import { PencilAltIcon, TrashIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import useAddBox from "../../hooks/useAddBox";
import useDeleteBox from "../../hooks/useDeleteBox";
import useBoxes from "../../hooks/useBoxes";
import Input from "../form/Input";
import Select from "../form/Select";
import useAddInventoryMinimumStock from "../../hooks/useAddInventoryMinimumStock";
import useDeleteInventoryMinimumStock from "../../hooks/useDeleteInventoryMinimumStock";

const Boxes = () => {
  const [box, setBox] = useState<any>(undefined);
  const [boxStock, setBoxStock] = useState<any>(undefined);
  const [boxId, setBoxId] = useState<any>(undefined);
  const boxes = useBoxes();
  const addBox = useAddBox(box);
  const addInventoryMinimumStock = useAddInventoryMinimumStock(boxStock);
  const deleteBox = useDeleteBox(boxId);
  const deleteInventoryMinimumStock = useDeleteInventoryMinimumStock(boxId);

  console.log(boxes);
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm();
  const { fields, append } = useFieldArray({
    control,
    name: "inventory_minimum_stock",
  });

  useEffect(() => {
    if (fields.length === 0) append([""], { shouldFocus: false });
  }, [fields]);

  const onSubmit = (data: any) => {
    setBox(data);
  };

  useEffect(() => {
    if (box) {
      addBox.mutate();
    }
  }, [box]);

  useEffect(() => {
    if (addBox.isSuccess && box) {
      if (
        box.inventory_minimum_stock &&
        box.inventory_minimum_stock.length > 0
      ) {
        const newBox = box;
        newBox.inventory_minimum_stock[0].fk_inventory = addBox.data.id;
        setBoxStock(newBox);
      } else {
        resetForm();
        setBox(undefined);
        setBoxStock(undefined);
        boxes.refetch();
      }
    }
  }, [addBox.isSuccess]);

  useEffect(() => {
    if (boxStock && boxStock.inventory_minimum_stock.length > 0) {
      addInventoryMinimumStock.mutate();
    }
  }, [boxStock]);

  useEffect(() => {
    if (addInventoryMinimumStock.isSuccess) {
      resetForm();
      setBox(undefined);
      boxes.refetch();
    }
  }, [addInventoryMinimumStock.isSuccess]);

  const deleteCurrentBox = (id: number) => {
    setBoxId(id);
  };
  useEffect(() => {
    if (boxId) {
      if (window.confirm("Soll dieser Eintrag wirklich gelöscht werden?")) {
        deleteBox.mutate();
        deleteInventoryMinimumStock.mutate();
      } else {
        setBoxId(undefined);
      }
    }
  }, [boxId]);

  useEffect(() => {
    if (deleteBox.isSuccess && deleteInventoryMinimumStock.isSuccess) {
      boxes.refetch();
      setBoxId(undefined);
    }
    resetForm();
  }, [deleteBox.isSuccess, deleteInventoryMinimumStock.isSuccess]);

  const resetForm = () => {
    reset({
      label: null,
      description: null,
      default_unit: null,
      inventory_minimum_stock: undefined,
      purchasing_price_netto: null,
      purchasing_price_brutto: null,
      selling_price_netto: null,
      selling_price_brutto: null,
      tax: null,
    });
  };
  return (
    <>
      <h1 className="text-2xl mb-6">Kisten</h1>
      <div className="flex justify-between flex-wrap lg:flex-nowrap gap-8">
        <div className="w-full lg:w-2/5 p-4 border">
          <form onSubmit={handleSubmit(onSubmit)}>
            <p className="mb-6">Kiste anlegen/bearbeiten</p>
            <Input
              id="label"
              style="flex-1"
              label="Bezeichnung*"
              register={register}
              errors={errors?.label}
              rules={{
                required: { value: true, message: "Dies ist ein Pflichtfeld" },
              }}
            />
            <Input
              id="description"
              style="flex-1"
              label="Beschreibung"
              register={register}
            />
            <div className="flex-1 flex gap-4">
              {fields.map((field, index) => (
                <Input
                  key={field.id}
                  style="w-full"
                  id={`inventory_minimum_stock.${index}.minimum_stock`}
                  label="Mindestbestand"
                  register={register}
                />
              ))}
              <Select
                id="default_unit"
                label="Standardeinheit"
                style="w-full"
                placeholder="-"
                register={register}
                errors={errors?.default_unit}
                options={[
                  { label: "Stk", key: "pc" },
                  { label: "kg", key: "kg" },
                  { label: "t", key: "t" },
                ]}
              />
            </div>
            <Input
              id="tax"
              style="flex-1"
              label="Ust. in %"
              register={register}
              onChange={(e: any) => {
                const tax = Number(e.target.value)
                  ? (100 + Number(e.target.value)) / 100
                  : 1;

                const ek_netto = getValues("purchasing_price_netto")
                  ? Number(
                      getValues("purchasing_price_netto")
                        .toString()
                        .replace(",", ".")
                    )
                  : null;
                const ek_brutto = tax && ek_netto ? ek_netto * tax : null;
                if (ek_brutto)
                  setValue("purchasing_price_brutto", ek_brutto.toFixed(2));

                const vk_netto = getValues("selling_price_netto")
                  ? Number(
                      getValues("selling_price_netto")
                        .toString()
                        .replace(",", ".")
                    )
                  : null;
                const vk_brutto = tax && vk_netto ? vk_netto * tax : null;
                if (vk_brutto)
                  setValue("selling_price_brutto", vk_brutto.toFixed(2));
              }}
            />
            <div className="flex-1 flex gap-4">
              <Input
                id="purchasing_price_netto"
                style="flex-1"
                label="EK (netto)"
                register={register}
                onChange={(e: any) => {
                  const tax = Number(getValues("tax"))
                    ? (100 + Number(getValues("tax"))) / 100
                    : undefined;

                  const brutto = tax
                    ? Number(e.target.value.toString().replace(",", ".")) * tax
                    : null;
                  if (brutto)
                    setValue("purchasing_price_brutto", brutto.toFixed(2));
                }}
              />
              <Input
                id="purchasing_price_brutto"
                style="flex-1"
                label="EK (brutto)"
                register={register}
                onChange={(e: any) => {
                  const tax = Number(getValues("tax"))
                    ? (100 + Number(getValues("tax"))) / 100
                    : undefined;

                  const netto = tax
                    ? Number(e.target.value.toString().replace(",", ".")) / tax
                    : null;
                  if (netto)
                    setValue("purchasing_price_netto", netto.toFixed(2));
                }}
              />
            </div>{" "}
            <div className="flex-1 flex gap-4">
              <Input
                id="selling_price_netto"
                style="flex-1"
                label="VK (netto)"
                register={register}
                onChange={(e: any) => {
                  const tax = Number(getValues("tax"))
                    ? (100 + Number(getValues("tax"))) / 100
                    : undefined;

                  const brutto = tax
                    ? Number(e.target.value.toString().replace(",", ".")) * tax
                    : null;
                  if (brutto)
                    setValue("selling_price_brutto", brutto.toFixed(2));
                }}
              />
              <Input
                id="selling_price_brutto"
                style="flex-1"
                label="VK (brutto)"
                register={register}
                onChange={(e: any) => {
                  const tax = Number(getValues("tax"))
                    ? (100 + Number(getValues("tax"))) / 100
                    : undefined;

                  const netto = tax
                    ? Number(e.target.value.toString().replace(",", ".")) / tax
                    : null;
                  if (netto) setValue("selling_price_netto", netto.toFixed(2));
                }}
              />
            </div>
            <div className="flex gap-8">
              <button type="submit" className="btn btn-primary">
                Speichern
              </button>
              <button
                type="button"
                className="btn btn-light"
                onClick={(_) => {
                  setBox(undefined);
                  resetForm();
                }}
              >
                Zurücksetzen
              </button>
            </div>
          </form>
        </div>
        <div className="w-full lg:w-3/5  overflow-x-auto">
          <table className="ac-table">
            <thead>
              <tr>
                <th>
                  <span>Bezeichnung</span>
                </th>
                <th>
                  <span>Beschreibung</span>
                </th>
                <th className="text-right">
                  <span>Mindestbestand</span>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {boxes?.data &&
                boxes?.data?.map((box: any, index: number) => (
                  <tr key={box.label + index.toString()}>
                    <td>{box.label}</td>
                    <td>{box.description}</td>
                    <td className="text-right">
                      {box?.inventory_minimum_stock[0]?.minimum_stock}
                    </td>
                    <td className="w-24">
                      <div className="flex justify-between items-center px-2">
                        <button
                          className="hover:scale-110"
                          onClick={(_) => {
                            reset(box);
                          }}
                        >
                          <PencilAltIcon
                            width={24}
                            height={24}
                            className="text-slate-700"
                          />
                        </button>
                        <button
                          className="hover:scale-110"
                          onClick={(_) => deleteCurrentBox(box.id)}
                        >
                          <TrashIcon
                            width={24}
                            height={24}
                            className="text-red-500"
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Boxes;
