import { useContext } from 'react';
import { useMutation } from 'react-query';
import { supabase } from '../utils/client';
import { UserContext } from '../App';
import moment from 'moment';

const useAddProduct = (product) => {
	const { acId } = useContext(UserContext);

	return useMutation(
		['products'],
		async () =>
			await supabase
				.from('inventories')
				.upsert([
					{
						id: product.id,
						fk_ac_customer: acId,
						label: product.label,
						description: product.description,
						article_no: product.article_no,
						category: 'Produkte',
						default_unit: product.default_unit,
						purchasing_price_netto: product.purchasing_price_netto,
						purchasing_price_brutto: product.purchasing_price_brutto,
						selling_price_netto: product.selling_price_netto,
						selling_price_brutto: product.selling_price_brutto,
						tax: product.tax,
						updated_at: moment().toISOString(),
					},
				])
				.single()
				.then(handleSupabaseError)
				.then(({ data }) => data)
	);
};

export default useAddProduct;

const handleSupabaseError = ({ error, ...rest }) => {
	if (error) {
		throw error;
	}
	return rest;
};
