import {
  DownloadIcon,
  ExternalLinkIcon,
  LightningBoltIcon,
  PencilAltIcon,
  RefreshIcon,
  TrashIcon,
  UploadIcon,
} from '@heroicons/react/outline';
import { useContext, useEffect, useCallback, useState } from 'react';
import { FC } from 'react';
import moment from 'moment';
import Pagination from './Pagination';
import Modal from 'react-modal';
import EditInventoryStock from './EditInventoryStock';
import useDeleteStock from '../hooks/useDeleteStock';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import StyledInput from './form/StyledInput';
import CustomFilterIcon from './CustomFilterIcon';
import { PrinterIcon, LightningBoltIcon as LightningBoltIconSolid } from '@heroicons/react/solid';
import { MerchandiseContext, UserContext } from '../App';
import useInventoryStock from '../hooks/useInventoryStock';
import useStocks from '../hooks/useStocks';
import useInventoryMinimumStock from '../hooks/useInventoryMinimumStock';
import InventoryStockByDeliverer from './InventoryStockByDeliverer';

const Inventory: FC<any> = () => {
  const {
    stocksPage,
    setStocksPage,
    search,
    setSearch,
    searchInput,
    setSearchInput,
    order,
    setOrder,
    ascending,
    setAscending,
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    incoming,
    setIncoming,
    outgoing,
    setOutgoing,
  } = useContext(MerchandiseContext);
  const [selectedLabel, setSelectedLabel] = useState<string | undefined>(undefined);
  const [stocksPerPage, setStocksPerPage] = useState<number>(10);
  const [searchExact, setSearchExact] = useState<boolean>(false);
  const inventoryStock = useInventoryStock(search, searchExact, dateFrom, dateTo, incoming, outgoing);
  const stocks = useStocks(stocksPage, stocksPerPage, search, order, ascending, dateFrom, dateTo, searchExact, incoming, outgoing);
  const inventoryMinimumStock = useInventoryMinimumStock();

  const { acId } = useContext(UserContext);

  const [stockIdToDelete, setStockIdToDelete] = useState<number | undefined>(undefined);
  const deleteStock = useDeleteStock(stockIdToDelete);
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const [selectedStock, setSelectedStock] = useState<any>(undefined);
  const [totalElements, setTotalElements] = useState<number>(0);

  useEffect(() => {
    if (search) {
      setSearchInput(search);
    }
  }, []);

  useEffect(() => {
    if (stocks.data) {
      setTotalElements(stocks?.data?.count || 0);
    }
  }, [stocks.data]);

  useEffect(() => {
    if (!searchInput) return setSearch(null);
    const handler = setTimeout(() => {
      if (searchInput.length >= 1) {
        setSearch(searchInput);
        setStocksPage(0);
      } else {
        setSearch(null);
        setStocksPage(0);
      }
    }, 750);
    return () => {
      clearTimeout(handler);
    };
  }, [searchInput]);

  useEffect(() => {
    if (stockIdToDelete) {
      if (window.confirm('Soll dieser Eintrag wirklich gelöscht werden?')) {
        deleteStock.mutate();
      } else {
        setStockIdToDelete(undefined);
      }
    }
  }, [stockIdToDelete]);

  useEffect(() => {
    if (stockIdToDelete && deleteStock.isSuccess) {
      toast.success('Eintrag gelöscht.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      stocks.refetch();
      inventoryStock.refetch();
      setStockIdToDelete(undefined);
    }
  }, [deleteStock.isSuccess]);

  useEffect(() => {
    if (deleteStock.isError) {
      setStockIdToDelete(undefined);
      toast.error('Da ist etwas schiefgelaufen!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [deleteStock.isError]);

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  Modal.setAppElement('#root');

  const handleFilter = (label: string) => {
    setOrder(label);
    if (label === order) {
      setAscending(!ascending);
      setStocksPage(0);
    } else {
      setAscending(false);
      setStocksPage(0);
    }
  };

  const handleSetDate = () => {
    if (startDate) {
      setDateFrom(startDate);
      setStocksPage(0);
    }
    if (endDate) {
      setDateTo(endDate);
      setStocksPage(0);
    }
  };

  useEffect(() => {
    if (selectedStock) {
      setModalIsOpen(true);
    }
  }, [selectedStock]);

  const resetAllFilters = () => {
    setSearch(undefined);
    setSearchInput(null);
    setSearchExact(false);
    setDateFrom('2022-01-01');
    setStartDate('2022-01-01');
    setDateTo(moment().format('YYYY') + '-12-31');
    setEndDate(moment().format('YYYY') + '-12-31');
    setStocksPage(0);
    setSelectedLabel(undefined);
    setIncoming(false);
    setOutgoing(false);
  };

  const replaceUmlaute = (value: string) => {
    value = value?.replace(/ä/g, '_-ae-');
    value = value?.replace(/ö/g, '_-oe-');
    value = value?.replace(/ü/g, '_-ue-');
    value = value?.replace(/ß/g, '_-ss-');
    value = value?.replace(/Ä/g, '_-AE-');
    value = value?.replace(/Ö/g, '_-OE-');
    value = value?.replace(/Ü/g, '_-UE-');
    value = value?.replace(/\+/g, '%2B');
    return value;
  };

  const onPrintDetailsSubmit = (e: any) => {
    e.preventDefault();
    const myFormData = new FormData(e.target);
    const productsArray: any = [];
    myFormData.forEach((value, key) => productsArray.push(value));
    const url = `/api/createInventoryPdf?search=${encodeURIComponent(
      replaceUmlaute(search)
    )}&dateFrom=${dateFrom}&dateTo=${dateTo}&products=${encodeURIComponent(replaceUmlaute(productsArray.join(',')))}&acid=${acId}`;

    window.open(url, '_blank');
  };
  const changeFilter = useCallback((e: any) => setSearchInput(e.target.value), []);
  const changeStartDate = useCallback((e: any) => setStartDate(e.target.value), []);
  const changeEndDate = useCallback((e: any) => setEndDate(e.target.value), []);

  return (
    <div className="pt-12">
      <div className="flex flex-wrap items-center gap-x-8 ">
        <button
          onClick={(_) => {
            setModalIsOpen(true);
            setSelectedStock(undefined);
          }}
          className="flex items-center mb-8 btn btn-primary ">
          <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-3" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
              clipRule="evenodd"
            />
          </svg>
          Zu-/Abgang
        </button>

        <StyledInput
          value={searchInput || ''}
          label="Filter"
          id="filter"
          placeholder="Filter"
          isFilled={searchInput ? true : false}
          onChange={changeFilter}
        />
        {!searchExact && (
          <button title="Zeige nur exakte Treffer an." className="mb-8 -ml-8">
            <LightningBoltIcon width={28} className="text-slate-500" onClick={() => setSearchExact(true)} />
          </button>
        )}
        {searchExact && (
          <button title="Zeige nur exakte Treffer an." className="mb-8 -ml-8">
            <LightningBoltIconSolid width={28} className="text-amber-500" onClick={() => setSearchExact(false)} />
          </button>
        )}
        <button
          title="Zeige nur eingehende Bestände."
          className="relative mb-8 -ml-4"
          onClick={() => {
            if (!incoming && !outgoing) {
              setIncoming(true);
              setOutgoing(false);
            }
            if (incoming && !outgoing) {
              setIncoming(false);
              setOutgoing(false);
            }
            if (!incoming && outgoing) {
              setIncoming(true);
              setOutgoing(false);
            }
          }}>
          <DownloadIcon width={24} className={`${incoming ? 'text-green-700' : 'text-slate-400'}`} />
          {incoming && <span className="absolute top-0 right-0 block w-2 h-2 bg-red-500 rounded-full"></span>}
        </button>
        <button
          title="Zeige nur ausgehende Bestände."
          className="relative mb-8 -ml-4"
          onClick={() => {
            if (!incoming && !outgoing) {
              setIncoming(false);
              setOutgoing(true);
            }
            if (!incoming && outgoing) {
              setIncoming(false);
              setOutgoing(false);
            }
            if (incoming && !outgoing) {
              setIncoming(false);
              setOutgoing(true);
            }
          }}>
          <UploadIcon width={24} className={`${outgoing ? 'text-red-700' : 'text-slate-400'}`} />
          {outgoing && <span className="absolute top-0 right-0 block w-2 h-2 bg-red-500 rounded-full"></span>}
        </button>
        <StyledInput
          id="date-from"
          label="Von:"
          type="date"
          value={startDate || '2022-01-01'}
          isFilled={startDate ? true : false}
          onChange={changeStartDate}
        />
        <StyledInput
          id="date-to"
          label="Bis:"
          type="date"
          value={endDate || moment().format('YYYY') + '-12-31'}
          isFilled={endDate ? true : false}
          onChange={changeEndDate}
        />
        <button className="mb-8 btn btn-primary" onClick={handleSetDate}>
          <RefreshIcon width={20} />
        </button>

        <button className="mb-8 btn btn-light" onClick={resetAllFilters}>
          Alle Filter zurücksetzen
        </button>
        <div className="relative group">
          <button className="mb-8 btn btn-primary" onMouseEnter={handleSetDate}>
            <PrinterIcon width={20} />
          </button>
          <div className="absolute z-50 block w-56 h-0 px-4 overflow-hidden transition-all duration-150 shadow-lg bg-slate-50 shadow-slate-800/20 group-hover:h-auto group-hover:py-4">
            <form onSubmit={onPrintDetailsSubmit} id="print-details">
              {inventoryStock?.data?.map((stock: any, i: number) => {
                if (stock.inventory_label)
                  return (
                    <div key={stock.inventory_label + '-print-' + i} className="mb-1">
                      <input
                        type="checkbox"
                        name={stock.inventory_label}
                        value={stock.inventory_label}
                        id={stock.inventory_label}
                        key={'print-product-' + i}
                        className="cursor-pointer"
                      />
                      <label htmlFor={stock.inventory_label} className="cursor-pointer">
                        {' '}
                        {stock.inventory_label}
                      </label>
                    </div>
                  );
              })}
              <button type="submit" className="w-full mt-4 btn btn-light">
                Drucken
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="mb-8 overflow-x-auto">
        <table className="mb-0 ac-table ">
          <thead>
            <tr>
              {inventoryStock?.data?.map((stock: any, i: number) => (
                <th
                  key={stock.inventory_label + '-head-' + i}
                  className="cursor-pointer"
                  onClick={(_) => {
                    setSearch(stock.inventory_label.toString());
                    setSearchExact(true);
                    setSearchInput(stock.inventory_label.toString());
                  }}>
                  {stock.inventory_label || 'unbekannt'}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr className="hover:!bg-transparent">
              {inventoryStock?.data?.map((stock: any) => {
                let bg = 'cursor-pointer hover:bg-gray-100 ';
                inventoryMinimumStock?.data?.map((inv: any) => {
                  if (stock.inventory_label === inv.label && inv.inventory_minimum_stock?.length > 0 && !search) {
                    if (
                      inv.inventory_minimum_stock[0].minimum_stock !== null &&
                      Number(stock.inventory_stock) < Number(inv.inventory_minimum_stock[0].minimum_stock)
                    )
                      bg += ' text-red-600 font-bold bg-red-50 ';
                  }
                });
                return (
                  <td
                    key={stock.inventory_label + '-body'}
                    className={bg}
                    onClick={() => {
                      setSelectedLabel(stock.inventory_label || '');
                    }}>
                    {Number(stock.inventory_stock).toLocaleString('de')}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </div>
      <div className="overflow-x-auto">
        <table className="mb-0 ac-table">
          <thead>
            <tr>
              <th className="cursor-pointer" onClick={() => handleFilter('type')}>
                <div className="flex justify-end">
                  <CustomFilterIcon
                    width={20}
                    upColor={order === 'type' && ascending ? 'fill-white' : undefined}
                    downColor={order === 'type' && !ascending ? 'fill-white' : undefined}
                  />
                </div>
              </th>
              <th className="cursor-pointer" onClick={() => handleFilter('booking_date')}>
                <div className="flex items-center justify-between">
                  <span>Datum</span>
                  <CustomFilterIcon
                    width={20}
                    upColor={order === 'booking_date' && ascending ? 'fill-white' : undefined}
                    downColor={order === 'booking_date' && !ascending ? 'fill-white' : undefined}
                  />
                </div>
              </th>
              <th className="cursor-pointer" onClick={() => handleFilter('label')}>
                <div className="flex items-center justify-between">
                  <span>Produkt</span>
                  <CustomFilterIcon
                    width={20}
                    upColor={order === 'label' && ascending ? 'fill-white' : undefined}
                    downColor={order === 'label' && !ascending ? 'fill-white' : undefined}
                  />
                </div>
              </th>
              <th className="cursor-pointer" onClick={() => handleFilter('amount')}>
                <div className="flex items-center justify-between">
                  <span>Menge</span>
                  <CustomFilterIcon
                    width={20}
                    upColor={order === 'amount' && ascending ? 'fill-white' : undefined}
                    downColor={order === 'amount' && !ascending ? 'fill-white' : undefined}
                  />
                </div>
              </th>
              <th className="cursor-pointer" onClick={() => handleFilter('booking_description')}>
                <div className="flex items-center justify-between">
                  <span>Buchungstext</span>
                  <CustomFilterIcon
                    width={20}
                    upColor={order === 'booking_description' && ascending ? 'fill-white' : undefined}
                    downColor={order === 'booking_description' && !ascending ? 'fill-white' : undefined}
                  />
                </div>
              </th>
              <th className="cursor-pointer" onClick={() => handleFilter('deliverer')}>
                <div className="flex items-center justify-between">
                  <span>Lieferant/Kunde</span>
                  <CustomFilterIcon
                    width={20}
                    upColor={order === 'deliverer' && ascending ? 'fill-white' : undefined}
                    downColor={order === 'deliverer' && !ascending ? 'fill-white' : undefined}
                  />
                </div>
              </th>
              <th className="cursor-pointer" onClick={() => handleFilter('delivery_note_no')}>
                <div className="flex items-center justify-between">
                  <span>Lieferschein-Nr.</span>
                  <CustomFilterIcon
                    width={20}
                    upColor={order === 'delivery_note_no' && ascending ? 'fill-white' : undefined}
                    downColor={order === 'delivery_note_no' && !ascending ? 'fill-white' : undefined}
                  />
                </div>
              </th>
              <th className="cursor-pointer" onClick={() => handleFilter('carrier')}>
                <div className="flex items-center justify-between">
                  <span>Spedition</span>
                  <CustomFilterIcon
                    width={20}
                    upColor={order === 'carrier' && ascending ? 'fill-white' : undefined}
                    downColor={order === 'carrier' && !ascending ? 'fill-white' : undefined}
                  />
                </div>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {stocks.data &&
              stocks.data?.data?.map((stock: any, index: number) => (
                <tr key={stock.id} className={` ${stock.type === 'increase' ? 'hover:!bg-green-50' : 'hover:!bg-red-50'}`}>
                  <td className="w-10">
                    {stock.type === 'increase' ? (
                      <DownloadIcon width={24} className="text-green-700" />
                    ) : (
                      <UploadIcon width={24} className="text-red-700" />
                    )}
                  </td>
                  <td>{moment(stock.booking_date).format('DD.MM.YYYY')}</td>
                  <td>{stock.label}</td>
                  <td>{Number(stock.amount).toLocaleString('de')}</td>
                  <td>{stock.booking_description}</td>
                  <td>{stock.deliverer}</td>
                  <td>{stock.delivery_note_no}</td>
                  <td>{stock.carrier}</td>
                  <td className="w-12 !py-0 !px-4">
                    {!stock.fk_delivery_note && (
                      <div className="flex items-center justify-end h-full gap-4">
                        <button className="hover:scale-110 " onClick={(_) => setSelectedStock(stock)}>
                          <PencilAltIcon width={24} />
                        </button>
                        <button className="hover:scale-110 " onClick={(_) => setStockIdToDelete(stock.id)}>
                          <TrashIcon width={24} className="text-red-500" />
                        </button>
                      </div>
                    )}
                    {stock.fk_delivery_note && (
                      <div className="flex items-center justify-end h-full">
                        <Link
                          to="/delivery-note"
                          state={{
                            delivery_note_no_state: stock.delivery_note_no,
                          }}
                          className="hover:scale-110">
                          <ExternalLinkIcon width={23} className="inline mb-1 ml-1 text-slate-700" />
                        </Link>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            {!stocks.data &&
              Array.from({ length: stocksPerPage }).map((_, index) => (
                <tr key={index}>
                  <td className="w-10">
                    <DownloadIcon width={24} className="text-gray-400" />
                  </td>
                  <td className="px-2">
                    <div className="animate-pulse">
                      <div className="h-4 bg-gray-200 rounded"></div>
                    </div>
                  </td>
                  <td className="px-2">
                    <div className="animate-pulse">
                      <div className="h-4 bg-gray-200 rounded"></div>
                    </div>
                  </td>
                  <td className="px-2">
                    <div className="animate-pulse">
                      <div className="h-4 bg-gray-200 rounded"></div>
                    </div>
                  </td>
                  <td className="px-2">
                    <div className="animate-pulse">
                      <div className="h-4 bg-gray-200 rounded"></div>
                    </div>
                  </td>
                  <td className="px-2">
                    <div className="animate-pulse">
                      <div className="h-4 bg-gray-200 rounded"></div>
                    </div>
                  </td>
                  <td className="px-2">
                    <div className="animate-pulse">
                      <div className="h-4 bg-gray-200 rounded"></div>
                    </div>
                  </td>
                  <td className="px-2">
                    <div className="animate-pulse">
                      <div className="h-4 bg-gray-200 rounded"></div>
                    </div>
                  </td>
                  <td className="w-12 !py-0 !px-4">
                    <div className="flex items-center justify-end gap-4 px-2">
                      <ExternalLinkIcon width={24} height={24} className="text-gray-400" />
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
          <tfoot className="border">
            <tr>
              <td colSpan={9}>
                <Pagination
                  count={stocks.data ? stocks.data?.data?.length : 0}
                  totalElements={totalElements}
                  setPage={setStocksPage}
                  page={stocksPage}
                  rowsPerPage={stocksPerPage}
                  setRowsPerPage={setStocksPerPage}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <Modal
        isOpen={modalIsOpen}
        className="absolute w-full -translate-x-1/2 -translate-y-1/2 bg-white max-w-7xl left-1/2 top-1/2"
        overlayClassName="bg-slate-700/90 z-50 absolute inset-0"
        shouldCloseOnOverlayClick={true}
        onRequestClose={(_) => {
          setModalIsOpen(false);
          setSelectedStock(undefined);
        }}>
        <EditInventoryStock
          stock={selectedStock}
          setSelectedStock={setSelectedStock}
          refetch={(_: any) => {
            stocks.refetch();
            inventoryStock.refetch();
          }}
          closeModal={(_: any) => {
            setSelectedStock(undefined);
            setModalIsOpen(false);
          }}
        />
      </Modal>
      <Modal
        isOpen={selectedLabel !== undefined ? true : false}
        className="fixed w-full max-w-2xl max-h-full p-8 overflow-y-auto -translate-x-1/2 -translate-y-1/2 bg-white left-1/2 top-1/2 "
        overlayClassName="bg-slate-700/90 z-50 fixed inset-0"
        shouldCloseOnOverlayClick={true}
        onRequestClose={(_) => {
          setSelectedLabel(undefined);
        }}>
        <InventoryStockByDeliverer setSelectedLabel={setSelectedLabel} inventoryLabel={selectedLabel} dateFrom={dateFrom} dateTo={dateTo} />
      </Modal>
    </div>
  );
};

export default Inventory;
