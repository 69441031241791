import StyledInput from './form/StyledInput';
import { RefreshIcon } from '@heroicons/react/outline';
import { useCallback, useState } from 'react';
import moment from 'moment';

const StatisticsFilter = ({ setSearch, setDateFrom, setDateTo, search }: any) => {
	const [startDate, setStartDate] = useState<string | undefined>(undefined);
	const [endDate, setEndDate] = useState<string | undefined>(undefined);

	const changeSearchInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.value) return setSearch('');
		const handler = setTimeout(() => {
			if (e.target.value.length >= 1) {
				setSearch(e.target.value);
			} else {
				setSearch('');
			}
		}, 750);
		return () => {
			clearTimeout(handler);
		};
	}, []);

	const resetAllFilters = () => {
		setSearch('');
		setDateFrom('2022-01-01');
		setStartDate('2022-01-01');
		setDateTo(moment().format('YYYY') + '-12-31');
		setEndDate(moment().format('YYYY') + '-12-31');
	};

	const changeStartDate = (e: React.ChangeEvent<HTMLInputElement>) => {
		setStartDate(e.target.value);
	};

	const changeEndDate = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEndDate(e.target.value);
	};

	const handleSetDate = () => {
		if (startDate) {
			setDateFrom(startDate);
		}
		if (endDate) {
			setDateTo(endDate);
		}
	};

	return (
		<>
			<StyledInput
				label='Filter'
				id='filter'
				placeholder='Filter'
				isFilled={search ? true : false}
				onChange={changeSearchInput}
			/>
			<StyledInput
				id='date-from'
				label='Von:'
				type='date'
				value={startDate || '2022-01-01'}
				isFilled={startDate ? true : false}
				onChange={changeStartDate}
			/>
			<StyledInput
				id='date-to'
				label='Bis:'
				type='date'
				value={endDate || moment().format('YYYY') + '-12-31'}
				isFilled={endDate ? true : false}
				onChange={changeEndDate}
			/>
			<button className='btn btn-primary mb-8' onClick={handleSetDate}>
				<RefreshIcon width={20} />
			</button>
			<button className='btn btn-light mb-8' onClick={resetAllFilters}>
				Alle Filter zurücksetzen
			</button>
		</>
	);
};

export default StatisticsFilter;
