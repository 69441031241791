import { useContext } from 'react';
import { useMutation } from 'react-query';
import { supabase } from '../utils/client';
import { UserContext } from '../App';

const useDeleteInventoryMinimumStock = (id) => {
	const { acId } = useContext(UserContext);

	return useMutation(
		['inventory_minimum_stock', acId],
		async () =>
			await supabase
				.from('inventory_minimum_stock')
				.delete()
				.match({ fk_inventory: id, fk_ac_customer: acId })
				.then(handleSupabaseError)
				.then(({ data }) => data)
	);
};

export default useDeleteInventoryMinimumStock;

const handleSupabaseError = ({ error, ...rest }) => {
	if (error) {
		throw error;
	}
	return rest;
};
