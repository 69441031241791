import React from 'react';

const Snackbar = ({ status, color, text }) => {
	return (
		<div
			className={` py-4 px-32 absolute left-1/2 -translate-x-1/2 rounded-full ${status} ${color} transition-all ease-in-out duration-700`}
		>
			<p className='text-xl'>{text}</p>
		</div>
	);
};

export default Snackbar;
