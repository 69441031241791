import { useMutation } from 'react-query';
import { supabase } from '../utils/client';

const useImportEmployee = () => {
	return useMutation(['employees'], AddEmployee);
};

export default useImportEmployee;

const AddEmployee = async (employee: any) => {
	return await supabase
		.from('employees')
		.upsert(employee, { onConflict: 'employee_id' })
		.then(handleSupabaseError)
		.then(({ data }) => data);
};

const handleSupabaseError = ({ error, ...rest }: any) => {
	if (error) {
		throw error;
	}
	return rest;
};
