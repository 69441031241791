import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useAddDestination from '../../hooks/useAddDestination';
import useDeleteDestination from '../../hooks/useDeleteDestination';
import useDestinations from '../../hooks/useDestinations';
import Input from '../form/Input';

const Destinations = () => {
	const [destination, setDestination] = useState<any>(undefined);
	const [destinationId, setDestinationId] = useState<any>(undefined);
	const destinations = useDestinations();
	const addDestination = useAddDestination(destination);
	const deleteDestination = useDeleteDestination(destinationId);

	const {
		register,
		handleSubmit,
		reset,
		control,
		formState: { errors },
	} = useForm();

	const onSubmit = (data: any) => {
		setDestination(data);
	};

	useEffect(() => {
		if (destination) addDestination.mutate();
	}, [destination]);

	useEffect(() => {
		if (addDestination.isSuccess) {
			reset({ label: null, description: null });
			setDestination(undefined);
			destinations.refetch();
		}
	}, [addDestination.isSuccess]);

	const deleteCurrentBox = (id: number) => {
		setDestinationId(id);
	};
	useEffect(() => {
		if (destinationId) {
			if (window.confirm('Soll dieser Eintrag wirklich gelöscht werden?')) {
				deleteDestination.mutate();
			} else {
				setDestinationId(undefined);
			}
		}
	}, [destinationId]);

	useEffect(() => {
		if (deleteDestination.isSuccess) {
			destinations.refetch();
			setDestinationId(undefined);
		}
		reset({ label: null, description: null });
	}, [deleteDestination.isSuccess]);

	return (
		<>
			<h1 className='text-2xl mb-6'>Bestimmungsorte</h1>
			<div className='flex justify-between flex-wrap lg:flex-nowrap gap-8'>
				<div className='w-full lg:w-2/5 p-4 border'>
					<form onSubmit={handleSubmit(onSubmit)}>
						<p className='mb-6'>Bestimmungsort anlegen/bearbeiten</p>
						<Input
							id='label'
							style='flex-1'
							label='Bezeichnung*'
							register={register}
							errors={errors?.label}
							rules={{
								required: { value: true, message: 'Dies ist ein Pflichtfeld' },
							}}
						/>
						<Input id='description' style='flex-1' label='Beschreibung' register={register} />
						<div className='flex gap-8'>
							<button type='submit' className='btn btn-primary'>
								Speichern
							</button>
							<button
								type='button'
								className='btn btn-light'
								onClick={(_) => {
									setDestination(undefined);
									reset({ label: null, description: null });
								}}
							>
								Zurücksetzen
							</button>
						</div>
					</form>
				</div>
				<div className='w-full lg:w-3/5  overflow-x-auto'>
					<table className='ac-table'>
						<thead>
							<tr>
								<th>
									<span>Bezeichnung</span>
								</th>
								<th>
									<span>Beschreibung</span>
								</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{destinations?.data &&
								destinations?.data.map((box: any, index: number) => (
									<tr key={box.label + index.toString()}>
										<td>{box.label}</td>
										<td>{box.description}</td>
										<td className='w-24'>
											<div className='flex justify-between items-center px-2'>
												<button
													className='hover:scale-110'
													onClick={(_) => {
														reset(box);
													}}
												>
													<PencilAltIcon width={24} height={24} className='text-slate-700' />
												</button>
												<button className='hover:scale-110' onClick={(_) => deleteCurrentBox(box.id)}>
													<TrashIcon width={24} height={24} className='text-red-500' />
												</button>
											</div>
										</td>
									</tr>
								))}
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
};

export default Destinations;
