import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import useEmployeeByEmployeeId from '../hooks/useEmployeeByEmployeeId';
import AdminLayout from '../layouts/AdminLayout';
import moment from 'moment';
import { UserContext } from '../App';
import EmployeeForm from '../components/EmployeeForm';
import useUpsertEmployee from '../hooks/useUpsertEmployee';

const Employee = () => {
	const { acId } = useContext(UserContext);
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();
	const params = useParams();
	const [page, setPage] = useState(0);
	const upsertEmployee = useUpsertEmployee();
	const { data: employee } = useEmployeeByEmployeeId(params.employeeId);

	useEffect(() => {
		if (employee && employee.length > 0) {
			reset(employee[0]);
		}
	}, [employee]);

	const onSubmit = async (form: any) => {
		form.birthday = moment(form.birthday).isValid() ? moment(form.birthday).format('YYYY-MM-DD') : null;
		form.fk_ac_customer = acId;
		form.rfid = form.rfid ? form.rfid : null;
		upsertEmployee.mutate(form);
	};

	return (
		<AdminLayout>
			<EmployeeForm employee={employee} onSubmit={onSubmit} register={register} handleSubmit={handleSubmit} />
		</AdminLayout>
	);
};

export default Employee;
