import React from 'react';

const ConfirmSignUp = () => {
	return (
		<div className='flex flex-col items-center justify-center h-screen'>
			<h1 className='text-slate-700 font-bold text-2xl max-w-xl text-center mb-12'>
				Sie haben soeben eine Email erhalten. Bitte bestätigen Sie dort Ihre Anmeldung
			</h1>
			<p className='text-slate-500 text-lg'>Sie können diese Seite nun schließen.</p>
		</div>
	);
};

export default ConfirmSignUp;
