import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { FC, useState, useEffect } from 'react';

const Pagination: FC<any> = ({ count, totalElements, setPage, page, rowsPerPage, setRowsPerPage }) => {
	const from = page * rowsPerPage + 1;
	const to = page * rowsPerPage + count;
	const [displayPage, setDisplayPage] = useState(page + 1);

	useEffect(() => {
		setDisplayPage(page + 1);
	}, [page]);

	const changePage = (e: any) => {
		setDisplayPage(e.target.value);
		if (!isNaN(Number(e.target.value))) {
			setPage(Number(e.target.value) - 1);
		}
	};

	return (
		<div className='bg-white px-4 py-3 flex items-center justify-between sm:px-6'>
			<div className='flex-1 flex justify-between sm:hidden'>
				<button
					onClick={(_) => setPage(page > 0 ? page - 1 : 0)}
					disabled={page === 0}
					className={`relative items-center px-4 py-1 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 ${
						page === 0 ? 'scale-0' : 'inline-flex'
					}`}
				>
					Zurück
				</button>
				<button
					onClick={(_) => setPage(to < totalElements && page + 1)}
					disabled={to >= totalElements}
					className={` ml-3 relative items-center px-4 py-1 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 ${
						to >= totalElements ? 'hidden' : 'inline-flex'
					}`}
				>
					Weiter
				</button>
			</div>
			<div className='hidden sm:flex-1 sm:flex sm:items-center sm:justify-between'>
				<div>
					<p className='text-sm text-gray-700'>
						Zeige <span className='font-medium'>{from < 1 ? 0 : from}</span> bis{' '}
						<span className='font-medium'>{to}</span> von <span className='font-medium'>{totalElements}</span>{' '}
						Ergebnissen ({Math.ceil(totalElements / rowsPerPage)} Seiten)
					</p>
				</div>
				<div className='flex gap-x-10 items-center'>
					<fieldset className='text-sm text-gray-700'>
						<select
							name='rows-per-page'
							id='rows-per-page'
							className='mr-1 text-right px-1 cursor-pointer bg-white'
							onChange={(e) => {
								setRowsPerPage(Number(e.target.value) || 10);
								setPage(0);
							}}
						>
							<option value={10}>10</option>
							<option value={25}>25</option>
							<option value={50}>50</option>
							<option value={100}>100</option>
							<option value={500}>500</option>
							<option value={1000}>1000</option>
						</select>
						<label htmlFor='rows-per-page'>Ergebnisse pro Seite</label>
					</fieldset>
					<nav className='relative z-0 inline-flex rounded-md shadow-sm -space-x-px' aria-label='Pagination'>
						<button
							onClick={(_) => setPage(0)}
							disabled={page === 0}
							className={`relative inline-flex items-center px-2 py-1 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 ${
								page !== 0 ? 'hover:bg-gray-50 border-gray-300 text-gray-500' : 'border-gray-200 text-gray-300'
							}`}
						>
							<span>Anfang</span>
						</button>
						<button
							onClick={(_) => setPage(page > 0 ? page - 1 : 0)}
							disabled={page === 0}
							className={`relative inline-flex items-center px-2 py-1 border border-gray-300 bg-white text-sm font-medium text-gray-500 ${
								page !== 0 ? 'hover:bg-gray-50 border-gray-300 text-gray-500' : 'border-gray-200 text-gray-300'
							}`}
						>
							<span className='sr-only'>Zurück</span>
							<ChevronLeftIcon className='h-5 w-5' aria-hidden='true' />
						</button>
						<div className='relative inline-flex items-center px-2 py-1 border border-gray-300 bg-white text-sm fonte-medium '>
							<input
								value={displayPage}
								onChange={changePage}
								type='text'
								className='h-full text-center !border-0 !outline-0 w-8'
							/>
						</div>
						<button
							onClick={(_) => setPage(to < totalElements && page + 1)}
							disabled={to >= totalElements}
							className={`relative inline-flex items-center px-2 py-1 border  bg-white text-sm font-medium  ${
								to < totalElements ? 'hover:bg-gray-50 border-gray-300 text-gray-500' : 'border-gray-200 text-gray-300'
							}`}
						>
							<span className='sr-only'>Weiter</span>
							<ChevronRightIcon className='h-5 w-5' aria-hidden='true' />
						</button>
						<button
							onClick={(_) => setPage(Math.floor(totalElements / rowsPerPage))}
							disabled={to >= totalElements}
							className={`relative inline-flex items-center px-2 py-1 rounded-r-md border  bg-white text-sm font-medium  ${
								to < totalElements ? 'hover:bg-gray-50 border-gray-300 text-gray-500' : 'border-gray-200 text-gray-300'
							}`}
						>
							<span>Ende</span>
						</button>
					</nav>
				</div>
			</div>
		</div>
	);
};

export default Pagination;
