import { useContext } from 'react';
import { useQuery } from 'react-query';
import { UserContext } from '../App';
import { supabase } from '../utils/client';

const useInventoryStock = (filter_input: string | undefined, searchExact: boolean,  date_from: string, date_to: string, incoming: boolean, outgoing: boolean) => {
	const { acId } = useContext(UserContext);

	return useQuery(
		['inventory_stock', filter_input, searchExact, date_from, date_to, incoming, outgoing, acId],
		async () =>
			await supabase
				.rpc('get_inventory_stock_with_filter', {
					filter_input: filter_input || '',
					search_exact: searchExact,
					date_from: date_from,
					date_to: date_to,
					incoming: incoming,
					outgoing: outgoing,
				})
				.then(handleSupabaseError)
				.then(({ data }) => data),
		{
			refetchOnWindowFocus: true,
		}
	);
};

export default useInventoryStock;

const handleSupabaseError = ({ error, ...rest }: any) => {
	if (error) {
		throw error;
	}
	return rest;
};

// .rpc('get_inventory_stock')
