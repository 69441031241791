import { useContext } from 'react';
import { useQuery } from 'react-query';
import { UserContext } from '../App';
import { supabase } from '../utils/client';

const useInventoryMinimumStock = () => {
	const { acId } = useContext(UserContext);

	return useQuery(
		['inventory_minimum_stock', acId],
		async () =>
			await supabase
				.from('inventories')
				.select(
					` label,
						inventory_minimum_stock(
							minimum_stock
						)
			`
				)
				.eq('fk_ac_customer', acId)
				.or('category.eq.Kisten,category.eq.Paletten')
				.then(handleSupabaseError)
				.then(({ data }) => data),
		{
			refetchOnWindowFocus: true,
			cacheTime: Infinity,
		}
	);
};

export default useInventoryMinimumStock;

const handleSupabaseError = ({ error, ...rest }: any) => {
	if (error) {
		throw error;
	}
	return rest;
};
