import React, { useState } from 'react';
import TimeTrackingDay from '../components/TimeTrackingDay';
import TimeTrackingMonth from '../components/TimeTrackingMonth';
import TimeTrackingWeek from '../components/TimeTrackingWeek';
import AdminLayout from '../layouts/AdminLayout';

const TimeTracking = () => {
	const [page, setPage] = useState(0);

	return (
		<AdminLayout>
			<h1 className='text-2xl font-bold mb-2'>Zeiterfassung</h1>
			<div className='inline-flex divide-x-2 divide-slate-300' role='group'>
				<button
					type='button'
					className='bg-slate-700 text-white  py-3 px-8 my-8 rounded-l-lg'
					onClick={() => setPage(0)}
				>
					Tag
				</button>
				<button type='button' className='bg-slate-700 text-white  py-3 px-8 my-8' onClick={() => setPage(1)}>
					Woche
				</button>
				<button
					type='button'
					className='bg-slate-700 text-white py-3 px-8 my-8 rounded-r-lg'
					onClick={() => setPage(2)}
				>
					Monat
				</button>
			</div>
			{page === 0 && <TimeTrackingDay />}
			{page === 1 && <TimeTrackingWeek />}
			{page === 2 && <TimeTrackingMonth />}
		</AdminLayout>
	);
};

export default TimeTracking;
