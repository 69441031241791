import { useEffect, useState } from "react";
import useDeliveryNotesItems from "../../hooks/useDeliveryNotesItems";

const MostSoldProductsTable = ({ search, dateFrom, dateTo }: any) => {
  const [mostDeliveredProducts, setMostDeliveredProducts] = useState<any>([]);
  const deliveryNotesItems = useDeliveryNotesItems(search, dateFrom, dateTo);

  useEffect(() => {
    if (deliveryNotesItems.data?.data) {
      const newObj: any = new Object();
      deliveryNotesItems.data?.data?.map((itemArray: any) => {
        itemArray.delivery_notes_items.map((item: any) => {
          const obj = typeof item === "string" ? JSON.parse(item) : item;
          const content_amount =
            Number(obj.content_amount) * (Number(obj.box_quantity) || 1) || 0;
          if (newObj[obj.product_label] === undefined) {
            newObj[obj.product_label] = {
              [obj.product_size]: {
                [obj.unit]: content_amount,
              },
            };
          } else {
            if (newObj[obj.product_label][obj.product_size] === undefined) {
              newObj[obj.product_label][obj.product_size] = {
                [obj.unit]: content_amount,
              };
            } else {
              if (
                newObj[obj.product_label][obj.product_size][obj.unit] ===
                undefined
              ) {
                newObj[obj.product_label][obj.product_size][obj.unit] =
                  content_amount;
              } else {
                newObj[obj.product_label][obj.product_size][obj.unit] +=
                  content_amount;
              }
            }
          }
        });
      });

      const toSort: any = [];

      Object.keys(newObj).map((key: any, n) => {
        Object.keys(newObj[key]).map((objKey: any, i) => {
          Object.keys(newObj[key][objKey]).map((unitKey: any, index) => {
            const tmp: any = {};
            if (
              JSON.stringify(newObj[key][objKey][unitKey]) !== "" &&
              Number(JSON.stringify(newObj[key][objKey][unitKey])) > 0
            ) {
              tmp.product_label = key;
              tmp.product_size = objKey;
              tmp.unit = unitKey;
              tmp.amount = newObj[key][objKey][unitKey];
              toSort.push(tmp);
            }
          });
        });
      });
      const sorted = toSort.sort((a: any, b: any) => {
        return b.amount - a.amount;
      });
      setMostDeliveredProducts(sorted);
    }
  }, [deliveryNotesItems?.data?.data]);

  return (
    <table className="ac-table">
      <thead>
        <tr>
          <th>Produkt</th>
          <th>Größe</th>
          <th className="!text-right">Menge</th>
          <th className="!text-right"></th>
        </tr>
      </thead>
      <tbody>
        {mostDeliveredProducts &&
          mostDeliveredProducts.map((item: any) => (
            <tr key={item.product_label + item.product_size + item.unit}>
              <td>{item.product_label || "unbekannt"}</td>
              <td>{item.product_size}</td>
              <td className="text-right">
                {item.amount.toLocaleString("de-DE")}{" "}
              </td>
              <td className="text-right w-10">
                {item.unit === "pc" && "Stk."}
                {item.unit === "kg" && "kg"}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default MostSoldProductsTable;
