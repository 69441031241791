import { useState } from "react";
import moment from "moment";
import MostSoldProductsTable from "./statisticTables/MostSoldProductsTable";
import MostSuppliedDestinationsTable from "./statisticTables/MostSuppliedDestinationsTable";
import MostUsedCarriersTable from "./statisticTables/MostUsedCarriersTable";
import StatisticsFilter from "./StatisticsFilter";
import OutgoingBoxesTable from "./statisticTables/OutgoingBoxesTable";
import IncomingBoxesTable from "./statisticTables/IncomingBoxesTable";

const Statistics = () => {
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [dateFrom, setDateFrom] = useState<string>("2022-01-01");
  const [dateTo, setDateTo] = useState<string>(moment().format("YYYY-MM-DD"));
  const [searchDestinations, setSearchDestinations] = useState<any[]>([]);
  const [searchCarriers, setSearchCarriers] = useState<any[]>([]);

  return (
    <div className="pt-12">
      <div className="flex flex-wrap items-center gap-x-8 ">
        <StatisticsFilter
          search={search}
          setSearch={setSearch}
          setDateFrom={setDateFrom}
          setDateTo={setDateTo}
        />
      </div>
      {/*       {search && (
        <div className="mb-8 bg-slate-100 p-2 flex gap-x-4">
          <span className="font-bold">Gefiltert nach:</span>
          <span>
            {moment(dateFrom).format("DD.MM.YYYY")} -{" "}
            {moment(dateTo).format("DD.MM.YYYY")}
          </span>
          <span>
            {searchDestinations.map(
              (destination: any) => destination.label + ", "
            )}
          </span>
          <span>
            {searchCarriers.map((carriers: any) => carriers.label + ", ")}
          </span>
        </div>
      )} */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div>
          <h2 className="font-bold text-xl mb-0 border-x border-t bg-slate-700 text-white py-1 px-2">
            Häufigste Produkte
          </h2>
          <MostSoldProductsTable
            search={search}
            dateFrom={dateFrom}
            dateTo={dateTo}
          />
        </div>
        <div>
          <h2 className="font-bold text-xl mb-0 border-x border-t bg-slate-700 text-white py-1 px-2">
            Häufigste Bestimmungsorte
          </h2>
          <MostSuppliedDestinationsTable
            search={search}
            dateFrom={dateFrom}
            dateTo={dateTo}
            setSearchDestinations={setSearchDestinations}
          />
        </div>
        <div>
          <h2 className="font-bold text-xl mb-0 border-x border-t bg-slate-700 text-white py-1 px-2">
            Häufigste Speditionen
          </h2>
          <MostUsedCarriersTable
            search={search}
            dateFrom={dateFrom}
            dateTo={dateTo}
            setSearchCarriers={setSearchCarriers}
          />
        </div>
      </div>
      <div>
        <h2 className="font-bold text-xl mb-0 border-x border-t bg-slate-700 text-white py-1 px-2">
          Eingehende Lieferungen
        </h2>
        <IncomingBoxesTable
          search={search}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
      </div>
      <div>
        <h2 className="font-bold text-xl mb-0 border-x border-t bg-slate-700 text-white py-1 px-2">
          Ausgehende Lieferungen
        </h2>
        <OutgoingBoxesTable
          search={search}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
      </div>
    </div>
  );
};

export default Statistics;
