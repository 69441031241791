import useInventoryStockByDeliverer from "../hooks/useInventoryStockByDeliverer";

const InventoryStockByDeliverer = ({
  inventoryLabel,
  dateFrom,
  dateTo,
  setSelectedLabel,
}: any) => {
  const inventoryStockByDeliverer = useInventoryStockByDeliverer(
    inventoryLabel,
    dateFrom,
    dateTo
  );

  return (
    <>
      <h2 className="text-center mb-2 font-semibold text-lg">
        {inventoryLabel || "unbekannt"}
      </h2>
      <table className="ac-table mb-6">
        <thead>
          <tr>
            <th>Lieferant/Kunde</th>
            <th className="!text-right">Menge</th>
          </tr>
        </thead>
        <tbody>
          {inventoryStockByDeliverer.data &&
            inventoryStockByDeliverer.data.map((item: any, index: number) => (
              <tr
                key={item.deliverer_label + index.toString()}
                className={
                  item.inventory_stock < 0 ? "bg-red-50" : "bg-green-50"
                }
              >
                <td>{item.deliverer_label}</td>
                <td className="!text-right">
                  {Number(item.inventory_stock).toLocaleString("de")}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="flex justify-end">
        <button
          type="button"
          onClick={() => setSelectedLabel(undefined)}
          className="btn btn-light"
        >
          Schliessen
        </button>
      </div>
    </>
  );
};

export default InventoryStockByDeliverer;
