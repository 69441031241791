import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import Input from './form/Input';
import Select from './form/Select';
import moment from 'moment';
import useAddStock from '../hooks/useAddStock';
import useBoxes from '../hooks/useBoxes';
import { UserContext } from '../App';
import { useAuth } from '../provider/AuthProvider';
import useDestinations from '../hooks/useDestinations';
import useCarriers from '../hooks/useCarriers';
import usePallets from '../hooks/usePallets';

const EditInventoryStock = ({ closeModal, refetch, stock, setSelectedStock }: any) => {
  const { acId } = useContext(UserContext);
  const { user } = useAuth();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [availableProducts, setAvailableProducts] = useState<any>();

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  const { fields, append } = useFieldArray({
    control,
    name: 'stocks',
  });
  const boxes = useBoxes();
  const pallets = usePallets();
  const [newStock, setNewStock] = useState<any>();
  const addOrUpdateStock = useAddStock();
  const destinations = useDestinations();
  const carriers = useCarriers();

  useLayoutEffect(() => {
    if (!stock) {
      setValue('booking_date', moment().format('YYYY-MM-DD'));
      append([''], {
        shouldFocus: false,
      });
    }
  }, []);

  useEffect(() => {
    if (stock) {
      const myStock = {
        booking_date: stock.booking_date || null,
        deliverer: stock.deliverer || null,
        carrier: stock.carrier || null,
        delivery_note_no: stock.delivery_note_no || null,
        booking_description: stock.booking_description || null,
        'stocks.0.type': stock.type || null,
        'stocks.0.category': stock.category || null,
        'stocks.0.unit': stock.unit || null,
        'stocks.0.label': stock.label || null,
        'stocks.0.amount': stock.amount || null,
      };

      reset(myStock);
      append([''], {
        shouldFocus: true,
        focusIndex: 1,
        focusName: `stocks.0.type`,
      });
      setValue('id', stock.id);
    }
  }, [stock]);

  const getAvailableProducts = (e: any) => {
    const dataList: any[] = [];
    const id = e.target.id.split('.')[1];
    const category = getValues(`stocks.${id}.category`);
    if (category === 'Kisten') {
      setValue(`stocks.${id}.label`, null);
      setValue(`stocks.${id}.unit`, 'pc');

      return setAvailableProducts(boxes?.data);
    }
    if (category === 'Paletten') {
      setValue(`stocks.${id}.label`, null);
      setValue(`stocks.${id}.unit`, 'pc');
      return setAvailableProducts(pallets?.data);
    }
    setValue(`stocks.${id}.label`, null);
    setValue(`stocks.${id}.unit`, null);
    return setAvailableProducts(null);
  };

  const onSubmit = (data: any) => {
    if (data) {
      setIsSaving(true);
      Object.assign(data, {
        acId: acId,
        created_by_user: JSON.stringify({ email: user.email }),
      });

      setNewStock(data);
    }
  };

  useEffect(() => {
    if (newStock) {
      const stockArr: any = [];
      newStock.stocks?.map((stock: any) => {
        if (newStock.booking_date && stock.type && stock.category && stock.label && stock.amount) {
          const createdStock = {
            id: newStock.id,
            fk_ac_customer: newStock.acId,
            fk_delivery_note: newStock.delivery_note_id || null,
            created_by_user: newStock.created_by_user,
            updated_by_user: newStock.updated_by_user || null,
            updated_at: moment().toISOString() || null,
            label: stock.label,
            category: stock.category || null,
            type: stock.type,
            amount: Number(stock.amount) || null,
            unit: stock.unit || null,
            booking_date: newStock.booking_date,
            booking_description: newStock.booking_description || null,
            deliverer: newStock.deliverer || null,
            carrier: newStock.carrier || null,
            delivery_note_no: newStock.delivery_note_no || null,
          };
          stockArr.push(createdStock);
        }
      });
      addOrUpdateStock.mutate(stockArr);
    }
  }, [newStock]);

  useEffect(() => {
    if (addOrUpdateStock.isSuccess) {
      setSelectedStock(undefined);

      reset({
        label: null,
        category: null,
        type: null,
        amount: null,
        unit: null,
        delivery_note_no: null,
        booking_date: moment().format('YYYY-MM-DD'),
        booking_description: null,
        deliverer: null,
        carrier: null,
      });
      refetch();
      closeModal();
    }
  }, [addOrUpdateStock.isSuccess]);

  useEffect(() => {
    if (addOrUpdateStock.isError) setIsSaving(false);
  }, [addOrUpdateStock.isError]);

  if (!carriers?.data || !destinations?.data) {
    return (
      <div className="flex items-center justify-center space-x-2 h-96">
        <div className="inline-block w-12 h-12 border-4 rounded-full spinner-border animate-spin" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }
  return (
    <div className="p-8 ">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex gap-8">
          <Input
            autofocus={true}
            label="Buchungsdatum*"
            id="booking_date"
            type="date"
            register={register}
            errors={errors?.booking_date}
            rules={{
              required: { value: true, message: 'Dies ist ein Pflichtfeld' },
            }}
          />
          <Select label="Lieferant" placeholder="-" id="deliverer" options={destinations.data || []} register={register} style="w-96" />
          <Input style="w-96" label="Lieferschein-Nr." id="delivery_note_no" register={register} />
          <Select label="Spedition" placeholder="-" id="carrier" options={carriers.data || []} register={register} style="w-96" />
          <Input style="w-full" label="Buchungstext" id="booking_description" register={register} />
        </div>
        {fields.map((field: any, index: number) => (
          <div key={index} className="flex gap-4">
            <Select
              label="Typ*"
              id={`stocks.${index}.type`}
              placeholder="--Bitte wählen--"
              register={register}
              options={[
                { label: 'Zugang', key: 'increase' },
                { label: 'Abgang', key: 'decrease' },
              ]}
              // @ts-ignore
              errors={errors?.stocks?.[index]?.type}
              rules={{
                required: { value: true, message: 'Dies ist ein Pflichtfeld' },
              }}
            />
            <Select
              label="Kategorie*"
              id={`stocks.${index}.category`}
              placeholder="--Bitte wählen--"
              register={register}
              options={[{ label: 'Kisten' }, { label: 'Paletten' }]}
              // @ts-ignore
              errors={errors?.stocks?.[index]?.category}
              rules={{
                required: { value: true, message: 'Dies ist ein Pflichtfeld' },
              }}
            />
            <Input
              label="Produkt"
              placeholder="-"
              id={`stocks.${index}.label`}
              listname="product_sizes"
              dataList={availableProducts || []}
              onFocus={getAvailableProducts}
              register={register}
              // @ts-ignore
              errors={errors?.stocks?.[index]?.label}
              rules={{
                required: { value: true, message: 'Dies ist ein Pflichtfeld' },
              }}
            />
            <Select
              id={`stocks.${index}.unit`}
              style="flex-1"
              label="Einheit"
              placeholder="-"
              register={register}
              // @ts-ignore
              errors={errors?.stocks?.[index]?.unit}
              options={[
                { label: 'Stk', key: 'pc' },
                { label: 'kg', key: 'kg' },
                { label: 't', key: 't' },
              ]}
            />

            <Input
              label="Menge*"
              id={`stocks.${index}.amount`}
              register={register}
              // @ts-ignore
              errors={errors?.stocks?.[index]?.amount}
              rules={{
                required: { value: true, message: 'Dies ist ein Pflichtfeld' },
                pattern: {
                  value: /^[1-9]+[0-9]*$/,
                  message: 'Nur positive Ganzzahlen erlaubt',
                },
              }}
            />
          </div>
        ))}

        {!stock && (
          <button
            type="button"
            className="flex items-center mb-12 text-slate-700"
            onClick={() =>
              append([''], {
                shouldFocus: true,
                focusIndex: fields.length,
                focusName: `stocks.${fields.length}.type`,
              })
            }>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5 mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            Position hinzufügen
          </button>
        )}
        <div className="flex justify-between">
          <button disabled={isSaving} type="submit" className="btn btn-primary">
            Speichern
          </button>
          <button type="button" onClick={closeModal} className="btn btn-light">
            Schliessen
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditInventoryStock;
