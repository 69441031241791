import {
	RefreshIcon,
	LogoutIcon,
	InformationCircleIcon,
	PrinterIcon,
	DocumentDownloadIcon,
	PencilAltIcon,
	TrashIcon,
	ExternalLinkIcon,
	XCircleIcon,
} from '@heroicons/react/outline';
import { DotsVerticalIcon, SwitchVerticalIcon } from '@heroicons/react/solid';

const Docs = () => {
	return (
		<div className='flex'>
			<div className='bg-slate-700 text-white p-10 overflow-y-auto fixed h-screen w-64 lg:w-72 hidden md:block'>
				<ol className='list-decimal font-bold tracking-wide'>
					<li className='mb-6'>
						<a href='#erste-schritte'>Erste Schritte</a>
						<ol className='list-decimal font-normal sm:ml-4 mb-2 tracking-normal'>
							<li>
								<a href='#installation'>Installation</a>
							</li>
							<li>
								<a href='#verknuepfung-erstellen'>Verknüpfung erstellen</a>
							</li>
							<li>
								<a href='#an-taskleiste-anheften'>An Taskleiste anheften</a>
							</li>
							<li>
								<a href='#registrieren'>Registrieren</a>
							</li>
							<li>
								<a href='#anmelden'>Anmelden</a>
							</li>
							<li>
								<a href='#abmelden'>Abmelden</a>
							</li>
							<li>
								<a href='#naechste-schritte'>Nächste Schritte</a>
							</li>
						</ol>
					</li>
					<li className='mb-6'>
						<a href='#einstellungen'>Einstellungen</a>
						<ol className='list-decimal font-normal sm:ml-4 mb-2 tracking-normal'>
							<li>
								<a href='#produkte'>Produkte</a>
							</li>
							<li>
								<a href='#kisten'>Kisten</a>
							</li>
							<li>
								<a href='#bestimmungsorte'>Bestimmungsorte</a>
							</li>
							<li>
								<a href='#speditionen'>Speditionen</a>
							</li>
							<li>
								<a href='#benutzer'>Benutzer</a>
							</li>
							<li>
								<a href='#system'>System</a>
							</li>
						</ol>
					</li>
					<li className='mb-6'>
						<a href='#warenwirtschaft'>Warenwirtschaft</a>
						<ol className='list-decimal font-normal sm:ml-4 mb-2 tracking-normal'>
							<li>
								<a href='#lieferscheine'>Lieferscheine</a>
							</li>
							<li>
								<a href='#lieferschein-anlegen'>Lieferschein anlegen</a>
							</li>
							<li>
								<a href='#bestaende'>Bestände</a>
							</li>
							<li>
								<a href='#zu-abgang-anlegen'>Zu-/Abgang anlegen</a>
							</li>
							<li>
								<a href='#statistik'>Statistik</a>
							</li>
						</ol>
					</li>
					<li className='mb-6'>
						<a href='#benutzung'>Benutzung</a>
						<ol className='list-decimal font-normal sm:ml-4 mb-2 tracking-normal'>
							<li>
								<a href='#filter'>Filter</a>
							</li>
							<li>
								<a href='#tabellen'>Tabellen</a>
							</li>
						</ol>
					</li>
				</ol>
			</div>
			<div className='flex-1 md:pl-64 lg:pl-72'>
				<div className='max-w-6xl mx-auto p-10'>
					<img src='/ackercloud-logo.png' alt='LOGO' className='block mx-auto w-48' />
					<h1 className='text-2xl text-center font-bold tracking-wide mb-20'>ackercloud - Dokumentation</h1>
					<ol className='list-decimal font-bold tracking-wide'>
						<li className='mb-10'>
							<h2 className='text-lg font-bold mb-4' id='erste-schritte'>
								Erste Schritte
							</h2>
							<ol className='list-decimal font-normal sm:ml-4 mb-2 tracking-normal'>
								<li className=' mb-10'>
									<h3 className='font-semibold mb-2' id='installation'>
										Installation
									</h3>
									<p>
										ackercloud ist eine Webapplikation und bedarf keiner Installation. Die App ist über die URL{' '}
										<a href='https://www.ackercloud.de' className='underline'>
											https://www.ackercloud.de
										</a>{' '}
										von jedem Endgerät, mit aktuellem Browser und aktiviertem JavaScript, zu erreichen und zu benutzen.
										Empfohlen wird Google Chrome als Browser zu verwenden.
									</p>
								</li>
								<li className=' mb-10'>
									<h3 className='font-semibold mb-2' id='verknuepfung-erstellen'>
										Verknüpfung erstellen
									</h3>
									<p>
										Wenn ackercloud mit Google Chrome verwendet wird, besteht die Möglichkeit eine Verknüpfung auf dem
										Desktop anzulegen. Mit dieser Verknüpfung lässt sich die ackercloud App im Vollbild-Modus wie eine
										Desktop Applikation verwenden. <br />
										<ul className='list-disc pl-6'>
											<li>
												Im Google Chrome Browser in der oberen, rechten Ecke des Browsers das Menü, über die 3
												vertikalen Punkte (<DotsVerticalIcon width={16} style={{ display: 'inline' }} />) öffnen.
											</li>
											<li>
												Menüpunkt "Weitere Tools" öffnen und den Untermenüpunkt "Verknüpfungen erstellen" anklicken.
											</li>
											<li>Im nun geöffneten Dialogfenster den Haken bei "In neuem Fenster öffnen" setzen.</li>
											<li>Nach dem Klick auf "Erstellen" befindet sich die ackercloud Verknüpfung auf dem Desktop.</li>
										</ul>
									</p>
								</li>
								<li className=' mb-10'>
									<h3 className='font-semibold mb-2' id='an-taskleiste-anheften'>
										An Taskleiste anheften
									</h3>
									<p>
										Auf einem Windows PC besteht die Möglichkeit die ackercloud App an die Taskleiste anzuheften. Hierzu
										die App über die Verknüpfung (siehe 1.2.) öffen. Anschließend das ackercloud Symbol in der
										Taskleiste mit einem Rechtsklick anwählen und auf "An Taskleiste anheften" klicken.
									</p>
								</li>
								<li className=' mb-10'>
									<h3 className='font-semibold mb-2' id='registrieren'>
										Registrieren
									</h3>
									<p>
										Eine Registrierung neuer Unternehmen ist derzeit nicht möglich. Sollen dem bestehenden Unternehmen
										neue Benutzer hinzugefügt werden, ist dies unter Einstellungen / System möglich. Mehr dazu unter
										2.5.
									</p>
								</li>
								<li className=' mb-10'>
									<h3 className='font-semibold mb-2' id='anmelden'>
										Anmelden
									</h3>
									<p>
										Um sich als Benutzer im System anzumelden, öffnet man ackercloud über die Desktop Verknüpfung (siehe
										1.2.) oder über die URL{' '}
										<a href='https://www.ackercloud.de' className='underline'>
											https://www.ackercloud.de
										</a>
										. Ist noch kein Benutzer angemeldet, wird man auf den Login-Bildschirm weitergeleitet. Dort trägt
										man seine Email-Adresse und sein Passwort ein und klickt "jetzt einloggen". Nach erfolgreicher
										Anmeldung befindet man sich nun in der Übersicht "Warenwirtschaft". <br />
										Ist bereits ein Benutzer angemeldet, muss dieser zunächst abgemeldet werden (siehe 1.6.).
										Anschließend kann sich ein neuer Benutzer, wie zuvor beschrieben, anmelden.
									</p>
								</li>
								<li className=' mb-10'>
									<h3 className='font-semibold mb-2' id='abmelden'>
										Abmelden
									</h3>
									<p>
										Um sich als Benutzer aus dem System abzumelden, klickt man in der oberen, rechten Ecke auf das
										Symbol <LogoutIcon width={16} style={{ display: 'inline' }} /> neben dem Benutzernamen. Im Anschluss
										wird man zurück auf den Login-Bildschirm weitergeleitet. Von hier kann sich der Benutzer erneut
										anmelden.
									</p>
								</li>
								<li className=' mb-10'>
									<h3 className='font-semibold mb-2' id='naechste-schritte'>
										Nächste Schritte
									</h3>
									<p>
										Um ackercloud vollständig nutzen zu können, müssen zunächst Stammdaten hinterlegt werden. Hierzu
										bitte die Schritte unter Punkt 2. "Einstellungen" beachten.
										<br />
										<strong className='font-bold text-red-500'>Wichtig!</strong> Vor dem Erstellen des ersten
										Lieferscheines unter Einstellungen / System, die Lieferschein Startnummer einstellen (siehe 2.6.)
									</p>
								</li>
							</ol>
						</li>
						<li className='mb-10'>
							<h2 className='text-lg font-bold mb-4' id='einstellungen'>
								Einstellungen
							</h2>
							<ol className='list-decimal font-normal sm:ml-4 mb-2 tracking-normal'>
								<li className=' mb-10'>
									<h3 className='font-semibold mb-2' id='produkte'>
										Produkte
									</h3>
									<p>
										Unter dem Menüpunkt "Produkte" können Produkte hinzugefügt werden. Das Formularfeld "Bezeichnung"
										ist ein Pflichtfeld. Die Felder für "Standardeinheit", "Beschreibung" und "Größe" sind optional.
										<br />
										Verfügt ein Produkt über verschiedene Größen oder Varianten, können diese über "Größe hinzufügen"
										beliebig oft hinzugefügt werden.
										<br />
										Der Button "Zurücksetzen" löscht alle Eingaben des Formularfeldes. Mit "Speichern" wird das Produkt
										hinzugefügt.
										<br />
										In der Tabelle werden alle Produkte angezeigt. Um ein Produkt zu löschen, kann das Papierkorb Symbol
										geklickt werden. Um ein Produkt zu bearbeiten, kann das Stift Symbol geklickt werden. <br />
										Nach dem Bearbeiten kann das Produkt mit "Speichern" gespeichert werden.
									</p>
								</li>
								<li className=' mb-10'>
									<h3 className='font-semibold mb-2' id='kisten'>
										Kisten
									</h3>
									<p>
										Unter dem Menüpunkt "Kisten" können Kisten hinzugefügt werden. Das Formularfeld "Bezeichnung" ist
										ein Pflichtfeld. Die Felder für "Standardeinheit", "Beschreibung" und "Mindestbestand" sind
										optional.
										<br />
										Wird das Feld Mindestbestand mit einer Zahl gefüllt, bekommt man unter Warenwirtschaft / Bestände
										eine Warnung angezeigt, wenn der Wert unterschritten wird.
										<br />
										Der Button "Zurücksetzen" löscht alle Eingaben des Formularfeldes. Mit "Speichern" wird die Kiste
										hinzugefügt.
										<br />
										In der Tabelle werden alle Kisten angezeigt. Um eine Kiste zu löschen, kann das Papierkorb Symbol
										geklickt werden. Um eine Kiste zu bearbeiten, kann das Stift Symbol geklickt werden. <br />
										Nach dem Bearbeiten kann die Kiste mit "Speichern" gespeichert werden.
									</p>
								</li>
								<li className=' mb-10'>
									<h3 className='font-semibold mb-2' id='bestimmungsorte'>
										Bestimmungsorte
									</h3>
									<p>
										Unter dem Menüpunkt "Bestimmungsorte" können Bestimmungsorte hinzugefügt werden. Das Formularfeld
										"Bezeichnung" ist ein Pflichtfeld. Das Feld "Beschreibung" ist optional.
										<br />
										Der Button "Zurücksetzen" löscht alle Eingaben des Formularfeldes. Mit "Speichern" wird der
										Bestimmungsort hinzugefügt.
										<br />
										In der Tabelle werden alle Bestimmungsorte angezeigt. Um einen Bestimmungsort zu löschen, kann das
										Papierkorb Symbol geklickt werden. Um einen Bestimmungsort zu bearbeiten, kann das Stift Symbol
										geklickt werden. <br />
										Nach dem Bearbeiten kann der Bestimmungsort mit "Speichern" gespeichert werden.
									</p>
								</li>
								<li className=' mb-10'>
									<h3 className='font-semibold mb-2' id='speditionen'>
										Speditionen
									</h3>
									<p>
										Unter dem Menüpunkt "Speditionen" können Speditionen hinzugefügt werden. Das Formularfeld
										"Bezeichnung" ist ein Pflichtfeld. Das Feld "Beschreibung" ist optional.
										<br />
										Der Button "Zurücksetzen" löscht alle Eingaben des Formularfeldes. Mit "Speichern" wird die
										Spedition hinzugefügt.
										<br />
										In der Tabelle werden alle Speditionen angezeigt. Um eine Spedition zu löschen, kann das Papierkorb
										Symbol geklickt werden. Um eine Spedition zu bearbeiten, kann das Stift Symbol geklickt werden.{' '}
										<br />
										Nach dem Bearbeiten kann die Spedition mit "Speichern" gespeichert werden.
									</p>
								</li>
								<li className=' mb-10'>
									<h3 className='font-semibold mb-2' id='benutzer'>
										Benutzer
									</h3>
									<p>
										Unter dem Menüpunkt "Benutzer" können weitere ackercloud Benutzer dem Unternehmen hinzugefügt
										werden. <br />
										Die Felder "Email" und "Passwort" sind Pflichtfelder. Die Felder "Vorname", "Nachname" sind
										optional. <br />
										Der Button "Benutzer anlegen" speichert den Benutzer. Der Benutzer kann sich nun über seine Email
										und das gewählte Passwort anmelden.
									</p>
								</li>
								<li className=' mb-10'>
									<h3 className='font-semibold mb-2' id='system'>
										System
									</h3>
									<p>
										Unter dem Menüpunkt "System" können Daten zum Unternehmen hinterlegt werden. Hierzu zählen die
										Felder "Produzent", "Anlieferer Nr.", "GGN Nr." und das "Betriebskürzel". <br />
										Die "Lieferscheinnummer Startnummer" ist die Nummer, mit der der erste erstellte Lieferschein
										beginnt. Alle weiteren Lieferscheine werden fortlaufend angelegt. Nach dem ersten erstellten
										Lieferschein hat diese Nummer keine Funktion mehr. <br /> Mit dem Button "Speichern" werden die
										Daten gespeichert.
									</p>
								</li>
							</ol>
						</li>
						<li className='mb-10'>
							<h2 className='text-lg font-bold mb-4' id='warenwirtschaft'>
								Warenwirtschaft
							</h2>
							<ol className='list-decimal font-normal sm:ml-4 mb-2 tracking-normal'>
								<li className=' mb-10'>
									<h3 className='font-semibold mb-2' id='lieferscheine'>
										Lieferscheine
									</h3>
									<p className='mb-4'>
										Der Menüpunkt Warenwirtschaft / Lieferscheine zeigt über eine Tabelle alle erstellten Lieferscheine
										an. Diese Anzeige kann über Filter (siehe 4.2.) eingegrenzt werden oder über die Sortierung nach den
										eigenen Wünschen angepasst werden. Mehr zur Benutzung von Tabellen siehe 4.3.
										<br />
										Neben den relevanten Daten zu den einzelnen Lieferscheinen befinden sich in der rechten Spalte
										mehrere Aktionsbuttons.
										<br />
										<ul className='list-disc pl-6'>
											<li>
												<InformationCircleIcon width={16} style={{ display: 'inline' }} /> gibt an, dass die eingehenden
												Paletten nicht der Zahl der ausgehenden Paletten entspricht. D.h. die Paletten wurden nicht 1:1
												getauscht.
											</li>
											<li>
												<PrinterIcon width={16} style={{ display: 'inline' }} /> druckt einen Lieferschein.
											</li>
											<li>
												<PencilAltIcon width={16} style={{ display: 'inline' }} /> öffnet einen Lieferschein zur Ansicht
												oder Bearbeitung.
											</li>
											<li>
												<DotsVerticalIcon width={16} style={{ display: 'inline' }} /> öffnet ein Untermenü
												<ul className='list-inside list-disc'>
													<li>
														<PencilAltIcon width={16} style={{ display: 'inline' }} /> öffnet einen Lieferschein zur
														Ansicht oder Bearbeitung.
													</li>
													<li>
														<PrinterIcon width={16} style={{ display: 'inline' }} /> druckt einen Lieferschein.
													</li>
													<li>
														<DocumentDownloadIcon width={16} style={{ display: 'inline' }} /> lädt einen Lieferschein
														herunter.
													</li>
													<li>
														<XCircleIcon width={16} style={{ display: 'inline' }} /> storniert einen Lieferschein.
													</li>
													<li>
														<TrashIcon width={16} style={{ display: 'inline' }} /> löscht einen Lieferschein.
													</li>
												</ul>
											</li>
										</ul>
									</p>
									<p>
										Stornierte Lieferscheine können über das Untermenü weiterhin geöffnet werden. Klickt man im
										geöffneten Lieferschein auf "aktualisieren", wird der Lieferschein wieder aktiv und ist nun nicht
										mehr storniert.
									</p>
								</li>
								<li className=' mb-10'>
									<h3 className='font-semibold mb-2' id='lieferschein-anlegen'>
										Lieferschein anlegen
									</h3>
									<p>
										Neue Lieferscheine können unter Warenwirtschaft / Lieferscheine angelegt werden, indem man auf
										"Neuer Lieferschein" klickt. Es öffnet sich ein neuer Lieferschein.
										<br />
										Das Lieferdatum wird mit dem aktuellen Datum vorab gefüllt, kann jedoch geändert werden. Die
										Beleg-Nr. wird beim Speichern automatisch vergeben und ist nicht änderbar. Das Eingabefeld
										"Bestell-Nr." ist frei wählbar, während die Felder "Bestimmungsort" und "Spedition" Drop-Down-Listen
										sind mit vordefinierten Werten. Bestimmungsorte und Speditionen können in den Einstellungen erstellt
										und bearbeitet werden (siehe 2.3. und 2.4.).
										<br />
										In der folgenden Tabelle können einzelne Positionen des Lieferscheines ausgefüllt und hinzugefügt
										werden. Die Felder "VA Kisten", "Paletten", "Klasse", "Produkt", "Größe", "Einheit", "Ursprung" und
										"Regionalfenster" sind Drop-Down-Listen mit vordefinierten Werten.
										<br />
										Kisten, Produkte und dazugehörige Produktgrößen, können in den Einstellungen erstellt und bearbeitet
										werden (siehe 2.1. und 2.2.).
										<br />
										Um eine neue Reihe in den Position hinzufügen, klickt man auf "Position hinzufügen". Die neue
										Position wird unten an die bestehenden Positionen angehängt.
										<br />
										Die Tabellen "Leergut geliefert" und "Leergut zurück" werden mit den eingehenden und ausgehenden
										Kisten und Paletten der Positionen im Lieferschein automatisch ausgefüllt, können jedoch angepasst
										werden.
										<br />
										Das Feld "Unterschrift" bietet die Möglichkeit auf einem Touchpad digital zu Unterschreiben. Hierzu
										klickt man auf das Unterschriftfeld und kann im nun geöffneten Dialogfeld seinen Lieferschein
										digital Unterschreiben.
										<br />
										Um den Lieferschein zu speichern, klickt man auf "Speichern". "Alle Lieferscheine" führt zurück zur
										Übersicht Warenwirtschaft / Lieferscheine.
									</p>
								</li>
								<li className=' mb-10'>
									<h3 className='font-semibold mb-2' id='bestaende'>
										Bestände
									</h3>
									<p className='mb-4'>
										Der Menüpunkt Warenwirtschaft / Bestände zeigt in der großen Haupttabelle alle Zu- und Abgänge an.
										Diese Anzeige kann über Filter (siehe 4.2.) eingegrenzt werden oder über die Sortierung nach den
										eigenen Wünschen angepasst werden. Mehr zur Benutzung von Tabellen siehe 4.3.
										<br />
										Neben den relevanten Daten zu den einzelnen Zu- und Abgängen befinden sich in der rechten Spalte
										mehrere Aktionsbuttons.
										<br />
										<ul className='list-disc pl-6'>
											<li>
												<ExternalLinkIcon width={16} style={{ display: 'inline' }} /> öffnet den verknüpften
												Lieferschein zum Zu-/Abgang.
											</li>
											<li>
												<PencilAltIcon width={16} style={{ display: 'inline' }} /> öffnet den Zu-/Abgang.
											</li>
											<li>
												<TrashIcon width={16} style={{ display: 'inline' }} /> löscht einen Zu-/Abgang.
											</li>
										</ul>
									</p>
									<p className='mb-4'>
										In der oberen, kleineren Tabelle werden die aktuellen Gesamtbestände von Kisten und Paletten im
										gewählten Zeitraum angezeigt. Über die Filter (siehe 4.2.) können auch Bestände eines bestimmten
										Kunden angezeigt werden, wenn in das Filter Eingabefeld der Name des Kundens eingegeben wird. <br />
										Mit einem Klick auf eine Spalte im Kopf der Tabelle (Bsp. A18) wird das Filter Eingabefeld
										automatisch damit gefüllt und es werden in dem Beispiel nur Einträge angezeigt, in denen "A18"
										enthalten ist. <br />
										Mit einem Klick auf den Bestand, öffnet sich ein neues Fenster. In diesem Fenster bekommt man eine
										Übersicht angezeigt, aus welchen Einzelbeständen sich der Gesamtbestand errechnet. Zum Beispiel
										steht dort, welcher Einzelbestand welchem Kunden zugeordnet ist.
									</p>
									<p>
										Der Drucken Button <PrinterIcon width={16} style={{ display: 'inline' }} /> in der Filterleiste,
										bietet die Möglichkeit, eine Liste aller Zu- und Abgänge bestimmter Paletten oder Kisten
										auszudrucken oder zu speichern.
										<br />
										Fährt man mit der Maus über das Symbol, kann man über Checkboxen die gewünschten Paletten oder
										Kisten auswählen die in der Liste erscheinen sollen. Über das Filter Eingabefeld oder die beiden
										Datumsfelder, können die Daten weiter eingegrenzt werden, z.B. alle Zu- und Abgänge eines bestimmten
										Kunden, innerhalb eines festgelegten Zeitraumes. Im Anschluss klickt man auf "Drucken". Es öffnet
										sich ein neues Fenster mit dem erstellten PDF Dokument.
									</p>
								</li>
								<li className=' mb-10'>
									<h3 className='font-semibold mb-2' id='zu-abgang-anlegen'>
										Zu-/Abgang anlegen
									</h3>
									<p>
										Jeder angelegte Lieferschein der eingehende oder ausgehende Kisten oder Paletten enthält, legt
										automatisch unter Bestände einen Warenzugang bzw. Warenabgang an. Neue Zu- und Abgänge können unter
										Warenwirtschaft / Bestände angelegt werden, indem man auf "Zu-/Abgang" klickt. Es öffnet sich ein
										neues Dialogfenster.
										<br />
										Das Buchungsdatum wird mit dem aktuellen Datum automatisch ausgefüllt, kann jedoch geändert werden.
										Die Felder "Lieferant" und "Spedition" sind Drop-Down-Listen mit vordefinierten Werten. Lieferanten
										und Speditionen können in den Einstellungen angelegt und bearbeitet werden (siehe 2.3. und 2.4.).
										Die Eingabefelder "Lieferschein-Nr." und "Buchungstext" sind frei wählbar.
										<br />
										In der folgenden Tabelle können einzelne Positionen der Zu- und Abgänge ausgefüllt und hinzugefügt
										werden. Die Felder "Typ", "Kategorie", "Produkt" und "Einheit" sind Drop-Down-Listen mit
										vordefinierten Werten. Produkte (Kisten) können in den Einstellungen angelegt und bearbeitet werden
										(siehe 2.2.). Das Eingabefeld "Menge" ist frei wählbar.
										<br />
										Um Zu- und Abgänge zu speichern, klickt man auf "Speichern", um die Eingaben zu verwerfen und den
										Vorgang abzubrechen, klickt man auf "Schließen".
									</p>
								</li>
								<li className=' mb-10'>
									<h3 className='font-semibold mb-2' id='statistik'>
										Statistik
									</h3>
									<p>
										Der Menüpunkt Warenwirtschaft / Statistik ist derzeit nur ein Prototyp.
										<br />
										Aktuell können die am häufigsten verkauften Produkte angezeigt werden, die am häufigsten belieferten
										Kunden, sowie die am häufigsten verwendeten Speditionen.
										<br />
										Über die Filter (siehe 4.2.) besteht so die Möglichkeit, sich die gesamt gelieferte Ware an einen
										bestimmten Kunden, innerhalb eines festgelegten Zeitraumes, anzeigen zu lassen. <br />
										Denkbar wäre ein Ausbau dieser Funktion um die Wirtschaftlichkeit zu erfassen. Bsp. welche Menge
										habe ich gepflanzt, welche Menge habe ich ausgeliefert?
									</p>
								</li>
							</ol>
						</li>
						<li className='mb-10'>
							<h2 className='text-lg font-bold mb-4' id='benutzung'>
								Benutzung
							</h2>
							<ol className='list-decimal font-normal sm:ml-4 mb-2 tracking-normal'>
								<li className=' mb-10'>
									<h3 className='font-semibold mb-2' id='filter'>
										Filter
									</h3>
									<p>
										Die Tabellen der Lieferscheine und Bestände bieten die Möglichkeit Daten nach verschiedenen
										Kriterien zu filtern. <br />
										Über das Eingabefeld "Filter" kann nach sämtlichen Attributen gefiltert werden wie
										Lieferscheinnummern, Bestimmungsorten, Speditionen und Weiteren. Die Daten werden während des
										Tippens in Echtzeit gefiltert und angezeigt. <br />
										Möchte man Daten über das Datum einschränken, erfolgt dies über die Datumsfelder "Von"/"Bis". Nach
										dem Ändern des Datums muss der Refresh Button{' '}
										<RefreshIcon width={16} style={{ display: 'inline' }} /> gedrückt werden um die Daten zu
										aktualisieren.
										<br />
										Der Button "Alle Filter zurücksetzen" setzt alle Filter zurück. Hierzu zählt das Eingabefeld
										"Filter" und die Datumsfelder.
										<br />
										Aktive Filter werden durch einen roten Indikator{' '}
										<span className='inline-block h-2 w-2 rounded-full bg-red-500'></span> gekennzeichnet.
									</p>
								</li>
								<li className=' mb-10'>
									<h3 className='font-semibold mb-2' id='tabellen'>
										Tabellen
									</h3>
									<p>
										Die Tabellen der Lieferscheine und Bestände bieten die Möglichkeit die Daten nach verschiedenen
										Spalten zu sortieren. Hierzu klickt man auf den Kopf der entsprechenden Spalte. Die Pfeile{' '}
										<SwitchVerticalIcon width={16} style={{ display: 'inline' }} /> zeigen an, nach welcher Spalte
										sortiert wird und ob die Sortierung aufsteigend oder absteigend erfolgt.
										<br />
										Im Fuß der Tabelle befinden sich Informationen zu der Gesamtanzahl der gefundenen Ergebnisse, sowie
										die Möglichkeit die maximale Anzahl der Ergebnisse pro Seite zu wählen. <br />
										Mit den Navigationsbuttons kann bequem durch die Daten navigiert werden. Die Zahl zwischen den
										Navigationsbuttons gibt die akutelle Seitenzahl an, kann jedoch auch verwendet werden um gezielt zu
										einer bestimmten Seite zu springen. Hierzu gibt man die gewünschte Seitenzahl in das Feld ein.
									</p>
								</li>
							</ol>
						</li>
					</ol>
				</div>
			</div>
		</div>
	);
};

export default Docs;
