import { useEffect } from "react";
import useMostSuppliedDestinations from "../../hooks/useMostSuppliedDestinations";

const MostSuppliedDestinationsTable = ({
  search,
  dateFrom,
  dateTo,
  setSearchDestinations,
}: any) => {
  const mostSuppliedDestinations = useMostSuppliedDestinations(
    search,
    dateFrom,
    dateTo
  );

  useEffect(() => {
    if (mostSuppliedDestinations.data)
      setSearchDestinations(mostSuppliedDestinations.data);
  }, [mostSuppliedDestinations.data]);

  return (
    <table className="ac-table">
      <thead>
        <tr>
          <th>Bestimmungsort</th>
          <th className="!text-right">Lieferungen</th>
        </tr>
      </thead>
      <tbody>
        {mostSuppliedDestinations.data?.map((destination: any) => {
          if (destination.supplies > 0)
            return (
              <tr key={destination.label}>
                <td>{destination.label || "unbekannt"}</td>
                <td className="text-right">
                  {destination.supplies.toLocaleString("de-DE")}
                </td>
              </tr>
            );
        })}
      </tbody>
    </table>
  );
};

export default MostSuppliedDestinationsTable;
