import { useContext } from 'react';
import { useQuery } from 'react-query';
import { UserContext } from '../App';
import { supabase } from '../utils/client';

const useStocks = (
	page = 0,
	rowsPerPage = 10,
	search: string | undefined,
	order: string | undefined,
	ascending: boolean = false,
	dateFrom: string,
	dateTo: string,
	searchExact: boolean,
	incoming: boolean,
	outgoing: boolean,
) => {
	const { acId } = useContext(UserContext);
	const from = page * rowsPerPage;
	const to = page * rowsPerPage + rowsPerPage - 1;
	let amount = 0;
	if (search && !isNaN(Number(search))) amount = Number(search);

	return useQuery(
		['stocks', page, rowsPerPage, search, order, ascending, dateFrom, dateTo, searchExact, incoming, outgoing, acId],
		async () => {
			let query = supabase
				.from('stock')
				.select(
					` id,
					fk_delivery_note,
					label,
					category,
					type,
					amount,
					unit,
					booking_date,
					booking_description,
					deliverer,
					delivery_note_no,
					carrier
					`,
					{ count: 'exact' }
				)
				.eq('fk_ac_customer', acId)
				.range(from, to)
				.gte('booking_date', dateFrom)
				.lte('booking_date', dateTo);

			// .order('booking_date', { ascending: false })
			// .order('inserted_at', { ascending: false })

			if (search) {
				if (searchExact) {
					let am = '';
					if (amount > 0) am = `amount.eq.${amount},`;
					query = query.or(
						`${am}delivery_note_no.ilike.${search},deliverer.ilike.${search},label.ilike.${search},booking_description.ilike.${search},carrier.ilike.${search}`
					);
				} else {
					query = query.or(
						`amount.eq.${amount},delivery_note_no.ilike.%${search}%,deliverer.ilike.%${search}%,label.ilike.%${search}%,booking_description.ilike.%${search}%,carrier.ilike.%${search}%`
					);
				}
			}
			if (order) {
				if (order === 'booking_date') {
					query = query.order('booking_date', { ascending: ascending });
					query = query.order('inserted_at', { ascending: ascending });
				} else {
					query = query.order(order, { ascending: ascending });
				}
			}
			if(incoming && !outgoing) {
				query = query.eq('type', 'increase')
			}
			if(outgoing && !incoming) {
				query = query.eq('type', 'decrease')
			}
			if(!incoming && !outgoing) {
				query = query.or(`type.eq.increase,type.eq.decrease`)
			}
			// if (dateFrom) {
			// 	query = query.gte('booking_date', dateFrom);
			// }
			// if (dateTo) {
			// 	query = query.lte('booking_date', dateTo);
			// }
			// if (date) {
			// 	query = query.or(`delivery_note_no.ilike.%${search}%,deliverer.ilike.%${search}%,label.ilike.%${search}%`);
			// }

			const queryRes = await query;
			const response = await handleSupabaseError(queryRes);
			return response;
		},
		{ refetchOnWindowFocus: true, staleTime: 0 }
	);
	// return useQuery(
	// 	['stocks', page, rowsPerPage, search],
	// 	async () =>
	// 		await supabase
	// 			.from('stock')
	// 			.select(
	// 				` id,
	// 				fk_delivery_note,
	// 				label,
	// 				category,
	// 				type,
	// 				amount,
	// 				unit,
	// 				booking_date,
	// 				booking_description,
	// 				deliverer,
	// 				delivery_note_no
	// 				`,
	// 				{ count: 'exact' }
	// 			)
	// 			.eq('fk_ac_customer', acId)
	// 			.or(`delivery_note_no.ilike.%${search}%`)
	// 			.order('booking_date', { ascending: false })
	// 			.order('inserted_at', { ascending: false })
	// 			.range(from, to)
	// 			.then(handleSupabaseError)
	// 			.then((data) => data),
	// 	{ enabled: !!acId, refetchOnWindowFocus: true, staleTime: 0 }
	// );
};

export default useStocks;

const handleSupabaseError = ({ error, ...rest }: any) => {
	if (error) {
		throw error;
	}
	return rest;
};
