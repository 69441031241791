import { FC } from 'react';

interface Select {
	placeholder?: string;
	id: string;
	style?: string;
	rules?: any;
	options?: any[];
	listname?: string;
	register: any;
	autofocus?: boolean;
	onFocus?: any;
	errors?: any;
	onChange?: any;
}

const SelectClassic: FC<Select> = ({
	placeholder,
	id,
	style,
	rules,
	options,
	listname,
	register,
	autofocus,
	onFocus,
	errors,
	onChange,
}) => {
	return (
		<fieldset className={`relative classic-input ${style || ''}`}>
			<select
				{...register(id, rules)}
				list={listname || ''}
				className={`${errors?.message ? 'border-red-600 focus-within:!border-red-400' : ''} peer min-w-full !w-auto`}
				name={id}
				id={id}
				autoComplete='off'
				autoFocus={autofocus || false}
				onFocus={onFocus}
				onChange={onChange}
			>
				{placeholder && <option value=''>{placeholder}</option>}
				{options &&
					options.map((item: any, index: any) => (
						<option key={(item.key || item.label) + '-' + index} value={item.key || item.label}>
							{item.label}
						</option>
					))}
			</select>
			{errors?.message && (
				<span className='absolute text-red-500 bottom-3.5 left-2.5 z-50 text-sm'>{errors?.message}</span>
			)}
		</fieldset>
	);
};

export default SelectClassic;
