import { useContext } from 'react';
import { useQuery } from 'react-query';
import { UserContext } from '../App';
import { supabase } from '../utils/client';

const useMostUsedCarriers = (filter_input: string | undefined, date_from: string, date_to: string) => {
	const { acId } = useContext(UserContext);

	return useQuery(
		['most_used_carriers', filter_input, date_from, date_to, acId],
		async () =>
			await supabase
				.rpc('get_most_used_carriers', {
					filter_input: filter_input || '',
					date_from: date_from,
					date_to: date_to,
				})
				.then(handleSupabaseError)
				.then(({ data }) => data),
		{
			enabled: !!date_from && !!date_to,
			refetchOnWindowFocus: true,
			staleTime: 15000,
		}
	);
};

export default useMostUsedCarriers;

const handleSupabaseError = ({ error, ...rest }: any) => {
	if (error) {
		throw error;
	}
	return rest;
};

// .rpc('get_inventory_stock')
