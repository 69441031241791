import moment from 'moment';
import Pagination from './Pagination';
import useDeliveryNotes from '../hooks/useDeliveryNotes';
import useUpdateDeliveryNote from '../hooks/useUpdateDeliveryNote';
import {
	DocumentDownloadIcon,
	PencilAltIcon,
	PrinterIcon,
	TrashIcon,
	RefreshIcon,
	InformationCircleIcon,
	ReceiptRefundIcon,
	XCircleIcon,
} from '@heroicons/react/outline';
import { MerchandiseContext, UserContext } from '../App';
import StyledInput from './form/StyledInput';
import CustomFilterIcon from './CustomFilterIcon';
import { Tooltip } from './Tooltip';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import { useAuth } from '../provider/AuthProvider';
import { useContext, useEffect, useState } from 'react';

interface Note {
	id: number;
	inserted_at: string;
	company_short: string;
	deliverer_no: number;
	delivery_notes_date: string;
	delivery_notes_no: number;
	ggn_no: string;
	producer: string;
	signature_carrier: string;
	signature_employee: string;
	delivery_notes_items: any[];
	delivery_notes_pallets: any;
	delivery_notes_delivered_boxes: any[];
	delivery_notes_returned_boxes: any[];
	destination: string;
	consignment_number: string;
	carrier: string;
	is_canceled: boolean;
	is_deleted: boolean;
}

const ReceiptsTrash = () => {
	const { setReceiptOrder, receiptsPage, setReceiptsPage, receiptOrder, receiptAscending, setReceiptAscending } =
		useContext(MerchandiseContext);
	const [receiptsPerPage, setReceiptsPerPage] = useState<number>(10);
	const { user } = useAuth();

	const [deliveryNoteToUpdate, setDeliveryNoteToUpdate] = useState<any>({
		id: undefined,
		user: undefined,
		is_canceled: false,
		is_deleted: false,
	});
	const deliveryNotes = useDeliveryNotes(
		receiptsPage,
		receiptsPerPage,
		undefined,
		receiptOrder,
		receiptAscending,
		'1970-01-01',
		'2100-01-01',
		true
	);
	const [totalElements, setTotalElements] = useState<number>(0);
	const updateDeliveryNote = useUpdateDeliveryNote(deliveryNoteToUpdate);

	const restoreReceipt = (id: number) => {
		setDeliveryNoteToUpdate({
			id: id,
			user: user,
			is_canceled: false,
			is_deleted: false,
		});
	};

	useEffect(() => {
		if (deliveryNoteToUpdate.id && deliveryNoteToUpdate.user) {
			updateDeliveryNote.mutate();
		}
	}, [deliveryNoteToUpdate]);

	useEffect(() => {
		if (updateDeliveryNote.isSuccess) {
			setDeliveryNoteToUpdate({ id: undefined, user: undefined, is_canceled: false, is_deleted: false });
			deliveryNotes.refetch();
		}
	}, [updateDeliveryNote.isSuccess]);

	const handleFilter = (label: string) => {
		setReceiptOrder(label);
		if (label === receiptOrder) {
			setReceiptAscending(!receiptAscending);
			setReceiptsPage(0);
		} else {
			setReceiptAscending(false);
			setReceiptsPage(0);
		}
	};

	return (
		<div className='py-12'>
			<h1 className='mb-11 font-semibold text-lg'>Papierkorb - gelöschte Lieferscheine</h1>
			<div className='overflow-visible'>
				{deliveryNotes.data?.data && (
					<table className='ac-table'>
						<thead>
							<tr>
								<th className='cursor-pointer' onClick={() => handleFilter('delivery_notes_no')}>
									<div className='flex justify-between items-center'>
										<span>Beleg-Nr.</span>
										<CustomFilterIcon
											width={20}
											upColor={receiptOrder === 'delivery_notes_no' && receiptAscending ? 'fill-white' : undefined}
											downColor={receiptOrder === 'delivery_notes_no' && !receiptAscending ? 'fill-white' : undefined}
										/>
									</div>
								</th>
								<th className='cursor-pointer' onClick={() => handleFilter('consignment_number')}>
									<div className='flex justify-between items-center'>
										<span>Bestell-Nr.</span>
										<CustomFilterIcon
											width={20}
											upColor={receiptOrder === 'consignment_number' && receiptAscending ? 'fill-white' : undefined}
											downColor={receiptOrder === 'consignment_number' && !receiptAscending ? 'fill-white' : undefined}
										/>
									</div>
								</th>
								<th className='cursor-pointer' onClick={() => handleFilter('delivery_notes_date')}>
									<div className='flex justify-between items-center'>
										<span>Lieferdatum</span>
										<CustomFilterIcon
											width={20}
											upColor={receiptOrder === 'delivery_notes_date' && receiptAscending ? 'fill-white' : undefined}
											downColor={receiptOrder === 'delivery_notes_date' && !receiptAscending ? 'fill-white' : undefined}
										/>
									</div>
								</th>
								<th>
									<span>Produkte</span>
								</th>
								<th className='cursor-pointer' onClick={() => handleFilter('destination')}>
									<div className='flex justify-between items-center'>
										<span>Bestimmungsort</span>
										<CustomFilterIcon
											width={20}
											upColor={receiptOrder === 'destination' && receiptAscending ? 'fill-white' : undefined}
											downColor={receiptOrder === 'destination' && !receiptAscending ? 'fill-white' : undefined}
										/>
									</div>
								</th>
								<th className='cursor-pointer' onClick={() => handleFilter('carrier')}>
									<div className='flex justify-between items-center'>
										<span>Spedition</span>
										<CustomFilterIcon
											width={20}
											upColor={receiptOrder === 'carrier' && receiptAscending ? 'fill-white' : undefined}
											downColor={receiptOrder === 'carrier' && !receiptAscending ? 'fill-white' : undefined}
										/>
									</div>
								</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{deliveryNotes.data?.data?.map((note: Note, index: number) => (
								<tr key={index}>
									<td>{note.delivery_notes_no}</td>
									<td>{note.consignment_number}</td>
									<td>{moment(note.delivery_notes_date).format('DD.MM.YYYY')}</td>
									<td>
										{note?.delivery_notes_items?.map(
											(item: any, index: number) =>
												JSON.parse(item).product_label + (note?.delivery_notes_items.length > index + 1 ? ', ' : '')
										)}
									</td>
									<td>{note?.destination}</td>
									<td>{note?.carrier}</td>
									<td className='w-32'>
										<div className='px-2 flex items-center gap-4 justify-end'>
											<button onClick={(_) => restoreReceipt(note?.id)}>wiederherstellen</button>
										</div>
									</td>
								</tr>
							))}
						</tbody>
						<tfoot className='border'>
							<tr>
								<td colSpan={7}>
									<Pagination
										count={deliveryNotes.data ? deliveryNotes.data?.data?.length : 0}
										totalElements={totalElements}
										setPage={setReceiptsPage}
										page={receiptsPage}
										rowsPerPage={receiptsPerPage}
										setRowsPerPage={setReceiptsPerPage}
									/>
								</td>
							</tr>
						</tfoot>
					</table>
				)}
			</div>
		</div>
	);
};

export default ReceiptsTrash;
