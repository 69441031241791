import React from 'react';
import AdminNav from '../components/AdminNav';
const AdminLayout = ({ children }) => {
	return (
		<>
			<AdminNav />
			<div className='container pt-24 pb-4 px-4'>{children}</div>
		</>
	);
};

export default AdminLayout;
