import { useContext } from 'react';
import { useQuery } from 'react-query';
import { UserContext } from '../App';
import { supabase } from '../utils/client';

const useAttachedFiles = (subfolder: string | undefined = undefined) => {
	const { acId } = useContext(UserContext);

	return useQuery(
		['attached-files', subfolder],
		async () =>
			await supabase.storage
				.from('user-files')
				.list(`${acId}${subfolder ? '/' + subfolder : ''}`, {
					sortBy: { column: 'name', order: 'asc' },
				})
				.then(handleSupabaseError)
				.then((data) => data),
		{ enabled: !!acId && !!subfolder, refetchOnWindowFocus: false }
	);
};

export default useAttachedFiles;

const handleSupabaseError = ({ error, ...rest }: any) => {
	if (error) {
		throw error;
	}
	return rest;
};
