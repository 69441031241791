import { useMutation } from 'react-query';
import { supabase } from '../utils/client';

const useUploadFile = () => {
	return useMutation(['file-upload'], File);
};

export default useUploadFile;

const File = async (data: {
	acId: string;
	subfolder: string | undefined;
	fileName: string;
	file:
		| string
		| ArrayBuffer
		| ArrayBufferView
		| Blob
		| Buffer
		| File
		| FormData
		| NodeJS.ReadableStream
		| ReadableStream<Uint8Array>
		| URLSearchParams;
	contentType: string;
}) => {
	if (data.file) console.log(data.file);
	return await supabase.storage
		.from('user-files')
		.upload(`${data.acId}${data.subfolder ? '/' + data.subfolder : ''}/${data.fileName}`, data.file, {
			cacheControl: '3600',
			upsert: false,
			contentType: data.contentType + ';charset=utf-8',
		})
		.then(handleSupabaseError)
		.then(({ data }) => data);
};

const handleSupabaseError = ({ error, ...rest }: any) => {
	if (error) {
		throw error;
	}
	return rest;
};
