import { useContext } from 'react';
import { useQuery } from 'react-query';
import { UserContext } from '../App';
import { supabase } from '../utils/client';

const useUsers = () => {
	const { acId } = useContext(UserContext);

	return useQuery(
		['ac_users', acId],
		async () =>
			await supabase
				.from('ac_customers')
				.select(
					` email,
					users:ac_users(
						id,
						email,
						user_role,
						firstname,
						lastname
					)
				`
				)
				.eq('ackercloud_id', acId)
				.single()
				.then(handleSupabaseError)
				.then(({ data }) => data),
		{
			refetchOnWindowFocus: false,
			cacheTime: Infinity,
		}
	);
};

export default useUsers;

const handleSupabaseError = ({ error, ...rest }: any) => {
	if (error) {
		throw error;
	}
	return rest;
};
