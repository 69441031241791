import { useContext } from 'react';
import { useMutation } from 'react-query';
import { supabase } from '../utils/client';
import { UserContext } from '../App';
import moment from 'moment';

const useAddProductSizes = (product) => {
	const { acId } = useContext(UserContext);

	const sizes = [];
	if (product?.products_sizes)
		product.products_sizes.map((size) => {
			if (size.label) {
				sizes.push({
					id: size.id ? size.id : null,
					fk_ac_customer: acId,
					fk_inventory: product.id,
					label: size.label,
					updated_at: moment().toISOString(),
				});
			}
		});

	return useMutation(
		['products.products_sizes'],
		async () =>
			await supabase
				.from('products_sizes')
				.upsert(sizes, { onConflict: 'id' })
				.then(handleSupabaseError)
				.then(({ data }) => data)
	);
};

export default useAddProductSizes;

const handleSupabaseError = ({ error, ...rest }) => {
	if (error) {
		throw error;
	}
	return rest;
};
