import { useContext, useEffect, useState } from 'react';
import {
	DocumentIcon,
	FolderAddIcon,
	DocumentAddIcon,
	PencilAltIcon,
	TrashIcon,
	DocumentDownloadIcon,
} from '@heroicons/react/outline';
import { FolderIcon } from '@heroicons/react/solid';
import AdminLayout from '../layouts/AdminLayout';
import useListFiles from '../hooks/useListFiles';
import moment from 'moment';
import { supabase } from '../utils/client';
import useUploadFile from '../hooks/useUploadFile';
import { useForm } from 'react-hook-form';
import { UserContext } from '../App';
import useDeleteFiles from '../hooks/useDeleteFiles';
import Modal from 'react-modal';
import StyledInput from '../components/form/StyledInput';
import useMoveFile from '../hooks/useMoveFile';

const Documents = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
	const [folderName, setFolderName] = useState<string>('');
	const { acId } = useContext(UserContext);
	const [root, setRoot] = useState<string>(acId);

	const [subFolder, setSubFolder] = useState<string | undefined>(undefined);
	const listFiles = useListFiles(subFolder);
	const uploadFile = useUploadFile();
	const deleteFiles = useDeleteFiles();
	const moveFile = useMoveFile();

	useEffect(() => {
		if (listFiles.isSuccess) console.log('files', listFiles?.data?.data);
	}, [listFiles.isSuccess]);

	const createFolder = async () => {
		await supabase.storage
			.from('user-files')
			.upload(`${acId}/${subFolder ? subFolder + '/' + folderName : folderName}/.emptyFolderPlaceholder`, '');
		setModalIsOpen(false);
		listFiles.refetch();
	};

	const onSubmit = (data: any) => {
		if (data.file) {
			Object.entries(data.file).forEach((file: any) => {
				const uploadData = {
					acId: acId,
					subfolder: subFolder,
					file: file[1],
					fileName: file[1].name,
					contentType: file[1].type,
				};
				uploadFile.mutate(uploadData);
			});
		}
	};

	useEffect(() => {
		if (uploadFile.isSuccess || deleteFiles.isSuccess) listFiles.refetch();
	}, [uploadFile.isSuccess, deleteFiles.isSuccess]);

	const deleteFile = (file: string) => {
		deleteFiles.mutate([file]);
	};

	const download = async (path: string) => {
		const data = await supabase.storage
			.from('user-files')
			.download(path)
			.then((data) => data);
		return data;
	};
	const openFile = async (path: string) => {
		const data = await download(path);
		if (data.data) {
			const file = URL.createObjectURL(data.data);
			window.open(file, 'PRINT', 'height=400,width=600');
		}
	};

	const saveFile = async (path: string, fileName: string) => {
		const data = await download(path);
		if (data.data) {
			const file = URL.createObjectURL(data.data);
			const a = document.createElement('a');
			document.body.appendChild(a);
			a.style.setProperty('display', 'none');
			a.href = file;
			a.download = fileName;
			a.click();
			window.URL.revokeObjectURL(file);
		}
	};

	const renameFileOrFolder = (from: string, to: string) => {
		const paths = {
			from: '',
			to: '',
		};
		moveFile.mutate(paths);
	};

	return (
		<AdminLayout>
			<div className='relative flex justify-end pt-3 items-center'>
				<div className='flex gap-8 '>
					<button className='btn btn-light flex items-center gap-2' onClick={(_) => setModalIsOpen(true)}>
						<FolderAddIcon className='w-5 h-5' />
						Ordner erstellen
					</button>
					<form onChange={handleSubmit(onSubmit)} className='flex gap-4 items-center'>
						<div>
							<input type='file' id='file' {...register('file')} multiple className='hidden' />
							<label htmlFor='file' className='btn btn-primary flex items-center gap-2 cursor-pointer'>
								<DocumentAddIcon className='w-5 h-5' /> Dateien hochladen
							</label>
						</div>
					</form>
				</div>
			</div>
			<div>
				<p className='mb-2'>
					{subFolder ? (
						<span>
							<a className='cursor-pointer underline' onClick={(_) => setSubFolder(undefined)}>
								Hauptverzeichnis
							</a>{' '}
							/
						</span>
					) : (
						<span>Hauptverzeichnis / </span>
					)}
					{subFolder &&
						subFolder?.split('/').map((folder: string, index) => {
							if (subFolder?.split('/').length === index + 1) {
								return (
									<span key={index.toString() + folder}>
										<a> {folder} </a>/
									</span>
								);
							} else {
								return (
									<span key={index.toString() + folder}>
										<a
											className='cursor-pointer underline'
											onClick={(_) =>
												setSubFolder(
													subFolder
														.split('/')
														.splice(0, index + 1)
														.toString()
														.replaceAll(',', '/')
												)
											}
										>
											{' '}
											{folder}
										</a>{' '}
										/
									</span>
								);
							}
						})}
				</p>
				<table className='ac-table'>
					<thead>
						<tr>
							<th className='w-10'></th>
							<th>Beschreibung</th>
							<th className='w-52'>Letzte Änderung</th>
							<th className=' w-24'></th>
						</tr>
					</thead>
					<tbody>
						{listFiles?.data?.data.map((item: any, index: number) => {
							if (!item.metadata)
								return (
									<tr key={item.name + 'folder' + index.toString()}>
										<td
											className='cursor-pointer'
											onClick={(_) => setSubFolder(subFolder ? `${subFolder}/${item.name}` : item.name)}
										>
											<FolderIcon className='h-6 w-6 text-slate-700' />
										</td>
										<td
											className='cursor-pointer'
											onClick={(_) => setSubFolder(subFolder ? `${subFolder}/${item.name}` : item.name)}
										>
											{item.name}
										</td>
										<td
											className='cursor-pointer'
											onClick={(_) => setSubFolder(subFolder ? `${subFolder}/${item.name}` : item.name)}
										></td>
										<td>
											<div className='flex justify-end gap-2'>
												<button>
													<PencilAltIcon width={20} />
												</button>
												<button
													onClick={(_) =>
														deleteFile(
															subFolder
																? `${acId}/${subFolder}/${item.name}/.emptyFolderPlaceholder`
																: `${acId}/${item.name}/.emptyFolderPlaceholder`
														)
													}
												>
													<TrashIcon width={20} className='text-red-600' />
												</button>
											</div>
										</td>
									</tr>
								);
						})}
						{listFiles?.data?.data.map((item: any, index: number) => {
							if (item.metadata && item.name !== '.emptyFolderPlaceholder')
								return (
									<tr key={item.name + 'files' + index.toString()}>
										<td
											className='cursor-pointer'
											onClick={(_) =>
												openFile(subFolder ? `${acId}/${subFolder}/${item.name}` : `${acId}/${item.name}`)
											}
										>
											<DocumentIcon className='h-6 w-6 text-slate-700' />
										</td>
										<td
											className='cursor-pointer'
											onClick={(_) =>
												openFile(subFolder ? `${acId}/${subFolder}/${item.name}` : `${acId}/${item.name}`)
											}
										>
											{item.name}
										</td>
										<td
											className=' cursor-pointer'
											onClick={(_) =>
												openFile(subFolder ? `${acId}/${subFolder}/${item.name}` : `${acId}/${item.name}`)
											}
										>
											{item.updated_at ? moment(item.updated_at).format('DD.MM.YYYY - HH:mm') : ''}
										</td>
										<td>
											<div className='flex justify-end gap-2'>
												<button
													onClick={(_) =>
														saveFile(
															subFolder ? `${acId}/${subFolder}/${item.name}` : `${acId}/${item.name}`,
															item.name
														)
													}
												>
													<DocumentDownloadIcon width={20} />
												</button>
												<button>
													<PencilAltIcon width={20} />
												</button>
												<button
													onClick={(_) =>
														deleteFile(subFolder ? `${acId}/${subFolder}/${item.name}` : `${acId}/${item.name}`)
													}
												>
													<TrashIcon width={20} className='text-red-600' />
												</button>
											</div>
										</td>
									</tr>
								);
						})}
					</tbody>
				</table>
				<Modal
					isOpen={modalIsOpen}
					className='absolute w-80  left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-white p-12'
					overlayClassName='bg-slate-700/90 z-50 absolute inset-0'
					shouldCloseOnOverlayClick={true}
					onRequestClose={(_) => setModalIsOpen(false)}
				>
					<StyledInput
						id='folder-name'
						label='Ordner-Name'
						autofocus={true}
						onKeyUp={(e: any) => setFolderName(e.target.value)}
					/>
					<button className='btn btn-primary w-full' onClick={createFolder}>
						Jetzt erstellen
					</button>
				</Modal>
			</div>
		</AdminLayout>
	);
};

export default Documents;
