import React, { useRef } from 'react';
import SignaturePad from 'react-signature-canvas';

const Signature = ({ setSignature, closeModal }) => {
	const sigCanvas = useRef({});

	const clear = () => sigCanvas.current.clear();
	const save = () => {
		setSignature(sigCanvas.current.getTrimmedCanvas().toDataURL('image/jpg'));
	};

	return (
		<div className='relative'>
			<SignaturePad
				ref={sigCanvas}
				canvasProps={{
					className: 'w-full h-96 border cursor-crosshair ',
				}}
			/>
			<div className='flex align-items justify-between pt-2'>
				<p className='text-lg'>Unterschrift</p>
				<div className='flex align-items justify-between gap-4'>
					<button onClick={save} type='button' className='btn btn-primary'>
						Verwenden
					</button>
					<button onClick={closeModal} type='button' className='btn btn-light'>
						Schliessen
					</button>
					<button onClick={clear} type='button' className='btn btn-light'>
						Neu
					</button>
				</div>
			</div>
		</div>
	);
};

export default Signature;
