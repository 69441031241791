import useBoxes from "../../hooks/useBoxes";
import useInventoryStock from "../../hooks/useInventoryStock";

interface Props {
  search?: string | undefined;
  searchExact?: boolean;
  dateFrom: string;
  dateTo: string;
  incoming?: boolean;
  outgoing?: boolean;
}

interface Stock {
  inventory_label: string;
  inventory_stock: string;
}

const OutgoingBoxesTable = ({
  search = undefined,
  searchExact = false,
  dateFrom,
  dateTo,
  incoming = false,
  outgoing = true,
}: Props) => {
  const inventoryStock = useInventoryStock(
    search,
    searchExact,
    dateFrom,
    dateTo,
    incoming,
    outgoing
  );
  const boxes = useBoxes();

  return (
    <table className="ac-table">
      <thead>
        <tr>
          <th>Produkt</th>
          <th className="!text-right">Menge</th>
          <th className="!text-right">EK netto</th>
          <th className="!text-right">EK brutto</th>
          <th className="!text-right">VK netto</th>
          <th className="!text-right">VK brutto</th>
          <th className="!text-right">Ust.</th>
        </tr>
      </thead>
      <tbody>
        {inventoryStock?.data?.map((stock: Stock, i: number) => (
          <tr key={stock.inventory_label + i.toString()}>
            <td>{stock.inventory_label || "unbekannt"}</td>
            <td className="!text-right">
              {Math.abs(Number(stock.inventory_stock)).toLocaleString("de")}
            </td>
            <td>
              {boxes?.data?.map((box: any, index: number) => {
                if (box.label === stock.inventory_label)
                  return (
                    <div
                      key={box.label + index.toString() + "ekn-out"}
                      className="flex gap-3 items-center justify-end"
                    >
                      {Math.abs(
                        Number(box.purchasing_price_netto) *
                          Number(stock.inventory_stock)
                      ).toLocaleString("de", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) + " € "}
                      <span className="text-sm text-slate-500">
                        (
                        {Number(box.purchasing_price_netto).toLocaleString(
                          "de",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        ) + " €/Stk."}
                        )
                      </span>
                    </div>
                  );
                return (
                  <span key={box.label + index.toString() + "ekn-out"}></span>
                );
              })}
            </td>
            <td>
              {boxes?.data?.map((box: any, index: number) => {
                if (box.label === stock.inventory_label)
                  return (
                    <div
                      key={box.label + index.toString() + "ekb-out"}
                      className="flex gap-3 items-center justify-end"
                    >
                      {Math.abs(
                        Number(box.purchasing_price_brutto) *
                          Number(stock.inventory_stock)
                      ).toLocaleString("de", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) + " € "}
                      <span className="text-sm text-slate-500">
                        (
                        {Number(box.purchasing_price_brutto).toLocaleString(
                          "de",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        ) + " €/Stk."}
                        )
                      </span>
                    </div>
                  );
                return (
                  <span key={box.label + index.toString() + "ekb-out"}></span>
                );
              })}
            </td>
            <td>
              {boxes?.data?.map((box: any, index: number) => {
                if (box.label === stock.inventory_label)
                  return (
                    <div
                      key={box.label + index.toString() + "vkn-out"}
                      className="flex gap-3 items-center justify-end"
                    >
                      {Math.abs(
                        Number(box.selling_price_netto) *
                          Number(stock.inventory_stock)
                      ).toLocaleString("de", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) + " € "}
                      <span className="text-sm text-slate-500">
                        (
                        {Number(box.selling_price_netto).toLocaleString("de", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) + " €/Stk."}
                        )
                      </span>
                    </div>
                  );
                return (
                  <span key={box.label + index.toString() + "vkn-out"}></span>
                );
              })}
            </td>
            <td>
              {boxes?.data?.map((box: any, index: number) => {
                if (box.label === stock.inventory_label)
                  return (
                    <div
                      key={box.label + index.toString() + "vkb-out"}
                      className="flex gap-3 items-center justify-end"
                    >
                      {Math.abs(
                        Number(box.selling_price_brutto) *
                          Number(stock.inventory_stock)
                      ).toLocaleString("de", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) + " € "}
                      <span className="text-sm text-slate-500">
                        (
                        {Number(box.selling_price_brutto).toLocaleString("de", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) + " €/Stk."}
                        )
                      </span>
                    </div>
                  );
                return (
                  <span key={box.label + index.toString() + "vkb-out"}></span>
                );
              })}
            </td>
            <td className="text-right">
              {boxes?.data?.map((box: any, index: number) => {
                if (box.label === stock.inventory_label)
                  return (
                    <span key={box.label + index.toString() + "ust-out"}>
                      {Number(box.tax).toLocaleString("de") + " %"}
                    </span>
                  );
                return (
                  <span key={box.label + index.toString() + "ust-out"}></span>
                );
              })}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default OutgoingBoxesTable;
