import { useContext } from 'react';
import { useMutation } from 'react-query';
import { supabase } from '../utils/client';
import { UserContext } from '../App';
import moment from 'moment';

const useAddInventoryMinimumStock = (inventory) => {
	const { acId } = useContext(UserContext);

	return useMutation(
		['inventory_minimum_stock', acId],
		async () =>
			await supabase
				.from('inventory_minimum_stock')
				.upsert(
					{
						id: inventory.inventory_minimum_stock[0].id ? inventory.inventory_minimum_stock[0].id : null,
						fk_ac_customer: acId,
						fk_inventory: Number(inventory?.inventory_minimum_stock[0]?.fk_inventory),
						minimum_stock: Number(inventory.inventory_minimum_stock[0].minimum_stock) || null,
						updated_at: moment().toISOString(),
					},
					{ onConflict: 'id' }
				)
				.then(handleSupabaseError)
				.then(({ data }) => data)
	);
};

export default useAddInventoryMinimumStock;

const handleSupabaseError = ({ error, ...rest }) => {
	if (error) {
		throw error;
	}
	return rest;
};
