import { useEffect } from "react";
import useMostUsedCarriers from "../../hooks/useMostUsedCarriers";

const MostUsedCarriersTable = ({
  search,
  dateFrom,
  dateTo,
  setSearchCarriers,
}: any) => {
  const mostUsedCarriers = useMostUsedCarriers(search, dateFrom, dateTo);

  useEffect(() => {
    if (mostUsedCarriers.data) setSearchCarriers(mostUsedCarriers.data);
  }, [mostUsedCarriers.data]);
  return (
    <table className="ac-table">
      <thead>
        <tr>
          <th>Spedition</th>
          <th className="!text-right">Lieferungen</th>
        </tr>
      </thead>
      <tbody>
        {mostUsedCarriers.data?.map((carrier: any) => {
          if (carrier.deliveries > 0)
            return (
              <tr key={carrier.label}>
                <td>{carrier.label || "unbekannt"}</td>
                <td className="text-right">
                  {carrier.deliveries.toLocaleString("de-DE")}
                </td>
              </tr>
            );
        })}
      </tbody>
    </table>
  );
};

export default MostUsedCarriersTable;
