import { useMutation } from 'react-query';
import { supabase } from '../utils/client';

const useUpsertEmployee = () => {
	return useMutation(['employees'], AddEmployee);
};

export default useUpsertEmployee;

const AddEmployee = async (employee: any) => {
	return await supabase
		.from('employees')
		.upsert(employee, { onConflict: 'id' })
		.then(handleSupabaseError)
		.then(({ data }) => data);
};

const handleSupabaseError = ({ error, ...rest }: any) => {
	if (error) {
		throw error;
	}
	return rest;
};

// .upsert([
// 	{
// 		id: stock.id,
// 		fk_ac_customer: stock.acId,
// 		fk_delivery_note: stock.delivery_note_id,
// 		created_by_user: stock.created_by_user,
// 		updated_by_user: stock.updated_by_user,
// 		updated_at: moment().toISOString(),
// 		label: stock.label,
// 		category: stock.category,
// 		type: stock.type,
// 		amount: Number(stock.amount),
// 		unit: stock.unit,
// 		booking_date: stock.booking_date,
// 		booking_description: stock.booking_description,
// 		deliverer: stock.deliverer,
// 		delivery_note_no: stock.delivery_note_no,
// 	},
// ])
