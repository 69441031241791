import { useContext } from 'react';
import { useQuery } from 'react-query';
import { UserContext } from '../App';
import { supabase } from '../utils/client';

const useEmployeeByEmployeeId = (employeeId) => {
	const { acId } = useContext(UserContext);
	return useQuery(
		['employee', employeeId],
		async () =>
			await supabase
				.from('employees')
				.select()
				.eq('employee_id', employeeId)
				.eq('fk_ac_customer', acId)
				.then(handleSupabaseError)
				.then(({ data }) => data),
		{
			enabled: !!employeeId,
			refetchOnWindowFocus: false,
		}
	);
};

export default useEmployeeByEmployeeId;

const handleSupabaseError = ({ error, ...rest }) => {
	if (error) {
		throw error;
	}
	return rest;
};
