import { FC } from 'react';

interface ClassicInput {
	placeholder?: string;
	id: string;
	type?: string;
	style?: string;
	rules?: any;
	dataList?: any[];
	listname?: string;
	register: any;
	autofocus?: boolean;
	onFocus?: Function;
	errors?: any;
	onChange?: any;
	onKeyUp?: any;
}

const ClassicInput: FC<ClassicInput> = ({
	placeholder,
	id,
	type,
	style,
	rules,
	dataList,
	listname,
	register,
	autofocus,
	onFocus,
	errors,
	onChange,
	onKeyUp,
}) => {
	return (
		<fieldset className={`relative classic-input ${style || ''}`} title={errors?.message ? errors.message : ''}>
			<span className={dataList && 'datalist-arrow'}>
				<input
					{...register(id, rules)}
					list={listname || ''}
					className={`${errors?.message && '!text-red-600 !bg-red-100'}  ${dataList && 'hide-indicator'}`}
					type={type || 'text'}
					name={id}
					id={id}
					placeholder={placeholder}
					autoComplete='off'
					autoFocus={autofocus || false}
					onFocusCapture={onFocus}
					onKeyUp={onKeyUp}
				/>
			</span>
			{dataList && (
				<datalist id={listname}>
					{dataList.map((item, index) => (
						<option key={item.key + '-' + index} value={item.label}>
							{item.label}
						</option>
					))}
				</datalist>
			)}
		</fieldset>
	);
};

export default ClassicInput;
