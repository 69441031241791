import { useContext } from 'react';
import { useMutation } from 'react-query';
import { supabase } from '../utils/client';
import { UserContext } from '../App';
import moment from 'moment';

const useAddPallet = (pallet) => {
	const { acId } = useContext(UserContext);

	return useMutation(
		['palletes'],
		async () =>
			await supabase
				.from('inventories')
				.upsert([
					{
						id: pallet.id,
						fk_ac_customer: acId,
						label: pallet.label,
						description: pallet.description,
						article_no: pallet.article_no,
						category: 'Paletten',
						default_unit: pallet.default_unit,
						purchasing_price_netto: pallet.purchasing_price_netto,
						purchasing_price_brutto: pallet.purchasing_price_brutto,
						selling_price_netto: pallet.selling_price_netto,
						selling_price_brutto: pallet.selling_price_brutto,
						tax: pallet.tax,
						updated_at: moment().toISOString(),
					},
				])
				.single()
				.then(handleSupabaseError)
				.then(({ data }) => data)
	);
};

export default useAddPallet;

const handleSupabaseError = ({ error, ...rest }) => {
	if (error) {
		throw error;
	}
	return rest;
};
