import { CloudUploadIcon, PencilAltIcon, UserAddIcon } from '@heroicons/react/outline';
import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
//import ActiveEmployees from '../components/ActiveEmployees';
import AdminNav from '../components/AdminNav';
import useEmployees from '../hooks/useEmployees';
import AdminLayout from '../layouts/AdminLayout';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import papa from 'papaparse';
import { UserContext } from '../App';
import useImportEmployee from '../hooks/useImportEmployee';

const Employees = () => {
	const { acId } = useContext(UserContext);
	const {
		register,
		handleSubmit,
		reset,
		control,
		setValue,
		watch,
		getValues,
		formState: { errors },
	} = useForm();

	const { data: employees, isLoading: employeesIsLoading, refetch: refetchEmployees } = useEmployees();
	const importEmployee = useImportEmployee();

	useEffect(() => {
		if (importEmployee.isSuccess) refetchEmployees();
	}, [importEmployee.isSuccess]);

	if (employeesIsLoading)
		return (
			<>
				<AdminNav />
				<div className='container py-8'>
					<h1>Daten werden geladen!</h1>
				</div>
			</>
		);

	if (!employees)
		return (
			<AdminLayout>
				<h1>Keine Mitarbeiter gefunden</h1>
			</AdminLayout>
		);

	const onSubmit = async (data: any) => {
		console.log('data', data.file[0]);
		papa.parse(data.file[0], {
			header: false,
			delimiter: ';',
			encoding: 'ISO-8859-1',
			skipEmptyLines: 'greedy',
			fastMode: true,
			complete: (result) => {
				const employees: any = [];
				let i = 0;
				result.data.map((row: any) => {
					if (i > 0) {
						const employee = {
							fk_ac_customer: acId,
							employee_id: row[0],
							firstname: row[1],
							lastname: row[2],
							address: {
								street: row[4].substr(-3) === 'Nr.' ? row[4].substr(0, row[4].length - 3).trim() : row[4].trim(),
								house_number: row[5],
								zip: row[6],
								city: row[7],
								country: row[3],
							},
							birthday: moment(row[8]).isValid() ? moment(row[8]).format('YYYY-MM-DD') : null,
							gender: row[10].toString().toLowerCase(),
							salutation: row[10] === 'M' ? 'mr' : row[10] === 'F' ? 'mrs' : null,
							nationality: row[11],
							social_security_number: row[16],
							maiden_name: row[17],
							birth_city: row[18],
							birth_country: row[19],
						};
						employees.push(employee);
					}
					i++;
				});
				importEmployee.mutate(employees);
			},
		});
	};

	return (
		<AdminLayout>
			<div className='relative flex gap-8 justify-end pt-3 pb-8 items-center'>
				<form
					onChange={handleSubmit(onSubmit)}
					encType='multipart/form-data'
					acceptCharset='ISO-8859-1'
					className='flex gap-4 items-center'
				>
					<div>
						<input type='file' id='file' {...register('file')} className='hidden' />
						<label htmlFor='file' className='btn btn-light flex items-center gap-2 cursor-pointer'>
							<CloudUploadIcon height={20} width={20} /> Riwo Import
						</label>
					</div>
				</form>
				<Link to={'/employee'}>
					<button className='btn btn-primary flex items-center gap-2'>
						<UserAddIcon height={20} width={20} />
						Neu anlegen
					</button>
				</Link>
			</div>
			<table className='ac-table'>
				<thead>
					<tr>
						<th>Mitarbeiter-Nr.</th>
						<th>Nachname</th>
						<th>Vorname</th>
						<th>Straße, Nr.</th>
						<th>PLZ</th>
						<th>Stadt</th>
						<th>Land</th>
						<th>Geburtsdatum</th>
						<th>RFID</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{employees.map((employee: any) => (
						<tr key={employee.id}>
							<td>{employee.employee_id}</td>
							<td>{employee.lastname}</td>
							<td>{employee.firstname}</td>
							<td>
								{employee.address.street}
								{employee.address.street && employee.address.house_number ? ', ' : ''}
								{employee.address.house_number}
							</td>
							<td>{employee.address.zip}</td>
							<td>{employee.address.city}</td>
							<td>{employee.address.country}</td>
							<td>{employee.birthday ? moment(employee.birthday).format('DD.MM.YYYY') : ''}</td>
							<td>{employee.rfid}</td>
							<td>
								<Link to={`/employee/${employee.employee_id}`}>
									<PencilAltIcon width={20} />
								</Link>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</AdminLayout>
	);
};

export default Employees;
