import { tab } from '@testing-library/user-event/dist/tab';
import React, { FC } from 'react';

const Tabs: FC<any> = ({ tabs, page, setPage }) => {
	return (
		<>
			<nav className='border-b border-gray-200 flex justify-between items-center'>
				<ul className='flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500'>
					{tabs.map((tab: any, index: number) => (
						<li key={tab.label + index.toString()} className='mr-2 cursor-pointer'>
							<a
								onClick={() => setPage(index)}
								className={`inline-flex p-4 rounded-t-lg border-b-2 group ${
									page === index ? ' text-slate-600 border-slate-600  ' : ' hover:text-gray-600 hover:border-gray-300 '
								}`}
							>
								{/* <svg
									className={`mr-2 w-5 h-5  ${
										page === index ? ' text-slate-600 ' : ' text-gray-400 group-hover:text-gray-500 '
									}`}
									fill='currentColor'
									viewBox='0 0 20 20'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path d='M9 2a1 1 0 000 2h2a1 1 0 100-2H9z'></path>
									<path
										fillRule='evenodd'
										d='M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z'
										clipRule='evenodd'
									></path>
								</svg> */}
								{tab.icon}
								{tab.label}
							</a>
						</li>
					))}
				</ul>
			</nav>
			{tabs.map((tab: any, index: number) => {
				return (
					page === index && (
						<div className='pt-12' key={tab.label + '-page-' + index}>
							{tab.pageComponent}
						</div>
					)
				);
			})}
		</>
	);
};

export default Tabs;
