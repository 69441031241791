import { FC } from 'react';

interface Input {
	label: string;
	placeholder?: string;
	id: string;
	type?: string;
	style?: string;
	dataList?: any[];
	listname?: string;
	autofocus?: boolean;
	errors?: any;
	readonly?: boolean;
	disabled?: boolean;
	defaultValue?: string;
	onFocus?: any;
	onKeyUp?: any;
	onChange?: any;
	value?: string;
	isFilled?: boolean;
}

const StyledInput: FC<Input> = ({
	label,
	placeholder,
	id,
	type,
	style,
	dataList,
	listname,
	autofocus,
	readonly,
	disabled,
	defaultValue,
	onFocus,
	onKeyUp,
	onChange,
	value,
	isFilled,
}) => {
	return (
		<fieldset className={`relative standard-input ${style || ''}`}>
			<span className={dataList && 'datalist-arrow'}>
				<input
					readOnly={readonly}
					disabled={disabled}
					list={listname || ''}
					defaultValue={defaultValue}
					className={`mb-0 peer  ${dataList && 'hide-indicator'}`}
					type={type || 'text'}
					name={id}
					id={id}
					placeholder={placeholder || label}
					autoComplete='off'
					autoFocus={autofocus || false}
					onFocus={onFocus || null}
					onKeyUp={onKeyUp || null}
					onChange={onChange || null}
					value={value}
				/>
				<label className={`'!text-red-600 relative'} standard-input-label`} htmlFor={id}>
					{label} {isFilled && <span className='absolute block h-2 w-2 rounded-full right-0 top-0 bg-red-500'></span>}
				</label>
			</span>
			{dataList && (
				<datalist id={listname}>
					{dataList.map((item, index) => (
						<option key={item.key + '-' + index} value={item.label}>
							{item.label}
						</option>
					))}
				</datalist>
			)}
		</fieldset>
	);
};

export default StyledInput;
