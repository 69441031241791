import { FC, useState } from 'react';
import Inventory from '../components/Inventory';
import Receipts from '../components/Receipts';
import AdminLayout from '../layouts/AdminLayout';
import Statistics from '../components/Statistics';
import ReceiptsTrash from '../components/ReceiptsTrash';

const MerchandiseManagement: FC = () => {
	const [tab, setTab] = useState<number>(0);

	return (
		<AdminLayout>
			<nav className='border-b border-gray-200'>
				<ul className='flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500'>
					<li className='mr-2 cursor-pointer'>
						<a
							onClick={() => setTab(0)}
							className={`inline-flex p-4 rounded-t-lg border-b-2 group ${
								tab === 0 ? ' text-slate-600 border-slate-600 ' : ' hover:text-gray-600 hover:border-gray-300 '
							}`}
						>
							<svg
								className={`mr-2 w-5 h-5 text-gray-400 group-hover:text-gray-500 
								}`}
								fill='currentColor'
								viewBox='0 0 20 20'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path d='M9 2a1 1 0 000 2h2a1 1 0 100-2H9z'></path>
								<path
									fillRule='evenodd'
									d='M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z'
									clipRule='evenodd'
								></path>
							</svg>
							Lieferscheine
						</a>
					</li>
					<li className='mr-2 cursor-pointer'>
						<a
							onClick={() => setTab(1)}
							className={`inline-flex p-4 rounded-t-lg border-b-2 group ${
								tab === 1 ? ' text-slate-600 border-slate-600 ' : ' hover:text-gray-600 hover:border-gray-300 '
							}`}
						>
							<svg
								className={`mr-2 w-5 h-5 text-gray-400 group-hover:text-gray-500
									}`}
								fill='currentColor'
								viewBox='0 0 20 20'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									fillRule='evenodd'
									d='M5 3a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V5a2 2 0 00-2-2H5zm9 4a1 1 0 10-2 0v6a1 1 0 102 0V7zm-3 2a1 1 0 10-2 0v4a1 1 0 102 0V9zm-3 3a1 1 0 10-2 0v1a1 1 0 102 0v-1z'
									clipRule='evenodd'
								/>
							</svg>
							Bestände
						</a>
					</li>
					<li className='mr-2 cursor-pointer'>
						<a
							onClick={() => setTab(2)}
							className={`inline-flex p-4 rounded-t-lg border-b-2 group ${
								tab === 2 ? ' text-slate-600 border-slate-600 ' : ' hover:text-gray-600 hover:border-gray-300 '
							}`}
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								className={`mr-2 w-5 h-5 text-gray-400 group-hover:text-gray-500
							}`}
								fill='currentColor'
								viewBox='0 0 24 24'
								stroke='currentColor'
								strokeWidth={2}
							>
								<path strokeLinecap='round' strokeLinejoin='round' d='M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z' />
								<path strokeLinecap='round' strokeLinejoin='round' d='M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z' />
							</svg>
							Statistik
						</a>
					</li>
				</ul>
			</nav>
			{tab === 0 && <Receipts setTab={setTab} />}
			{tab === 1 && <Inventory />}
			{tab === 2 && <Statistics />}
			{tab === 3 && <ReceiptsTrash />}
		</AdminLayout>
	);
};

export default MerchandiseManagement;
