import { useContext } from 'react';
import { useMutation } from 'react-query';
import { supabase } from '../utils/client';
import { UserContext } from '../App';

const useDeleteStock = (id) => {
	const { acId } = useContext(UserContext);

	return useMutation(
		['stocks'],
		async () =>
			await supabase
				.from('stock')
				.delete()
				.match({ id: id, fk_ac_customer: acId })
				.then(handleSupabaseError)
				.then(({ data }) => data)
	);
};

export default useDeleteStock;

const handleSupabaseError = ({ error, ...rest }) => {
	if (error) {
		throw error;
	}
	return rest;
};
