import { useContext } from 'react';
import { useQuery } from 'react-query';
import { UserContext } from '../App';
import { supabase } from '../utils/client';

const useEmployeeByRfid = (rfid: string) => {
	const { acId } = useContext(UserContext);
	return useQuery(
		['employee', rfid],
		async () =>
			await supabase
				.from('employees')
				.select()
				.eq('rfid', rfid)
				.eq('fk_ac_customer', acId)
				.limit(1)
				.single()
				.then(handleSupabaseError)
				.then(({ data }) => data),
		{ enabled: !!rfid, refetchOnWindowFocus: false }
	);
};

export default useEmployeeByRfid;

const handleSupabaseError = ({ error, ...rest }: any) => {
	if (error) {
		throw error;
	}
	return rest;
};
