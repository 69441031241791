import { useContext } from 'react';
import { useQuery } from 'react-query';
import { UserContext } from '../App';
import { supabase } from '../utils/client';

const useDeliveryNotes = (
	page = 0,
	rowsPerPage = 10,
	search: string | undefined,
	order: string | undefined,
	ascending: boolean = false,
	dateFrom: string,
	dateTo: string,
	isDeleted: boolean = false
) => {
	const { acId } = useContext(UserContext);
	const from = page * rowsPerPage;
	const to = page * rowsPerPage + rowsPerPage - 1;
	let notesId = BigInt(0);
	if (search && !isNaN(Number(search))) notesId = BigInt(search);

	return useQuery(
		['deliveryNotes', page, rowsPerPage, search, order, ascending, dateFrom, isDeleted, dateTo, acId],
		async () => {
			let query = supabase
				.from('delivery_notes')
				.select(
					`   id,
							inserted_at,
							created_by_user,
							delivery_notes_no,
							company_short,
							producer,
							deliverer_no,
							ggn_no,
							delivery_notes_date,
							signature_employee,
							signature_carrier,
							producer,
							deliverer_no,
							ggn_no,
							signature_employee,
							signature_carrier,
							destination,
							consignment_number,
							delivery_notes_items,
							delivery_notes_pallets,
							delivery_notes_delivered_boxes,
							delivery_notes_returned_boxes,
							carrier,
							is_canceled,
							is_deleted
				`,
					{ count: 'exact' }
				)
				.eq('fk_ac_customer', acId)
				.eq('is_deleted', isDeleted)
				.range(from, to)
				.gte('delivery_notes_date', dateFrom)
				.lte('delivery_notes_date', dateTo);

			if (search) {
				query = query.or(
					`delivery_notes_no.eq.${notesId},destination.ilike.%${search}%,consignment_number.ilike.%${search}%,carrier.ilike.%${search}%`
				);
			}
			if (order) {
				query = query.order(order, { ascending: ascending });
			}

			const queryRes = await query;
			const response = await handleSupabaseError(queryRes);
			return response;
		},
		{ enabled: !!acId && !!dateFrom && !!dateTo, refetchOnWindowFocus: true }
	);
};

export default useDeliveryNotes;

const handleSupabaseError = ({ error, ...rest }: any) => {
	if (error) {
		throw error;
	}
	return rest;
};
