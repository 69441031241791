import { useContext } from 'react';
import { useMutation } from 'react-query';
import { supabase } from '../utils/client';
import { UserContext } from '../App';
import moment from 'moment';

const useUpdateDeliveryNote = (props = { id: undefined, user: undefined, is_canceled: false, is_deleted: false }) => {
	const { id, user, is_canceled, is_deleted } = props;
	const { acId } = useContext(UserContext);

	return useMutation(
		['delivery_notes', id, acId],
		async () =>
			await supabase
				.from('delivery_notes')
				.update({
					updated_at: moment().toISOString(),
					updated_by_user: JSON.stringify({ email: user.email }),
					is_deleted: is_deleted || false,
					is_canceled: is_canceled || false,
				})
				.match({ id: id, fk_ac_customer: acId })
				.then(handleSupabaseError)
				.then(({ data }) => data),
		{
			enabled: !!id && !!user,
		}
	);
};

export default useUpdateDeliveryNote;

const handleSupabaseError = ({ error, ...rest }) => {
	if (error) {
		throw error;
	}
	return rest;
};
