import { Routes, Route } from "react-router-dom";
import Home from "./routes/Tracker";
import Employees from "./routes/Employees";
import Employee from "./routes/Employee";
import Admin from "./routes/Admin";
import TimeTracking from "./routes/TimeTracking";
import MerchandiseManagement from "./routes/MerchandiseManagement";
import Settings from "./routes/Settings";
import Bonus from "./routes/Bonus";
import Documents from "./routes/Documents";
import Forms from "./routes/Forms";
import ResourcePlanning from "./routes/ResourcePlanning";
import FieldIndex from "./routes/FieldIndex";
import Login from "./routes/Login";
import { useAuth } from "./provider/AuthProvider";
import useUser from "./hooks/useUser";
import { useState, useEffect, createContext } from "react";
import DeliverNote from "./routes/DeliverNote";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";
import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental";
import { supabase } from "./utils/client";
import "react-toastify/dist/ReactToastify.min.css";
import { toast, ToastContainer } from "react-toastify";
import ConfirmSignUp from "./routes/ConfirmSignUp";
import Tracker from "./routes/Tracker";
import moment from "moment";
import Docs from "./routes/Docs";

export const MerchandiseContext = createContext<any>(undefined);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
    },
  },
});

const localStoragePersistor = createWebStoragePersistor({
  storage: window.localStorage,
});

persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
});
export const UserContext = createContext<any>(undefined);
export const ToastContext = createContext<any>(undefined);
export const ExperimentalContext = createContext<any>(undefined);

function App() {
  const { user } = useAuth();
  const [userId, setUserId] = useState<string | undefined>(undefined);
  const [userRole, setUserRole] = useState<string | undefined>(undefined);
  const [acId, setAcId] = useState<string | undefined>(undefined);
  const [acUser, setAcUser] = useState<any>();
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [experimental, setExperimental] = useState<boolean>(false);

  const [receiptsPage, setReceiptsPage] = useState<number>(0);
  const [stocksPage, setStocksPage] = useState<number>(0);
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [order, setOrder] = useState<string | undefined>("booking_date");
  const [ascending, setAscending] = useState<boolean | undefined>(undefined);
  const [receiptSearch, setReceiptSearch] = useState<string | undefined>(
    undefined
  );
  const [receiptOrder, setReceiptOrder] = useState<string | undefined>(
    "delivery_notes_no"
  );
  const [receiptAscending, setReceiptAscending] = useState<boolean | undefined>(
    undefined
  );
  const [dateFrom, setDateFrom] = useState<string>("2022-01-01");
  const [dateTo, setDateTo] = useState<string>(
    moment().format("YYYY") + "-12-31"
  );
  const [receiptDateFrom, setReceiptDateFrom] = useState<string>("2022-01-01");
  const [receiptDateTo, setReceiptDateTo] = useState<string>(
    moment().format("YYYY") + "-12-31"
  );
  const [receiptSearchInput, setReceiptSearchInput] = useState<string | null>(
    null
  );
  const [searchInput, setSearchInput] = useState<string | null>(null);
  const [incoming, setIncoming] = useState<boolean>(false);
  const [outgoing, setOutgoing] = useState<boolean>(false);

  // GET ACKERCLOUD USER START
  useEffect(() => {
    if (userId) getAcUser();
  }, [userId]);

  const getAcUser = async () => {
    await supabase
      .from("ac_users")
      .select()
      .eq("id", userId)
      .then(handleSupabaseError)
      .then(({ data }) => setAcUser(data));
  };
  const handleSupabaseError = ({ error, ...rest }: any) => {
    if (error) {
      throw error;
    }
    return rest;
  };
  useEffect(() => {
    setUserId(undefined);
    if (user && user.id) setUserId(user.id);
  }, [user]);

  useEffect(() => {
    if (acUser) {
      setUserRole(acUser[0].user_role);
      setAcId(acUser[0].fk_ac_customer);
      setFirstname(acUser[0].firstname);
      setLastname(acUser[0].lastname);
    }
  }, [acUser]);
  // GET ACKERCLOUD USER END

  if (user && user.aud === "authenticated" && acId) {
    return (
      <ExperimentalContext.Provider value={{ experimental, setExperimental }}>
        <MerchandiseContext.Provider
          value={{
            receiptsPage,
            setReceiptsPage,
            stocksPage,
            setStocksPage,
            search,
            setSearch,
            order,
            setOrder,
            ascending,
            setAscending,
            receiptAscending,
            setReceiptAscending,
            receiptSearch,
            setReceiptSearch,
            receiptOrder,
            setReceiptOrder,
            dateFrom,
            setDateFrom,
            dateTo,
            setDateTo,
            receiptDateFrom,
            setReceiptDateFrom,
            receiptDateTo,
            setReceiptDateTo,
            receiptSearchInput,
            setReceiptSearchInput,
            searchInput,
            setSearchInput,
            incoming,
            setIncoming,
            outgoing,
            setOutgoing,
          }}
        >
          <ToastContext.Provider value={{ toast }}>
            <UserContext.Provider
              value={{
                userRole,
                acId,
                setAcId,
                firstname,
                setFirstname,
                lastname,
                setLastname,
                setUserRole,
              }}
            >
              <QueryClientProvider client={queryClient}>
                <Routes>
                  <Route path="/" element={<MerchandiseManagement />} />
                  <Route
                    path="/merchandise-management"
                    element={<MerchandiseManagement />}
                  />
                  <Route path="/delivery-note" element={<DeliverNote />} />
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/documents" element={<Documents />} />
                  <Route path="/forms" element={<Forms />} />
                  <Route path="/employees" element={<Employees />} />
                  <Route path="/employee/:employeeId" element={<Employee />} />
                  <Route path="/employee" element={<Employee />} />
                  <Route path="/timetracking" element={<TimeTracking />} />
                  <Route path="/bonus" element={<Bonus />} />
                  <Route path="/tracker" element={<Tracker />} />
                  <Route path="/docs" element={<Docs />} />
                  {/* <Route path='/admin' element={<Admin />} /> */}
                  {/* <Route path='/resource-planning' element={<ResourcePlanning />} /> */}
                  {/* <Route path='/field-index' element={<FieldIndex />} /> */}
                </Routes>

                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </UserContext.Provider>
            <ToastContainer />
          </ToastContext.Provider>
        </MerchandiseContext.Provider>
      </ExperimentalContext.Provider>
    );
  }
  return (
    <MerchandiseContext.Provider value={{}}>
      <ToastContext.Provider value={{ toast }}>
        <UserContext.Provider value={{ userRole, acId }}>
          <QueryClientProvider client={queryClient}>
            <Routes>
              <Route path="/confirm" element={<ConfirmSignUp />} />
              <Route path="*" element={<Login />} />
            </Routes>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </UserContext.Provider>
        <ToastContainer />
      </ToastContext.Provider>
    </MerchandiseContext.Provider>
  );
}

export default App;
