import { FC, useState, useEffect, useLayoutEffect, useContext } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import ClassicInput from '../components/form/ClassicInput';
import Input from '../components/form/Input';
import useDestinations from '../hooks/useDestinations';
import useProducts from '../hooks/useProducts';
import AdminLayout from '../layouts/AdminLayout';
import moment from 'moment';
import Signature from '../components/Signature';
import { Beforeunload } from 'react-beforeunload';
import { ExperimentalContext, ToastContext, UserContext } from '../App';
import useBoxes from '../hooks/useBoxes';
import useSystemData from '../hooks/useSystemData';
import Checkbox from '../components/form/Checkbox';
import SelectClassic from '../components/form/SelectClassic';
import { supabase } from '../utils/client';
import useAddStock from '../hooks/useAddStock';
import useAddDeliveryNote from '../hooks/useAddDeliveryNote';
import Modal from 'react-modal';
import { useAuth } from '../provider/AuthProvider';
import useDeleteStockByDeliveryNoteId from '../hooks/useDeleteStockByDeliveryNoteId';
import useDeliveryNoteByNo from '../hooks/useDeliveryNoteByNo';
import Select from '../components/form/Select';
import { DocumentAddIcon, TrashIcon } from '@heroicons/react/outline';
import useUploadFile from '../hooks/useUploadFile';
import useAttachedFiles from '../hooks/useAttachedFiles';
import useCarriers from '../hooks/useCarriers';
import usePallets from '../hooks/usePallets';
import Admin from './Admin';

const DeliverNote: FC = () => {
  const { experimental } = useContext(ExperimentalContext);
  const { user } = useAuth();
  const { acId } = useContext(UserContext);
  const { toast } = useContext(ToastContext);
  const [signatureImage, setSignatureImage] = useState<string | undefined>(undefined);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [deliveryNoteId, setDeliveryNoteId] = useState<number | undefined>(undefined);
  const [deliveryNoteNo, setDeliveryNoteNo] = useState<any>();
  const deliverNote = useDeliveryNoteByNo(deliveryNoteNo);
  const [stateUser, setStateUser] = useState<any>();
  Modal.setAppElement('#root');
  const boxes = useBoxes();
  const pallets = usePallets();
  const products = useProducts();
  const destinations = useDestinations();
  const carriers = useCarriers();
  const systemData = useSystemData();
  const [files, setFiles] = useState<string | undefined>();
  const [subFolder, setSubFolder] = useState<string | undefined>(undefined);
  const uploadFile = useUploadFile();
  const attachedFiles = useAttachedFiles(subFolder);

  const { state }: any = useLocation();

  let navigate = useNavigate();
  const deleteStocksByDeliveryNoteId = useDeleteStockByDeliveryNoteId(deliveryNoteId);
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: undefined,
      delivery_notes_date: moment().format('YYYY-MM-DD'),
      file: undefined,
      signature_carrier: undefined,
      created_by_user: JSON.stringify({ email: user.email }),
      updated_by_user: JSON.stringify({ email: user.email }),
      delivery_notes_no: '',
      company_short: '',
      producer: '',
      deliverer_no: '',
      carrier: '',
      ggn_no: '',
      destination: '',
      consignment_number: '',
      signature_employee: undefined,
      items: [
        {
          box_label: '',
          box_quantity: '',
          pallet_label: '',
          pallets_quantity: '',
          product_label: '',
          product_size: '',
          content_amount: '',
          unit: 'pc',
          origin_country: 'DE',
          province: '',
          qs: true,
        },
      ],
      delivered_boxes: [
        { label: '', quantity: undefined },
        { label: '', quantity: undefined },
        { label: '', quantity: undefined },
      ],
      returned_boxes: [
        { label: '', quantity: undefined },
        { label: '', quantity: undefined },
        { label: '', quantity: undefined },
      ],
      empty_pallets: {
        delivered_dus_pallets: undefined,
        delivered_dus2_pallets: undefined,
        delivered_eur_pallets: undefined,
        returned_dus_pallets: undefined,
        returned_dus2_pallets: undefined,
        returned_eur_pallets: undefined,
      },
    },
  });
  const { fields, append } = useFieldArray({
    control,
    name: 'items',
  });
  const { fields: delivered_boxes_fields, append: append_delivered_box_fields } = useFieldArray({
    control,
    name: 'delivered_boxes',
  });
  const { fields: returned_boxes_fields, append: append_returned_box_fields } = useFieldArray({
    control,
    name: 'returned_boxes',
  });

  useEffect(() => {
    const inputFiles = watch('file');
    let files = '';
    if (inputFiles) {
      Object.entries(inputFiles).forEach((file: any) => {
        files += file[1].name + ', ';
      });
    }
    setFiles(files);
  }, [watch('file')]);
  const watching = watch();

  const checkDelivered = async () => {
    const boxes: any = {};

    let dus = 0;
    let dus2 = 0;
    let eur = 0;
    await watching?.items?.forEach((item: any, index: number) => {
      if (boxes.hasOwnProperty(item.box_label)) {
        boxes[item.box_label] = Number(item.box_quantity) + Number(boxes[item.box_label]);
      } else {
        boxes[item.box_label] = Number(item.box_quantity);
      }
      if (item.pallet_label === 'Europaletten') eur = eur + Number(item.pallets_quantity);
      if (item.pallet_label === 'Düsseldorfer Paletten') dus = dus + Number(item.pallets_quantity);
      if (item.pallet_label === 'KDP') dus2 = dus2 + Number(item.pallets_quantity);
    });
    setValue(`delivered_boxes.${0}.label`, null);
    setValue(`delivered_boxes.${0}.quantity`, null);
    setValue(`delivered_boxes.${1}.label`, null);
    setValue(`delivered_boxes.${1}.quantity`, null);
    setValue(`delivered_boxes.${2}.label`, null);
    setValue(`delivered_boxes.${2}.quantity`, null);

    let index = 0;
    Object.entries(boxes).forEach((entry) => {
      const [key, value] = entry;
      setValue(`delivered_boxes.${index}.label`, key);
      setValue(`delivered_boxes.${index}.quantity`, value);
      index++;
    });
    setValue('empty_pallets.delivered_dus_pallets', dus);
    setValue('empty_pallets.delivered_dus2_pallets', dus2);
    setValue('empty_pallets.delivered_eur_pallets', eur);
  };

  const setSignature = (img: string) => {
    setValue('signature_carrier', img);
    setSignatureImage(img);
    setModalIsOpen(false);
  };

  useEffect(() => {
    if (state) {
      if (state.delivery_note_no_state) {
        setSubFolder('Lieferscheine/' + state.delivery_note_no_state);
        setDeliveryNoteNo(state.delivery_note_no_state);
      } else {
        setSubFolder('Lieferscheine/' + state.delivery_notes_no);
        setDeliveryNoteId(state.id);
        setSignatureImage(state.signature_carrier);
        setStateUser(state?.created_by_user);
        reset({
          id: state.id,
          created_by_user: state.created_by_user,
          updated_by_user: user.email,
          delivery_notes_no: state.delivery_notes_no,
          company_short: state.company_short,
          producer: state.producer,
          deliverer_no: state.deliverer_no,
          carrier: state.carrier,
          ggn_no: state.ggn_no,
          delivery_notes_date: state.delivery_notes_date,
          signature_employee: state.signature_employee,
          signature_carrier: state.signature_carrier,
          empty_pallets: JSON.parse(state.delivery_notes_pallets),
          destination: state.destination,
          consignment_number: state.consignment_number,
          items: state?.delivery_notes_items?.map((item: any) => {
            return JSON.parse(item);
          }),
          delivered_boxes: state?.delivery_notes_delivered_boxes?.map((item: any) => {
            return JSON.parse(item);
          }),
          returned_boxes: state?.delivery_notes_returned_boxes?.map((item: any) => {
            return JSON.parse(item);
          }),
        });
      }
    }
  }, [state]);

  useEffect(() => {
    if (deliverNote?.data) {
      const data = deliverNote.data.body;
      setStateUser(data.created_by_user);
      setDeliveryNoteId(data.id);
      setSignatureImage(data.signature_carrier);
      reset({
        id: data.id,
        created_by_user: data.created_by_user,
        updated_by_user: user.email,
        delivery_notes_no: data.delivery_notes_no,
        company_short: data.company_short,
        producer: data.producer,
        deliverer_no: data.deliverer_no,
        carrier: data.carrier,
        ggn_no: data.ggn_no,
        delivery_notes_date: data.delivery_notes_date,
        signature_employee: data.signature_employee,
        signature_carrier: data.signature_carrier,
        empty_pallets: JSON.parse(data.delivery_notes_pallets),
        destination: data.destination,
        consignment_number: data.consignment_number,
        items: data?.delivery_notes_items?.map((item: any) => {
          return JSON.parse(item);
        }),
        delivered_boxes: data?.delivery_notes_delivered_boxes?.map((item: any) => {
          return JSON.parse(item);
        }),
        returned_boxes: data?.delivery_notes_returned_boxes?.map((item: any) => {
          return JSON.parse(item);
        }),
      });
    }
  }, [deliverNote.isSuccess]);

  useLayoutEffect(() => {
    setValue('delivery_notes_date', moment().format('YYYY-MM-DD'));
    if (delivered_boxes_fields.length < 3)
      append_delivered_box_fields([
        { label: '', quantity: '' },
        { label: '', quantity: '' },
        { label: '', quantity: '' },
      ]);
    if (returned_boxes_fields.length < 3)
      append_returned_box_fields([
        { label: '', quantity: '' },
        { label: '', quantity: '' },
        { label: '', quantity: '' },
      ]);
    if (fields.length < 1)
      append(
        [
          {
            box_label: '',
            box_quantity: '',
            pallet_label: '',
            pallets_quantity: '',
            product_label: '',
            product_size: '',
            content_amount: '',
            unit: 'pc',
            origin_country: 'DE',
            province: '',
            qs: true,
          },
        ],
        { shouldFocus: false }
      );
  }, []);

  const [note, setNote] = useState<any>();

  const addDeliveryNote = useAddDeliveryNote(note);
  const { mutate: addOrUpdateStock, mutateAsync: addOrUpdateStockAsync, isSuccess, isError: stockError } = useAddStock();

  const onSubmit = async (data: any) => {
    if (data && checkForData(data)) {
      setIsSaving(true);
      let newNumber;
      if (!data.delivery_notes_no) {
        let lastNumber = await supabase
          .from('delivery_notes')
          .select('delivery_notes_no')
          .eq('fk_ac_customer', acId)
          .order('delivery_notes_no', { ascending: false })
          .limit(1)
          .single();

        if (!lastNumber?.data) {
          lastNumber = await supabase.from('system_data').select('delivery_notes_no_start').eq('fk_ac_customer', acId).single();
          newNumber = lastNumber.data.delivery_notes_no_start;
        } else {
          newNumber = Number(lastNumber.data.delivery_notes_no) + 1;
        }
      }
      Object.assign(data, {
        id: data?.id,
        created_by_user: stateUser || JSON.stringify({ email: user.email }),
        updated_by_user: JSON.stringify({ email: user.email }),
        company_short: systemData?.data?.company_short,
        deliverer_no: systemData?.data?.deliverer_no,
        producer: systemData?.data?.producer,
        ggn_no: systemData?.data?.ggn_no,
        delivery_notes_no: data?.delivery_notes_no ? data.delivery_notes_no.toString() : newNumber.toString(),
      });
      setNote(data);
    } else {
      setIsSaving(false);
      toast.warn('Das Formular ist leer.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  useEffect(() => {
    if (note) {
      addDeliveryNote.mutate();
    }
  }, [note]);

  useEffect(() => {
    if (addDeliveryNote.isSuccess) {
      const data = addDeliveryNote.data;
      deleteStocks(data);
      //	uploadAttachedFiles(data);
    }
  }, [addDeliveryNote.isSuccess]);

  const uploadAttachedFiles = (data: any) => {
    Object.entries(note.file).forEach((file: any) => {
      const tmp = {
        acId: acId,
        subfolder: 'Lieferscheine/' + data.delivery_notes_no,
        fileName: file[1].name,
        file: file[1],
        contentType: file[1].type,
      };
      uploadFile.mutateAsync(tmp);
    });
  };

  useEffect(() => {
    setIsSaving(false);
  }, [addDeliveryNote.isError]);

  const deleteStocks = async (data: any) => {
    if (stateUser) {
      await deleteStocksByDeliveryNoteId.mutateAsync();
    }
    if (checkForStock(note)) {
      storeStocks(data);
    } else {
      setIsSaving(false);
      toast.success('Lieferschein gespeichert!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      navigate('/merchandise-management');
    }
  };

  const storeStocks = async (data: any) => {
    const stockArr = [];
    await data.delivery_notes_delivered_boxes.map(async (item: any) => {
      if (JSON.parse(item).label || JSON.parse(item).quantity) {
        const newStock: any = {
          fk_ac_customer: acId,
          created_by_user: stateUser || JSON.stringify({ email: user.email }),
          updated_by_user: JSON.stringify({ email: user.email }),
          fk_delivery_note: data.id,
          booking_date: data.delivery_notes_date ? note.delivery_notes_date : moment(data.inserted_at).format('YYYY-MM-DD'),
          label: JSON.parse(item).label,
          category: 'Kisten',
          unit: 'pc',
          amount: Number(JSON.parse(item).quantity),
          booking_description: 'Lieferschein',
          deliverer: data.destination,
          carrier: data.carrier,
          delivery_note_no: data.delivery_notes_no,
          type: 'decrease',
          updated_at: moment().toISOString(),
        };
        stockArr.push(newStock);
        // addOrUpdateStock(newStock);
      }
    });

    await data.delivery_notes_returned_boxes.map(async (item: any) => {
      if (JSON.parse(item).label || JSON.parse(item).quantity) {
        const newStock: any = {
          fk_ac_customer: acId,
          created_by_user: stateUser || JSON.stringify({ email: user.email }),
          updated_by_user: JSON.stringify({ email: user.email }),
          fk_delivery_note: data.id,
          booking_date: data.delivery_notes_date ? data.delivery_notes_date : moment(data.inserted_at).format('YYYY-MM-DD'),
          label: JSON.parse(item).label,
          category: 'Kisten',
          unit: 'pc',
          amount: Number(JSON.parse(item).quantity),
          booking_description: 'Lieferschein',
          deliverer: data.destination,
          carrier: data.carrier,
          delivery_note_no: data.delivery_notes_no,
          type: 'increase',
          updated_at: moment().toISOString(),
        };
        stockArr.push(newStock);
        // addOrUpdateStock(newStock);
      }
    });

    const delivered_dus = {
      fk_ac_customer: acId,
      created_by_user: stateUser || JSON.stringify({ email: user.email }),
      updated_by_user: JSON.stringify({ email: user.email }),
      fk_delivery_note: data.id,
      booking_date: data.delivery_notes_date ? data.delivery_notes_date : moment(data.inserted_at).format('YYYY-MM-DD'),
      label: 'Düsseldorfer Paletten',
      category: 'Paletten',
      unit: 'pc',
      amount: Number(JSON.parse(data.delivery_notes_pallets).delivered_dus_pallets),
      booking_description: 'Lieferschein',
      deliverer: data.destination,
      carrier: data.carrier,
      delivery_note_no: data.delivery_notes_no,
      type: 'decrease',
      updated_at: moment().toISOString(),
    };
    if (JSON.parse(data.delivery_notes_pallets).delivered_dus_pallets) stockArr.push(delivered_dus);
    // if (JSON.parse(data.delivery_notes_pallets).delivered_dus_pallets) addOrUpdateStock(delivered_dus);

    const delivered_dus2 = {
      fk_ac_customer: acId,
      created_by_user: stateUser || JSON.stringify({ email: user.email }),
      updated_by_user: JSON.stringify({ email: user.email }),
      fk_delivery_note: data.id,
      booking_date: data.delivery_notes_date ? data.delivery_notes_date : moment(data.inserted_at).format('YYYY-MM-DD'),
      label: 'KDP',
      category: 'Paletten',
      unit: 'pc',
      amount: Number(JSON.parse(data.delivery_notes_pallets).delivered_dus2_pallets),
      booking_description: 'Lieferschein',
      deliverer: data.destination,
      carrier: data.carrier,
      delivery_note_no: data.delivery_notes_no,
      type: 'decrease',
      updated_at: moment().toISOString(),
    };
    if (JSON.parse(data.delivery_notes_pallets).delivered_dus2_pallets) stockArr.push(delivered_dus2);

    const delivered_eur = {
      fk_ac_customer: acId,
      created_by_user: stateUser || JSON.stringify({ email: user.email }),
      updated_by_user: JSON.stringify({ email: user.email }),
      fk_delivery_note: data.id,
      booking_date: data.delivery_notes_date ? data.delivery_notes_date : moment(data.inserted_at).format('YYYY-MM-DD'),
      label: 'Europaletten',
      category: 'Paletten',
      unit: 'pc',
      amount: Number(JSON.parse(data.delivery_notes_pallets).delivered_eur_pallets),
      booking_description: 'Lieferschein',
      deliverer: data.destination,
      carrier: data.carrier,
      delivery_note_no: data.delivery_notes_no,
      type: 'decrease',
      updated_at: moment().toISOString(),
    };
    if (JSON.parse(data.delivery_notes_pallets).delivered_eur_pallets) stockArr.push(delivered_eur);
    // if (JSON.parse(data.delivery_notes_pallets).delivered_eur_pallets) addOrUpdateStock(delivered_eur);

    const returned_dus = {
      fk_ac_customer: acId,
      created_by_user: stateUser || JSON.stringify({ email: user.email }),
      updated_by_user: JSON.stringify({ email: user.email }),
      fk_delivery_note: data.id,
      booking_date: data.delivery_notes_date ? data.delivery_notes_date : moment(data.inserted_at).format('YYYY-MM-DD'),
      label: 'Düsseldorfer Paletten',
      category: 'Paletten',
      unit: 'pc',
      amount: Number(JSON.parse(data.delivery_notes_pallets).returned_dus_pallets),
      booking_description: 'Lieferschein',
      deliverer: data.destination,
      carrier: data.carrier,
      delivery_note_no: data.delivery_notes_no,
      type: 'increase',
      updated_at: moment().toISOString(),
    };
    if (JSON.parse(data.delivery_notes_pallets).returned_dus_pallets) stockArr.push(returned_dus);
    // if (JSON.parse(data.delivery_notes_pallets).returned_dus_pallets) addOrUpdateStock(returned_dus);

    const returned_dus2 = {
      fk_ac_customer: acId,
      created_by_user: stateUser || JSON.stringify({ email: user.email }),
      updated_by_user: JSON.stringify({ email: user.email }),
      fk_delivery_note: data.id,
      booking_date: data.delivery_notes_date ? data.delivery_notes_date : moment(data.inserted_at).format('YYYY-MM-DD'),
      label: 'KDP',
      category: 'Paletten',
      unit: 'pc',
      amount: Number(JSON.parse(data.delivery_notes_pallets).returned_dus2_pallets),
      booking_description: 'Lieferschein',
      deliverer: data.destination,
      carrier: data.carrier,
      delivery_note_no: data.delivery_notes_no,
      type: 'increase',
      updated_at: moment().toISOString(),
    };
    if (JSON.parse(data.delivery_notes_pallets).returned_dus2_pallets) stockArr.push(returned_dus2);

    const returned_eur = {
      fk_ac_customer: acId,
      created_by_user: stateUser || JSON.stringify({ email: user.email }),
      updated_by_user: JSON.stringify({ email: user.email }),
      fk_delivery_note: data.id,
      booking_date: data.delivery_notes_date ? data.delivery_notes_date : moment(data.inserted_at).format('YYYY-MM-DD'),
      label: 'Europaletten',
      category: 'Paletten',
      unit: 'pc',
      amount: Number(JSON.parse(data.delivery_notes_pallets).returned_eur_pallets),
      booking_description: 'Lieferschein',
      deliverer: data.destination,
      carrier: data.carrier,
      delivery_note_no: data.delivery_notes_no,
      type: 'increase',
      updated_at: moment().toISOString(),
    };
    if (JSON.parse(data.delivery_notes_pallets).returned_eur_pallets) stockArr.push(returned_eur);
    // if (JSON.parse(data.delivery_notes_pallets).returned_eur_pallets) addOrUpdateStock(returned_eur);

    await addOrUpdateStockAsync(stockArr);
    setIsSaving(false);
    toast.success('Lieferschein gespeichert!', {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    navigate('/merchandise-management');
  };

  useEffect(() => {
    if (addDeliveryNote.isError || stockError) {
      setIsSaving(false);
      toast.error('Es ist etwas schiefgelaufen', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [addDeliveryNote.isError, stockError]);

  const [productSizes, setProductSizes] = useState<any>([]);
  const getProductSizes = (e: any) => {
    const dataList: any[] = [];
    const id = e.target.id.split('.')[1];
    const value = getValues(`items.${id}.product_label`);
    if (products.data) {
      products.data.map((product: any) => {
        if (product.label === value) dataList.push(product.products_sizes);
      });
      setProductSizes(dataList);
    }
  };

  const navigateBack = () => {
    // if (checkForData(getValues())) {
    // 	if (window.confirm('Deine Änderungen werden eventuell nicht gespeichert.')) navigate('/merchandise-management');
    // } else {
    navigate('/merchandise-management');
    // }
  };

  const download = async (path: string) => {
    const data = await supabase.storage
      .from('user-files')
      .download(path)
      .then((data) => data);
    return data;
  };

  const saveFile = async (path: string, fileName: string) => {
    console.log('file', path, fileName);
    const data = await download(path);
    if (data.data) {
      const file = URL.createObjectURL(data.data);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style.setProperty('display', 'none');
      a.href = file;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(file);
    }
  };
  if (!boxes?.data || !pallets?.data || !carriers?.data || !systemData?.data || !destinations?.data) {
    return (
      <AdminLayout>
        <div className="flex items-center justify-center h-screen space-x-2">
          <div className="inline-block w-12 h-12 border-4 rounded-full spinner-border animate-spin" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </AdminLayout>
    );
  }
  return (
    <AdminLayout>
      <Beforeunload onBeforeunload={() => 'You’ll lose your data!'}>
        <div className="py-12">
          {/* <Link to={'/merchandise-management'} className='inline-block'> */}
          <button className="inline-block" onClick={navigateBack}>
            <a className="flex items-center btn btn-light ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M11 17l-5-5m0 0l5-5m-5 5h12" />
              </svg>
              <span className="hidden sm:inline">Alle Lieferscheine</span>
            </a>
          </button>
          {/* </Link> */}
          <form className="-mt-10" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-wrap justify-end mb-8 gap-x-8">
              <Input
                autofocus={true}
                label="Lieferdatum*"
                id="delivery_notes_date"
                type="date"
                register={register}
                errors={errors?.delivery_notes_date}
                rules={{
                  required: {
                    value: true,
                    message: 'Dies ist ein Pflichtfeld',
                  },
                }}
              />
              <Input
                label="Beleg-Nr."
                id="delivery_notes_no"
                register={register}
                defaultValue="automatisch"
                readonly={true}
                disabled={true}
              />
              <Input label="Bestell-Nr." id="consignment_number" register={register} errors={errors?.consignment_number} />
              <Select
                label="Bestimmungsort"
                placeholder="-"
                id="destination"
                options={destinations.data || []}
                register={register}
                style="w-auto min-w-[180px]"
              />
              <Select
                label="Spedition"
                placeholder="-"
                id="carrier"
                options={carriers.data || []}
                register={register}
                style="w-auto min-w-[180px]"
              />
            </div>
            <div className="overflow-x-auto">
              <table className="w-full mb-16 ">
                <thead className="text-white border bg-slate-700">
                  <tr className="text-left">
                    <th className="px-2 py-1">
                      <span className="inline-block min-w-max">VA Kisten</span>
                    </th>
                    <th className="px-2 py-1">
                      <span className="inline-block min-w-max">Anzahl Kisten</span>
                    </th>
                    <th className="px-2 py-1">
                      <span className="inline-block min-w-max">Paletten</span>
                    </th>
                    <th className="px-2 py-1">
                      <span className="inline-block min-w-max">Anzahl Paletten</span>
                    </th>
                    <th className="px-2 py-1">
                      <span className="inline-block min-w-max">Klasse</span>
                    </th>
                    <th className="px-2 py-1">
                      <span className="inline-block min-w-max">Produkt</span>
                    </th>
                    <th className="px-2 py-1">
                      <span className="inline-block min-w-max">Größe</span>
                    </th>
                    <th className="px-2 py-1">
                      <span className="inline-block min-w-max">Menge/Inhalt</span>
                    </th>
                    <th className="px-2 py-1">
                      <span className="inline-block min-w-max">Einheit</span>
                    </th>
                    <th className="px-2 py-1">
                      <span className="inline-block min-w-max">Ursprung</span>
                    </th>
                    <th className="px-2 py-1">
                      <span className="inline-block min-w-max">Regionalfenster</span>
                    </th>
                    <th className="px-2 py-1">
                      <span className="inline-block min-w-max">QS</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {fields.map((field: any, index: number) => (
                    <tr key={index} className="text-left">
                      <td className="border focus-within:bg-slate-600">
                        <SelectClassic
                          style="without-border"
                          placeholder="-"
                          id={`items.${index}.box_label`}
                          options={boxes.data || []}
                          register={register}
                          onChange={(e: any) => {
                            setValue(`items.${index}.box_label`, e.target.value);
                            checkDelivered();
                          }}
                        />
                      </td>
                      <td className="border focus-within:bg-slate-600">
                        <ClassicInput
                          style="without-border"
                          placeholder="Anzahl Kisten"
                          id={`items.${index}.box_quantity`}
                          register={register}
                          errors={errors?.items?.[index]?.box_quantity}
                          rules={{
                            pattern: {
                              value: /^[1-9]+[0-9]*$/,
                              message: 'Nur positive Ganzzahlen erlaubt',
                            },
                          }}
                          onKeyUp={checkDelivered}
                        />
                      </td>
                      <td className="border focus-within:bg-slate-600">
                        <SelectClassic
                          style="without-border"
                          placeholder="-"
                          id={`items.${index}.pallet_label`}
                          register={register}
                          options={pallets.data || []}
                          onChange={(e: any) => {
                            setValue(`items.${index}.pallet_label`, e.target.value);
                            checkDelivered();
                          }}
                        />
                      </td>
                      <td className="border focus-within:bg-slate-600">
                        <ClassicInput
                          style="without-border"
                          placeholder="Anzahl Paletten"
                          id={`items.${index}.pallets_quantity`}
                          register={register}
                          errors={errors?.items?.[index]?.pallets_quantity}
                          rules={{
                            pattern: {
                              value: /^[1-9]+[0-9]*$/,
                              message: 'Nur positive Ganzzahlen erlaubt',
                            },
                          }}
                          onKeyUp={checkDelivered}
                        />
                      </td>
                      <td className="border focus-within:bg-slate-600">
                        <SelectClassic
                          style="without-border"
                          id={`items.${index}.quality_class`}
                          options={[{ label: 'I' }, { label: 'II' }, { label: 'EXTRA' }]}
                          register={register}
                        />
                      </td>
                      <td className="border focus-within:bg-slate-600">
                        <SelectClassic
                          style="without-border"
                          placeholder="-"
                          id={`items.${index}.product_label`}
                          options={products.data || []}
                          register={register}
                        />
                      </td>
                      <td className="border focus-within:bg-slate-600">
                        <ClassicInput
                          style="without-border"
                          placeholder="-"
                          id={`items.${index}.product_size`}
                          listname="product_sizes"
                          dataList={productSizes[0] || []}
                          onFocus={getProductSizes}
                          register={register}
                        />
                      </td>
                      <td className="border focus-within:bg-slate-600">
                        <ClassicInput style="without-border" placeholder="Menge" id={`items.${index}.content_amount`} register={register} />
                      </td>
                      <td className="border focus-within:bg-slate-600">
                        <SelectClassic
                          style="without-border"
                          id={`items.${index}.unit`}
                          register={register}
                          options={[
                            { label: 'Stk', key: 'pc' },
                            { label: 'kg', key: 'kg' },
                          ]}
                        />
                      </td>
                      <td className="border focus-within:bg-slate-600">
                        <SelectClassic
                          style="without-border"
                          id={`items.${index}.origin_country`}
                          options={[{ label: 'DE' }]}
                          register={register}
                        />
                      </td>
                      <td className="border focus-within:bg-slate-600">
                        <SelectClassic
                          style="without-border"
                          placeholder="-"
                          id={`items.${index}.province`}
                          options={[{ label: 'NRW' }, { label: 'Rhld.' }]}
                          register={register}
                        />
                      </td>
                      <td className="relative w-12 border">
                        <div className="border border-transparent absolute inset-0  focus-within:!border-slate-700 flex items-center justify-center ">
                          <Checkbox register={register} id={`items.${index}.qs`} />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={11} className="py-4 ">
                      {fields.length < 8 && (
                        <button
                          type="button"
                          className="flex items-center text-slate-700"
                          onClick={() =>
                            append(
                              [
                                {
                                  box_label: '',
                                  box_quantity: '',
                                  pallet_label: '',
                                  pallets_quantity: '',
                                  product_label: '',
                                  product_size: '',
                                  content_amount: '',
                                  unit: 'pc',
                                  origin_country: 'DE',
                                  province: '',
                                  qs: true,
                                },
                              ],
                              {
                                shouldFocus: true,
                                focusIndex: fields.length,
                                focusName: `items.${fields.length}.box_label`,
                              }
                            )
                          }>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5 mr-2"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}>
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                          Position hinzufügen
                        </button>
                      )}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div className="mb-12 md:flex md:gap-10">
              <div className="w-full md:w-[28rem] mb-16 md:mb-0">
                <p className="mb-3 underline">Leergut geliefert</p>
                <table style={{ width: '100%' }}>
                  <thead className="text-white border bg-slate-700">
                    <tr>
                      <th className="px-2 py-1 w-28"></th>
                      <th className="px-2 py-1 text-left w-28">Typ</th>
                      <th className="w-20 px-2 py-1 text-left">Anzahl</th>
                    </tr>
                  </thead>
                  <tbody>
                    {delivered_boxes_fields.map((field, index) => (
                      <tr key={`delivered-boxes-${index}`}>
                        <td className="px-2 font-semibold border">Kisten</td>
                        <td className="border focus-within:bg-slate-600">
                          <SelectClassic
                            style="without-border"
                            placeholder="-"
                            id={`delivered_boxes.${index}.label`}
                            options={boxes.data}
                            register={register}
                          />
                        </td>
                        <td className="border focus-within:bg-slate-600">
                          <ClassicInput
                            style="without-border"
                            placeholder="Anzahl"
                            id={`delivered_boxes.${index}.quantity`}
                            register={register}
                            errors={errors?.delivered_boxes?.[index]?.quantity}
                            rules={{
                              pattern: {
                                value: /^([0-9]|[1-9][0-9]*)$/,
                                message: 'Nur positive Ganzzahlen erlaubt',
                              },
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td className="px-2 font-semibold border">Europal.</td>
                      <td className="border focus-within:bg-slate-600" colSpan={2}>
                        <ClassicInput
                          style="without-border"
                          placeholder="Anzahl"
                          id="empty_pallets.delivered_eur_pallets"
                          register={register}
                          // @ts-ignore
                          errors={errors?.empty_pallets?.delivered_eur_pallets}
                          rules={{
                            pattern: {
                              value: /^([0-9]|[1-9][0-9]*)$/,
                              message: 'Nur positive Ganzzahlen erlaubt',
                            },
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="px-2 font-semibold border">Düsseldorfer 1</td>
                      <td className="border focus-within:bg-slate-600" colSpan={2}>
                        <ClassicInput
                          style="without-border"
                          placeholder="Anzahl"
                          id="empty_pallets.delivered_dus_pallets"
                          register={register}
                          // @ts-ignore
                          errors={errors?.empty_pallets?.delivered_dus_pallets}
                          rules={{
                            pattern: {
                              value: /^([0-9]|[1-9][0-9]*)$/,
                              message: 'Nur positive Ganzzahlen erlaubt',
                            },
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="px-2 font-semibold border">KDP</td>
                      <td className="border focus-within:bg-slate-600" colSpan={2}>
                        <ClassicInput
                          style="without-border"
                          placeholder="Anzahl"
                          id="empty_pallets.delivered_dus2_pallets"
                          register={register}
                          // @ts-ignore
                          errors={errors?.empty_pallets?.delivered_dus2_pallets}
                          rules={{
                            pattern: {
                              value: /^([0-9]|[1-9][0-9]*)$/,
                              message: 'Nur positive Ganzzahlen erlaubt',
                            },
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="w-full md:w-[28rem] mb-16 md:mb-0">
                <p className="mb-3 underline">Leergut zurück</p>
                <table style={{ width: '100%' }}>
                  <thead className="text-white border bg-slate-700">
                    <tr>
                      <th className="px-2 py-1 w-28"></th>
                      <th className="px-2 py-1 text-left w-28">Typ</th>
                      <th className="w-20 px-2 py-1 text-left">Anzahl</th>
                    </tr>
                  </thead>
                  <tbody>
                    {returned_boxes_fields.map((field, index) => (
                      <tr key={`returned-boxes-${index}`}>
                        <td className="px-2 font-semibold border">Kisten</td>
                        <td className="border focus-within:bg-slate-600">
                          <SelectClassic
                            style="without-border"
                            placeholder="-"
                            id={`returned_boxes.${index}.label`}
                            options={boxes.data}
                            register={register}
                          />
                        </td>
                        <td className="border focus-within:bg-slate-600">
                          <ClassicInput
                            style="without-border"
                            placeholder="Anzahl"
                            id={`returned_boxes.${index}.quantity`}
                            register={register}
                            errors={errors?.returned_boxes?.[index]?.quantity}
                            rules={{
                              pattern: {
                                value: /^([0-9]|[1-9][0-9]*)$/,
                                message: 'Nur positive Ganzzahlen erlaubt',
                              },
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td className="px-2 font-semibold border">Europal.</td>
                      <td className="border focus-within:bg-slate-600" colSpan={2}>
                        <ClassicInput
                          style="without-border"
                          placeholder="Anzahl"
                          id="empty_pallets.returned_eur_pallets"
                          register={register}
                          // @ts-ignore
                          errors={errors?.empty_pallets?.returned_eur_pallets}
                          rules={{
                            pattern: {
                              value: /^([0-9]|[1-9][0-9]*)$/,
                              message: 'Nur positive Ganzzahlen erlaubt',
                            },
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="px-2 font-semibold border">Düsseldorfer 1</td>
                      <td className="border focus-within:bg-slate-600" colSpan={2}>
                        <ClassicInput
                          style="without-border"
                          placeholder="Anzahl"
                          id="empty_pallets.returned_dus_pallets"
                          register={register}
                          // @ts-ignore
                          errors={errors?.empty_pallets?.returned_dus_pallets}
                          rules={{
                            pattern: {
                              value: /^([0-9]|[1-9][0-9]*)$/,
                              message: 'Nur positive Ganzzahlen erlaubt',
                            },
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="px-2 font-semibold border">KDP</td>
                      <td className="border focus-within:bg-slate-600" colSpan={2}>
                        <ClassicInput
                          style="without-border"
                          placeholder="Anzahl"
                          id="empty_pallets.returned_dus2_pallets"
                          register={register}
                          // @ts-ignore
                          errors={errors?.empty_pallets?.returned_dus2_pallets}
                          rules={{
                            pattern: {
                              value: /^([0-9]|[1-9][0-9]*)$/,
                              message: 'Nur positive Ganzzahlen erlaubt',
                            },
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="w-full xl:flex-1 xl:w-auto">
                <div className="relative w-full h-full">
                  <p className="mb-3 underline">Unterschrift</p>
                  <button type="button" className="w-full h-[291px] border " onClick={(_) => setModalIsOpen(true)}>
                    <img src={signatureImage} className="object-contain w-full h-full" />
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center gap-8 mb-8">
              <button disabled={isSaving} type="submit" className="btn btn-primary">
                {!stateUser ? 'Speichern' : 'Aktualisieren'}
              </button>
              {experimental && (
                <div className="flex items-center gap-2">
                  <input type="file" id="file" {...register('file')} multiple className="hidden" />
                  <label htmlFor="file" className="flex items-center gap-2 cursor-pointer btn btn-primary">
                    <DocumentAddIcon className="w-5 h-5" /> Dateien anhängen
                  </label>
                  <span>{files}</span>
                </div>
              )}
            </div>
            {experimental && attachedFiles?.data?.data.length > 0 && (
              <>
                <p className="mb-2 underline">Angehängte Dateien:</p>
                <div className="flex gap-2">
                  {attachedFiles.data.data.map((item: any) => (
                    <button
                      type="button"
                      onClick={(_) => saveFile(`${acId}/Lieferscheine/${state?.delivery_notes_no}/${item.name}`, item.name)}
                      className="flex items-center gap-2 px-4 py-1 border rounded-lg bg-slate-100 border-slate-300"
                      key={item.id}>
                      {item.name} <TrashIcon width={18} className="text-red-600" />
                    </button>
                  ))}
                </div>
              </>
            )}
          </form>
        </div>
      </Beforeunload>
      {isSaving && (
        <div className="absolute inset-0 bg-gray-700/70">
          <div className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            <svg
              role="status"
              className="w-20 h-20 mr-2 animate-spin text-slate-200 fill-sky-500"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <p className="mt-4 text-lg font-semibold text-white">speichert...</p>
          </div>
        </div>
      )}
      <Modal
        isOpen={modalIsOpen}
        className="absolute p-2 -translate-x-1/2 -translate-y-1/2 bg-white left-1/2 top-1/2"
        overlayClassName="bg-slate-700/90 z-50 absolute inset-0">
        <Signature setSignature={setSignature} closeModal={(_: any) => setModalIsOpen(false)} />
      </Modal>
    </AdminLayout>
  );
};

export default DeliverNote;

const hasItems = (data: any) => {
  if (data?.items) {
    const itemCheck = data.items.map((item: any) => {
      if (
        item.box_label ||
        item.box_quantity ||
        item.pallets_quantity ||
        item.content_amount ||
        item.product_label ||
        item.product_size ||
        item.destination
      ) {
        return true;
      } else {
        return false;
      }
    });
    if (itemCheck.includes(true)) return true;
  }
  return false;
};

const hasDeliveredBoxes = (data: any) => {
  if (data?.delivered_boxes) {
    const deliveredBoxesCheck = data.delivered_boxes.map((box: any) => {
      if (box.label || box.quantity) {
        return true;
      } else {
        return false;
      }
    });
    if (deliveredBoxesCheck.includes(true)) return true;
  }
  return false;
};

const hasReturnedBoxes = (data: any) => {
  if (data?.returned_boxes) {
    const returnedBoxesCheck = data.returned_boxes.map((box: any) => {
      if (box.label || box.quantity) {
        return true;
      } else {
        return false;
      }
    });
    if (returnedBoxesCheck.includes(true)) return true;
  }
  return false;
};

const hasEmptyPallets = (data: any) => {
  if (data?.empty_pallets && data.empty_pallets.length > 0) {
    if (
      data.empty_pallets[0].delivered_dus_pallets ||
      data.empty_pallets[0].delivered_dus2_pallets ||
      data.empty_pallets[0].delivered_eur_pallets ||
      data.empty_pallets[0].returned_dus_pallets ||
      data.empty_pallets[0].returned_dus2_pallets ||
      data.empty_pallets[0].returned_eur_pallets
    ) {
      return true;
    }
  }
  return false;
};

const checkForData = (data: any) => {
  if (hasItems(data) || hasDeliveredBoxes(data) || hasReturnedBoxes(data) || hasEmptyPallets(data)) {
    return true;
  } else {
    return false;
  }
};

const checkForStock = (data: any) => {
  if (hasDeliveredBoxes(data) || hasReturnedBoxes(data) || hasEmptyPallets(data)) {
    return true;
  } else {
    return false;
  }
};
