import { FC } from 'react';

interface Select {
	label: string;
	placeholder?: string;
	id: string;
	style?: string;
	rules?: any;
	options?: any[];
	listname?: string;
	register: any;
	autofocus?: boolean;
	onChange?: any;
	errors?: any;
}

const Select: FC<Select> = ({
	rules,
	errors,
	label,
	placeholder,
	id,
	style,
	options,
	listname,
	register,
	autofocus,
}) => {
	return (
		<fieldset className={`relative standard-input ${style || ''}`}>
			<select
				{...register(id, rules)}
				list={listname || ''}
				className={`${errors?.message && 'border-red-600 focus-within:!border-red-400'} peer`}
				name={id}
				id={id}
				autoComplete='off'
				autoFocus={autofocus || false}
			>
				<option value='' key={null}>
					{placeholder || label}
				</option>
				{options &&
					options.map((item: any, index: any) => (
						<option key={(item.key || item.label) + '-' + index} value={item.key || item.label}>
							{item.label}
						</option>
					))}
			</select>

			<label className={`${errors?.message && '!text-red-600'} standard-input-label`} htmlFor={id}>
				{label}
			</label>
			{errors?.message && (
				<span className='absolute text-red-500 bottom-3.5 left-2.5 z-50 text-sm'>{errors?.message}</span>
			)}
		</fieldset>
	);
};

export default Select;
