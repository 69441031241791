import React from 'react';
import { useEffect, useState } from 'react';
import moment from 'moment';

const TimeTrackingWeek = () => {
	return (
		<div>
			<p className='mb-6 font-bold text-lg'>07.02.2022 - 13.02.2022</p>
			<table className='w-full text-left'>
				<thead>
					<tr>
						<th>Nr.</th>
						<th>Name</th>
						<th>Montag</th>
						<th>Dienstag</th>
						<th>Mittwoch</th>
						<th>Donnerstag</th>
						<th>Freitag</th>
						<th>Samstag</th>
						<th>Sonntag</th>
					</tr>
				</thead>
				<tbody>
					<tr className='bg-slate-100'>
						<td>175</td>
						<td>Christian Kolbow</td>
						{[...Array(5)].map((e, i) => (
							<td key={i}>10.0</td>
						))}
						<td>4.37</td>
						<td>0</td>
					</tr>
					<tr>
						<td>111</td>
						<td>Barbara Milz</td>
						{[...Array(5)].map((e, i) => (
							<td key={i}>9.75</td>
						))}
						<td>4.34</td>
						<td>0</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default TimeTrackingWeek;
