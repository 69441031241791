import { useContext } from 'react';
import { useMutation } from 'react-query';
import { supabase } from '../utils/client';
import { UserContext } from '../App';
import moment from 'moment';

const useAddCarrier = (carrier) => {
	const { acId } = useContext(UserContext);

	return useMutation(
		['carriers'],
		async () =>
			await supabase
				.from('carriers')
				.upsert([
					{
						id: carrier.id,
						fk_ac_customer: acId,
						label: carrier.label,
						description: carrier.description,
						updated_at: moment().toISOString(),
					},
				])
				.single()
				.then(handleSupabaseError)
				.then(({ data }) => data)
	);
};

export default useAddCarrier;

const handleSupabaseError = ({ error, ...rest }) => {
	if (error) {
		throw error;
	}
	return rest;
};
