import { useContext } from 'react';
import { useQuery } from 'react-query';
import { UserContext } from '../App';
import { supabase } from '../utils/client';

const useLastTimestamp = (employeeId: number | undefined) => {
	const { acId } = useContext(UserContext);

	return useQuery(
		['last-time-stamp'],
		async () =>
			await supabase
				.from('time_clock')
				.select(
					`   come,
							leave
				`
				)
				.eq('fk_ac_customer', acId)
				.eq('fk_employee', employeeId)
				.order('come', { ascending: false })
				.limit(1)
				.single()
				.then(handleSupabaseError)
				.then(({ data }) => data),
		{ enabled: !!employeeId, refetchOnWindowFocus: false }
	);
};

export default useLastTimestamp;

const handleSupabaseError = ({ error, ...rest }: any) => {
	if (error) {
		throw error;
	}
	return rest;
};
