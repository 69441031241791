import React from 'react';
import AdminLayout from '../layouts/AdminLayout';

const Bonus = () => {
	return (
		<AdminLayout>
			<h1 className='font-bold text-3xl text-slate-700'>Bonus System</h1>
		</AdminLayout>
	);
};

export default Bonus;
