import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import Input from '../components/form/Input';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../provider/AuthProvider';
import Spinner from '../components/Spinner';

const Login: FC = () => {
	const [isRegistered, setIsRegistered] = useState<boolean>(true);
	const { user, signUp, signIn } = useAuth();
	let navigate = useNavigate();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const registerUser = async (data: any) => {
		alert('Derzeit sind keine Registrierungen möglich.');
		// const res = await signUp(
		// 	{ email: data.email, password: data.password },
		// 	{ data: { ackercloud_id: uuidv4(), creator: true } }
		// );
		// if (res.user?.role === 'authenticated') navigate('/confirm');
		// if (res.error)
		// 	toast.error(
		// 		res.error.message === 'Email address already registered by another user'
		// 			? 'Diese Email Adresse wird bereits verwendet.'
		// 			: 'Etwas ist schiefgelaufen!',
		// 		{
		// 			position: toast.POSITION.BOTTOM_RIGHT,
		// 		}
		// 	);
	};
	const loginUser = async (data: any) => {
		const res = await signIn({ email: data.email, password: data.password });
		if (res.error)
			toast.error(
				res.error.message === 'Invalid login credentials'
					? 'Email oder Passwort falsch!'
					: 'Es ist ein Fehler aufgetreten!',
				{
					position: toast.POSITION.BOTTOM_RIGHT,
				}
			);
	};

	if (user && user.aud)
		return (
			<>
				<div className='h-screen bg-slate-50 flex flex-col items-center justify-center'>
					<img src='/ackercloud-logo.png' />
					<div className='mb-8 text-slate-700'>
						<Spinner />
						<span>Bitte warten. Sie werden sofort weitergeleitet. </span>
						<a href='/' className='underline font-semibold hover:text-slate-500'>
							Direkt zur Startseite!
						</a>
					</div>
				</div>
			</>
		);

	return (
		<>
			<div className='h-screen bg-slate-50 flex flex-col items-center justify-center'>
				<div className='h-96 w-96 bg-white shadow-xl shadow-slate-700/15'>
					<h2 className='bg-slate-700 text-white font-semibold  text-xl text-center p-4'>
						{isRegistered ? 'Login' : 'Registrieren'}
					</h2>
					<div className='flex flex-col pt-12'>
						{isRegistered && (
							<form onSubmit={handleSubmit(loginUser)} className='flex flex-col items-center justify-center'>
								<Input
									register={register}
									id='email'
									type='email'
									label='Email'
									errors={errors?.email}
									rules={{
										required: { value: true, message: 'Dies ist ein Pflichtfeld' },
										pattern: {
											value:
												/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
											message: 'Keine gültige Email Adresse',
										},
									}}
								/>
								<Input
									register={register}
									type='password'
									id='password'
									label='Passwort'
									errors={errors?.password}
									rules={{
										required: {
											value: true,
											message: 'Dies ist ein Pflichtfeld',
										},
									}}
								/>
								<button type='submit' className='btn btn-primary mb-2'>
									Jetzt einloggen!
								</button>
							</form>
						)}
						{!isRegistered && (
							<form onSubmit={handleSubmit(registerUser)} className='flex flex-col items-center justify-center'>
								<Input
									register={register}
									id='email'
									type='email'
									label='Email'
									errors={errors?.email}
									rules={{
										required: { value: true, message: 'Dies ist ein Pflichtfeld' },
										pattern: {
											value:
												/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
											message: 'Keine gültige Email Adresse',
										},
									}}
								/>
								<Input
									register={register}
									type='password'
									id='password'
									label='Passwort'
									errors={errors?.password}
									rules={{
										required: { value: true, message: 'Dies ist ein Pflichtfeld' },
										pattern: {
											value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
											message: 'Mindestens 8 Zeichen. Groß-& Kleinbuchstaben und Zahlen.',
										},
									}}
								/>
								<button type='submit' className='btn btn-primary mb-2'>
									Jetzt registrieren!
								</button>
							</form>
						)}
						<button className='underline' onClick={(_) => setIsRegistered(!isRegistered)}>
							{isRegistered ? 'Keinen Account? Registrieren.' : 'Bereits registriert? Einloggen.'}
						</button>
					</div>
				</div>
			</div>
			<ToastContainer />
		</>
	);
};

export default Login;
