import { useState, useEffect } from 'react';
import { supabase } from '../utils/client';
import Cards from '../components/Cards';
import Snackbar from '../components/Snackbar';
import { Link } from 'react-router-dom';
import useEmployeeByRfid from '../hooks/useEmployeeByRfid';
import useLastTimestamp from '../hooks/useLastTimestamp';

const Tracker = () => {
	const [employeeId, setEmployeeId] = useState<number | undefined>(undefined);
	const [rfid, setRfid] = useState<string>('');
	const [rfidInput, setRfidInput] = useState('');
	const [employeeName, setEmployeeName] = useState('');
	const [language, setLanguage] = useState('de');
	const [lastStampType, setLastStampType] = useState(undefined);
	const [stampDate, setStampDate] = useState('');
	const [stampDisplayDate, setStampDisplayDate] = useState('');
	const [stampTime, setStampTime] = useState('');
	const [actionMessage, setActionMessage] = useState('Halten Sie Ihren Chip an das Lesegerät');
	const actionMessages = [
		'Halten Sie Ihren Chip an das Lesegerät',
		'Ține-ți cipul în fața cititorului',
		'Дръжте чипа си до четеца',
		'Zbliż chip do czytnika',
	];
	const [workingHours, setWorkingHours] = useState(0);
	const [pauseHours, setPauseHours] = useState(0);
	const [snackbarColor, setSnackbarColor] = useState('bg-gray-200 text-gray-900');
	const [snackbarStatus, setSnackbarStatus] = useState('translate-y-0');
	const [snackbarText, setSnackbarText] = useState('Mein Text hier');
	const lastTimestamp = useLastTimestamp(employeeId);
	const employee = useEmployeeByRfid(rfid);

	useEffect(() => {
		if (employee?.data) {
			setEmployeeId(employee?.data?.id);
			console.log(employee?.data?.id);
		}
	}, [employee.isSuccess]);

	useEffect(() => {
		if (lastTimestamp?.data) console.log('my last stamp', lastTimestamp?.data);
	}, [lastTimestamp.isSuccess]);

	const createNewStamp = async (stampType: any) => {
		let stampDisplayType = stampType;
		if (stampType === 'come') stampDisplayType = `"Kommen"`;
		if (stampType === 'leave') stampDisplayType = `"Gehen"`;
		if (stampType === 'pause') stampDisplayType = `"Pause"`;
		if (stampType === 'restart') stampDisplayType = `"Pause beendet"`;

		const { error } = await supabase
			.from('time_clock')
			.insert([{ rfid: rfid, type: stampType, date: stampDate, time: stampTime, employeeId: employeeId }]);
		if (error) {
			setSnackbarStatus('-translate-y-40');
			setSnackbarColor('bg-red-200 text-red-900');
			setSnackbarText('Es ist ein Fehler aufgetreten. Nochmal versuchen!');
		} else {
			setSnackbarStatus('-translate-y-40');
			setSnackbarColor('bg-green-200 text-green-900');
			setSnackbarText(`${stampDisplayType} am ${stampDisplayDate} um ${stampTime} gespeichert.`);
		}
	};

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		const input = e.target[0].value;
		if (input) {
			setRfidInput('');
			setRfid(input);
		}
	};

	// useEffect(() => {
	// 	infiniteLoop();
	// }, []);

	// const infiniteLoop = () => {
	// 	let i = 0;
	// 	setInterval(() => {
	// 		setActionMessage(actionMessages[i]);
	// 		i++;
	// 		if (i >= actionMessages.length) {
	// 			i = 0;
	// 		}
	// 	}, 3000);
	// };

	const book = async (type: any) => {
		await createNewStamp(type);
		setRfid('');
		setEmployeeId(undefined);
		setTimeout(() => {
			setSnackbarStatus('translate-y-40');
			setSnackbarColor('bg-gray-200 text-gray-900');
			setSnackbarText('');
		}, 4000);
	};

	if (rfid)
		return (
			<div className='relative h-screen'>
				<Cards
					stampDisplayDate={stampDisplayDate}
					lastStampType={lastStampType}
					stampTime={stampTime}
					employeeName={employeeName}
					book={book}
					workingHours={workingHours}
					pauseHours={pauseHours}
				/>
			</div>
		);
	return (
		<div className='relative h-screen overflow-hidden'>
			<div className='flex flex-col justify-center items-center h-screen'>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					className='h-96 w-96 rounded-full border-8 p-24 rotate-90 animate-pulse mb-20 border-emerald-800 text-emerald-800'
					fill='none'
					viewBox='0 0 24 24'
					stroke='currentColor'
				>
					<path
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeWidth={2}
						d='M8.111 16.404a5.5 5.5 0 017.778 0M12 20h.01m-7.08-7.071c3.904-3.905 10.236-3.905 14.141 0M1.394 9.393c5.857-5.857 15.355-5.857 21.213 0'
					/>
				</svg>
				<p className='text-5xl text-emerald-800'>{actionMessage}</p>
				<form onSubmit={handleSubmit} className='h-0 w-0 overflow-hidden'>
					<input
						type='text'
						autoFocus
						onChange={(e) => setRfidInput(e.target.value)}
						value={rfidInput}
						onBlur={(e) => e.target.focus()}
					/>
					<button type='submit'></button>
				</form>
			</div>
			<Link to={'/'}>
				<p className='text-black bottom-0 right-0 py-8 px-12 absolute'>Admin Login</p>
			</Link>
			<Snackbar color={snackbarColor} status={snackbarStatus} text={snackbarText} />
		</div>
	);
};

export default Tracker;
