import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useAddCarrier from '../../hooks/useAddCarrier';
import useDeleteCarrier from '../../hooks/useDeleteCarrier';
import useCarriers from '../../hooks/useCarriers';
import Input from '../form/Input';

const Carriers = () => {
	const [carrier, setCarrier] = useState<any>(undefined);
	const [carrierId, setCarrierId] = useState<any>(undefined);
	const carriers = useCarriers();
	const addCarrier = useAddCarrier(carrier);
	const deleteCarrier = useDeleteCarrier(carrierId);

	const {
		register,
		handleSubmit,
		reset,
		control,
		formState: { errors },
	} = useForm();

	const onSubmit = (data: any) => {
		setCarrier(data);
	};

	useEffect(() => {
		if (carrier) addCarrier.mutate();
	}, [carrier]);

	useEffect(() => {
		if (addCarrier.isSuccess) {
			reset({ label: null, description: null });
			setCarrier(undefined);
			carriers.refetch();
		}
	}, [addCarrier.isSuccess]);

	const deleteCurrentBox = (id: number) => {
		setCarrierId(id);
	};
	useEffect(() => {
		if (carrierId) {
			if (window.confirm('Soll dieser Eintrag wirklich gelöscht werden?')) {
				deleteCarrier.mutate();
			} else {
				setCarrierId(undefined);
			}
		}
	}, [carrierId]);

	useEffect(() => {
		if (deleteCarrier.isSuccess) {
			carriers.refetch();
			setCarrierId(undefined);
		}
		reset({ label: null, description: null });
	}, [deleteCarrier.isSuccess]);

	return (
		<>
			<h1 className='text-2xl mb-6'>Speditionen</h1>
			<div className='flex justify-between flex-wrap lg:flex-nowrap gap-8'>
				<div className='w-full lg:w-2/5 p-4 border'>
					<form onSubmit={handleSubmit(onSubmit)}>
						<p className='mb-6'>Speditionen anlegen/bearbeiten</p>
						<Input
							id='label'
							style='flex-1'
							label='Bezeichnung*'
							register={register}
							errors={errors?.label}
							rules={{
								required: { value: true, message: 'Dies ist ein Pflichtfeld' },
							}}
						/>
						<Input id='description' style='flex-1' label='Beschreibung' register={register} />
						<div className='flex gap-8'>
							<button type='submit' className='btn btn-primary'>
								Speichern
							</button>
							<button
								type='button'
								className='btn btn-light'
								onClick={(_) => {
									setCarrier(undefined);
									reset({ label: null, description: null });
								}}
							>
								Zurücksetzen
							</button>
						</div>
					</form>
				</div>
				<div className='w-full lg:w-3/5  overflow-x-auto'>
					<table className='ac-table'>
						<thead>
							<tr>
								<th>
									<span>Bezeichnung</span>
								</th>
								<th>
									<span>Beschreibung</span>
								</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{carriers?.data &&
								carriers?.data.map((box: any, index: number) => (
									<tr key={box.label + index.toString()}>
										<td>{box.label}</td>
										<td>{box.description}</td>
										<td className='w-24'>
											<div className='flex justify-between items-center px-2'>
												<button
													className='hover:scale-110'
													onClick={(_) => {
														reset(box);
													}}
												>
													<PencilAltIcon width={24} height={24} className='text-slate-700' />
												</button>
												<button className='hover:scale-110' onClick={(_) => deleteCurrentBox(box.id)}>
													<TrashIcon width={24} height={24} className='text-red-500' />
												</button>
											</div>
										</td>
									</tr>
								))}
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
};

export default Carriers;
