import { useContext } from 'react';
import { useQuery } from 'react-query';
import { UserContext } from '../App';
import { supabase } from '../utils/client';

const usePallets = () => {
	const { acId } = useContext(UserContext);

	return useQuery(
		['pallets', acId],
		async () =>
			await supabase
				.from('inventories')
				.select(
					`   id,
							label,
							description,
							default_unit,
							inventory_minimum_stock(
								id,
								minimum_stock
							)
				`
				)
				.eq('fk_ac_customer', acId)
				.eq('category', 'Paletten')
				.order('label', { ascending: true })
				.then(handleSupabaseError)
				.then(({ data }) => data),
		{
			refetchOnWindowFocus: false,
			cacheTime: Infinity,
		}
	);
};

export default usePallets;

const handleSupabaseError = ({ error, ...rest }: any) => {
	if (error) {
		throw error;
	}
	return rest;
};
