import { useContext } from 'react';
import { useQuery } from 'react-query';
import { UserContext } from '../App';
import { supabase } from '../utils/client';

const useDestinations = () => {
	const { acId } = useContext(UserContext);

	return useQuery(
		['destinations', acId],
		async () =>
			await supabase
				.from('destinations')
				.select(
					`   id,
							label,
							description
				`
				)
				.eq('fk_ac_customer', acId)
				.order('label', { ascending: true })
				.then(handleSupabaseError)
				.then(({ data }) => data),
		{
			refetchOnWindowFocus: false,
			cacheTime: Infinity,
		}
	);
};

export default useDestinations;

const handleSupabaseError = ({ error, ...rest }: any) => {
	if (error) {
		throw error;
	}
	return rest;
};
