import { useContext } from "react";
import { useMutation } from "react-query";
import { supabase } from "../utils/client";
import { UserContext } from "../App";
import moment from "moment";

const useAddBox = (box) => {
  const { acId } = useContext(UserContext);

  return useMutation(
    ["boxes"],
    async () =>
      await supabase
        .from("inventories")
        .upsert([
          {
            id: box.id,
            fk_ac_customer: acId,
            label: box.label,
            description: box.description,
            article_no: box.article_no,
            category: "Kisten",
            default_unit: box.default_unit,
            purchasing_price_netto: box.purchasing_price_netto
              ? Number(box.purchasing_price_netto.replace(",", "."))
              : null,
            purchasing_price_brutto: box.purchasing_price_brutto
              ? Number(box.purchasing_price_brutto.replace(",", "."))
              : null,
            selling_price_netto: box.selling_price_netto
              ? Number(box.selling_price_netto.replace(",", "."))
              : null,
            selling_price_brutto: box.selling_price_brutto
              ? Number(box.selling_price_brutto.replace(",", "."))
              : null,
            tax: Number(box.tax),
            updated_at: moment().toISOString(),
          },
        ])
        .single()
        .then(handleSupabaseError)
        .then(({ data }) => data)
  );
};

export default useAddBox;

const handleSupabaseError = ({ error, ...rest }) => {
  if (error) {
    throw error;
  }
  return rest;
};
