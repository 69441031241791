import React from 'react';
import { useEffect, useState } from 'react';
import moment from 'moment';

const TimeTrackingMonth = () => {
	const [daysInMonth, setDaysInMonth] = useState(31);
	useEffect(() => {
		setDaysInMonth(moment('2022-02', 'YYYY-MM').daysInMonth());
	}, []);
	return (
		<div>
			<p className='mb-6 font-bold text-lg'>Februar 2022</p>

			<table className='w-full text-left'>
				<thead>
					<tr>
						<th>Nr.</th>
						<th>Name</th>
						{daysInMonth && [...Array(daysInMonth)].map((e, i) => <th key={i}>{i + 1}.</th>)}
					</tr>
				</thead>
				<tbody>
					<tr className='bg-slate-100'>
						<td>175</td>
						<td>Christian Kolbow</td>
						{daysInMonth && [...Array(daysInMonth)].map((e, i) => <td key={i}>10.0</td>)}
					</tr>
					<tr>
						<td>111</td>
						<td>Barbara Milz</td>
						{daysInMonth && [...Array(daysInMonth)].map((e, i) => <td key={i}>9.75</td>)}
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default TimeTrackingMonth;
