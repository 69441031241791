import { useContext } from 'react';
import { useQuery } from 'react-query';
import { UserContext } from '../App';
import { supabase } from '../utils/client';

const useDeliveryNotesItems = (search: string | undefined, dateFrom: string, dateTo: string) => {
	const { acId } = useContext(UserContext);

	return useQuery(
		['deliveryNotesItems', search, dateFrom, dateTo, acId],
		async () => {
			let query = supabase
				.from('delivery_notes')
				.select(`delivery_notes_items, destination`)
				.eq('fk_ac_customer', acId)
				.eq('is_canceled', false)
				.eq('is_deleted', false)
				.gte('delivery_notes_date', dateFrom)
				.lte('delivery_notes_date', dateTo);

			if (search) {
				query = query.or(
					`destination.ilike.%${search}%,consignment_number.ilike.%${search}%,carrier.ilike.%${search}%`
				);
			}

			const queryRes = await query;
			const response = await handleSupabaseError(queryRes);
			return response;
		},
		{ refetchOnWindowFocus: true, staleTime: 15000 }
	);
};

export default useDeliveryNotesItems;

const handleSupabaseError = ({ error, ...rest }: any) => {
	if (error) {
		throw error;
	}
	return rest;
};
