import Input from './form/Input';
import Select from './form/Select';

const EmployeeForm = ({ employee, register, onSubmit, handleSubmit }) => {
	return (
		<form className='w-full accent-slate-500 mb-8 pt-3' onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
			<div className='flex justify-between items-center mb-8'>
				{employee && (employee[0]?.firstname || employee[0]?.lastname) && (
					<h1 className='font-bold text-4xl text-slate-700'>
						{employee && employee[0]?.firstname} {employee && employee[0]?.lastname}
					</h1>
				)}
				{!employee && <h1 className='font-bold text-4xl text-slate-700'>Neuer Mitarbeiter</h1>}
				<button type='submit' className='btn btn-primary'>
					Speichern
				</button>
			</div>
			<div className='grid grid-cols-3 gap-8'>
				<div className=' border  py-4 px-6 '>
					<Input label='Mitarbeiter-Nr' id='employee_id' register={register} />
					<Input label='RFID' id='rfid' register={register} />
					<Select
						placeholder=' - wählen - '
						id='salutation'
						options={[
							{ label: 'Herr', key: 'mr' },
							{ label: 'Frau', key: 'mrs' },
						]}
						register={register}
						label={'Anrede'}
					/>
					<Input label='Name' id='lastname' register={register} />
					<Input label='Vorname' id='firstname' register={register} />
					<Input label='Straße' id='address.street' register={register} />
					<Input label='Haus-Nr.' id='address.house_number' register={register} />
					<Input label='Adress-Zusatz' id='address.address_additional_info' register={register} />
					<Input label='PLZ' id='address.zip' register={register} />
					<Input label='Stadt' id='address.city' register={register} />
					<Input
						label='Land - ToDo'
						id='address.country'
						dataList={[
							{ label: 'Deutschland', code: 'de' },
							{ label: 'Polen', code: 'pol' },
							{ label: 'Rumänien', code: 'rou' },
						]}
						listname={'countries'}
						register={register}
					/>
				</div>
				<div className=' border  py-4 px-6 '>
					<Input label='Staatsangehörigkeit' id='nationality' register={register} />
					<Input label='Sozialversicherungs-Nr.' id='social_security_number' register={register} />
					<Input label='Geburtsdatum' id='birthday' type='date' register={register} />
					<Input label='Geburtsname' id='maiden_name' register={register} />
					<Input
						label='Geburtsland - ToDo'
						id='birth_country'
						dataList={[
							{ label: 'Deutschland', code: 'de' },
							{ label: 'Polen', code: 'pol' },
							{ label: 'Rumänien', code: 'rou' },
						]}
						listname={'birthCountries'}
						register={register}
					/>
					<Input label='Geburtsort' id='birth_city' register={register} />
					<Select
						placeholder=' - wählen - '
						label='Geschlecht'
						id='gender'
						options={[
							{ label: 'divers', key: 'd' },
							{ label: 'männlich', key: 'm' },
							{ label: 'weiblich', key: 'f' },
						]}
						register={register}
					/>
					<Input label='Familienstand - ToDo' id='marital_status' register={register} />
					<Input
						label='Religion - ToDo'
						id='religion'
						dataList={[
							{ label: 'römisch-katholisch', code: 'rk' },
							{ label: 'evangelisch', code: 'ev' },
						]}
						listname={'religions'}
						register={register}
					/>
				</div>
				<div className=' border py-4 px-6 '>
					<Input label='Versicherungs-Nr' id='insurance_number' register={register} />
					<Input label='Helfer Status - ToDo' id='helper_status' register={register} />
					<Input label='Arbeitsbereich - ToDo' id='workspace' register={register} />
					<Input label='Tätigkeit - ToDo' id='task' register={register} />
					<Input label='Beschäftigungsort - ToDo' id='place_of_employment' register={register} />
					<Input label='Beschäftigt als - ToDo' id='employed_as' register={register} />
					<Input label='Lohngruppe - ToDo' id='wage_group' register={register} />
					<Input label='Personengruppe - ToDo' id='person_group' register={register} />
					<Input label='Unterkunft' id='accomodation' register={register} />
				</div>
			</div>
		</form>
	);
};

export default EmployeeForm;
