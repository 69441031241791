import { UserGroupIcon } from '@heroicons/react/outline';

const Cards = ({ employeeName, book, stampDisplayDate, lastStampType, stampTime, workingHours, pauseHours }: any) => {
	return (
		<div className='flex flex-col gap-8 h-screen justify-center items-center'>
			<h1 className='text-4xl font-bold text-slate-700'>{employeeName}</h1>
			<p className='mb-4 text-xl'>
				Zeit des Stempels: {stampDisplayDate}, {stampTime}Uhr
			</p>
			<p>
				Arbeitsstunden heute: {workingHours.toFixed(2)} - Pause heute: {pauseHours.toFixed(2)}
			</p>
			<div className='flex gap-8 justify-center items-center'>
				<button
					disabled={lastStampType === 'come' || lastStampType === 'pause' || lastStampType === 'restart'}
					className={
						lastStampType === 'come' || lastStampType === 'pause' || lastStampType === 'restart'
							? 'bg-neutral-500 py-48 px-24 rounded-xl text-white'
							: 'bg-green-700 py-48 px-24 rounded-xl text-white cursor-pointer'
					}
					onClick={(e) => book('come')}
				>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						className='h-32 w-32'
						fill='none'
						viewBox='0 0 24 24'
						stroke='currentColor'
					>
						<path
							strokeLinecap='round'
							strokeLinejoin='round'
							strokeWidth={2}
							d='M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1'
						/>
					</svg>
				</button>
				<button
					disabled={lastStampType === 'leave'}
					className={
						lastStampType === 'leave'
							? 'bg-neutral-500 py-48 px-24 rounded-xl text-white'
							: 'bg-red-700 py-48 px-24 rounded-xl text-white cursor-pointer'
					}
					onClick={(e) => book('leave')}
				>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						className='h-32 w-32'
						fill='none'
						viewBox='0 0 24 24'
						stroke='currentColor'
					>
						<path
							strokeLinecap='round'
							strokeLinejoin='round'
							strokeWidth={2}
							d='M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1'
						/>
					</svg>
				</button>
				{/* <button
					disabled={lastStampType === 'leave'}
					className={
						lastStampType === 'leave'
							? 'bg-neutral-500 py-48 px-24 rounded-xl text-white'
							: 'bg-sky-700 py-48 px-24 rounded-xl text-white cursor-pointer'
					}
					onClick={(e) => {
						lastStampType !== 'pause' ? book('pause') : book('restart');
					}}
				>
					{lastStampType === 'pause' && (
						<svg
							xmlns='http://www.w3.org/2000/svg'
							className='h-32 w-32'
							fill='none'
							viewBox='0 0 24 24'
							stroke='currentColor'
						>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth={2}
								d='M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z'
							/>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth={2}
								d='M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
							/>
						</svg>
					)}
					{lastStampType !== 'pause' && (
						<svg
							xmlns='http://www.w3.org/2000/svg'
							className='h-32 w-32'
							fill='none'
							viewBox='0 0 24 24'
							stroke='currentColor'
						>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth={2}
								d='M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z'
							/>
						</svg>
					)}
				</button> */}
				{/* <button className='py-48 px-24  rounded-xl bg-fuchsia-700 text-white cursor-pointer' onClick={showTimeStamps}>
					<UserGroupIcon width={128} height={128} />
				</button> */}
			</div>
		</div>
	);
};

export default Cards;
