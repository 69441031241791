import { useMutation } from "react-query";
import { supabase } from "../utils/client";

const useDeleteFiles = () => {
  return useMutation(["file-delete"], File);
};

export default useDeleteFiles;

const File = async (paths) => {
  return await supabase.storage
    .from("user-files")
    .remove(paths)
    .then(handleSupabaseError)
    .then(({ data }) => data);
};

const handleSupabaseError = ({ error, ...rest }) => {
  if (error) {
    throw error;
  }
  return rest;
};
