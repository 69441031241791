import { useContext } from 'react';
import { useMutation } from 'react-query';
import { supabase } from '../utils/client';
import { UserContext } from '../App';
import moment from 'moment';

const useAddDeliveryNote = (deliveryNote) => {
	const { acId } = useContext(UserContext);

	const items = deliveryNote?.items.map((item) => {
		return JSON.stringify(item);
	});
	const pallets = JSON.stringify(deliveryNote?.empty_pallets);
	const delivered_boxes = deliveryNote?.delivered_boxes.map((item) => {
		return JSON.stringify(item);
	});
	const returned_boxes = deliveryNote?.returned_boxes.map((item) => {
		return JSON.stringify(item);
	});

	return useMutation(
		['deliveryNotes'],
		async () =>
			await supabase
				.from('delivery_notes')
				.upsert([
					{
						id: deliveryNote.id,
						fk_ac_customer: acId,
						created_by_user: deliveryNote.created_by_user,
						updated_by_user: deliveryNote.updated_by_user,
						updated_at: moment().toISOString(),
						delivery_notes_no: deliveryNote.delivery_notes_no,
						company_short: deliveryNote.company_short,
						producer: deliveryNote.producer,
						deliverer_no: Number(deliveryNote.deliverer_no),
						ggn_no: deliveryNote.ggn_no,
						delivery_notes_date: deliveryNote.delivery_notes_date,
						signature_employee: deliveryNote.signature_employee,
						signature_carrier: deliveryNote.signature_carrier,
						destination: deliveryNote.destination,
						consignment_number: deliveryNote.consignment_number,
						delivery_notes_items: items ? items : null,
						delivery_notes_pallets: pallets ? pallets : null,
						delivery_notes_delivered_boxes: delivered_boxes ? delivered_boxes : null,
						delivery_notes_returned_boxes: returned_boxes ? returned_boxes : null,
						carrier: deliveryNote.carrier,
						is_canceled: false,
						is_deleted: false,
					},
				])
				.single()
				.then(handleSupabaseError)
				.then(({ data }) => data),
		{
			enabled: !!deliveryNote,
		}
	);
};

export default useAddDeliveryNote;

const handleSupabaseError = ({ error, ...rest }) => {
	if (error) {
		throw error;
	}
	return rest;
};
