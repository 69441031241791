import { FC, useState, useContext } from 'react';
import AdminLayout from '../layouts/AdminLayout';
import { UserContext } from '../App';
import Tabs from '../components/Tabs';
import Products from '../components/settings/Products';
import Boxes from '../components/settings/Boxes';
import {
	CubeTransparentIcon,
	TruckIcon,
	UsersIcon,
	AdjustmentsIcon,
	CurrencyEuroIcon,
	ArchiveIcon,
	MapIcon,
	OfficeBuildingIcon,
} from '@heroicons/react/solid';
import Users from '../components/settings/Users';
import Company from '../components/settings/Company';
import System from '../components/settings/System';
import Destinations from '../components/settings/Destinations';
import Carriers from '../components/settings/Carriers';
import Pallets from '../components/settings/Pallets';

const Settings: FC = () => {
	const [page, setPage] = useState<number>(0);
	const { userRole } = useContext(UserContext);

	const [tabs, setTabs] = useState<any>([
		{
			label: 'Produkte',
			pageComponent: <Products />,
			icon: <CubeTransparentIcon className={`mr-2 w-5 h-5  text-gray-400 group-hover:text-gray-500`} />,
		},
		{
			label: 'Kisten',
			pageComponent: <Boxes />,
			icon: <ArchiveIcon className={`mr-2 w-5 h-5  text-gray-400 group-hover:text-gray-500`} />,
		},
		{
			label: 'Paletten',
			pageComponent: <Pallets />,
			icon: <ArchiveIcon className={`mr-2 w-5 h-5  text-gray-400 group-hover:text-gray-500`} />,
		},
		{
			label: 'Bestimmungsorte',
			pageComponent: <Destinations />,
			icon: <MapIcon className={`mr-2 w-5 h-5  text-gray-400 group-hover:text-gray-500`} />,
		},
		{
			label: 'Speditionen',
			pageComponent: <Carriers />,
			icon: <TruckIcon className={`mr-2 w-5 h-5  text-gray-400 group-hover:text-gray-500`} />,
		},
		{
			label: 'Benutzer',
			pageComponent: <Users />,
			icon: <UsersIcon className={`mr-2 w-5 h-5  text-gray-400 group-hover:text-gray-500`} />,
		},
		// {
		// 	label: 'Unternehmen',
		// 	pageComponent: <Company />,
		// 	icon: <OfficeBuildingIcon className={`mr-2 w-5 h-5  text-gray-400 group-hover:text-gray-500`} />,
		// },
		{
			label: 'System',
			pageComponent: <System />,
			icon: <AdjustmentsIcon className={`mr-2 w-5 h-5  text-gray-400 group-hover:text-gray-500`} />,
		},
		// {
		// 	label: 'Tarif',
		// 	pageComponent: <></>,
		// 	icon: <CurrencyEuroIcon className={`mr-2 w-5 h-5  text-gray-400 group-hover:text-gray-500`} />,
		// },
	]);

	if (userRole === 'admin') {
		return (
			<AdminLayout>
				<Tabs tabs={tabs} page={page} setPage={setPage} />
			</AdminLayout>
		);
	}
	return (
		<AdminLayout>
			<h1>Sie haben keine Berechtigung.</h1>
		</AdminLayout>
	);
};

export default Settings;
