import { useContext } from 'react';
import { useQuery } from 'react-query';
import { UserContext } from '../App';
import { supabase } from '../utils/client';

const useEmployees = () => {
	const { acId } = useContext(UserContext);

	return useQuery(
		['employees'],
		async () =>
			await supabase
				.from('employees')
				.select()
				.eq('fk_ac_customer', acId)
				.order('lastname', { ascending: true })
				.then(handleSupabaseError)
				.then(({ data }) => data)
	);
};

export default useEmployees;

const handleSupabaseError = ({ error, ...rest }) => {
	if (error) {
		throw error;
	}
	return rest;
};
