import { useContext } from 'react';
import { useMutation } from 'react-query';
import { supabase } from '../utils/client';
import { UserContext } from '../App';
import moment from 'moment';

const useUpdateSystemData = (systemData) => {
	const { acId } = useContext(UserContext);

	return useMutation(
		['systemData', acId],
		async () =>
			await supabase
				.from('system_data')
				.update([
					{
						producer: systemData.producer,
						deliverer_no: systemData.deliverer_no,
						ggn_no: systemData.ggn_no,
						company_short: systemData.company_short,
						delivery_notes_no_format: systemData.delivery_notes_no_format,
						delivery_notes_no_start: systemData.delivery_notes_no_start,
						updated_at: moment().toISOString(),
						email_config: systemData.email_config,
					},
				])
				.match({ id: systemData.id, fk_ac_customer: acId })
				.then(handleSupabaseError)
				.then(({ data }) => data)
	);
};

export default useUpdateSystemData;

const handleSupabaseError = ({ error, ...rest }) => {
	if (error) {
		throw error;
	}
	return rest;
};
