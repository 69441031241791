import { useContext } from 'react';
import { useQuery } from 'react-query';
import { UserContext } from '../App';
import { supabase } from '../utils/client';

const useSystemData = () => {
	const { acId } = useContext(UserContext);

	return useQuery(
		['systemData', acId],
		async () =>
			await supabase
				.from('system_data')
				.select(
					`   id,
							producer,
							deliverer_no,
							ggn_no,
							company_short,
							delivery_notes_no_format,
							delivery_notes_no_start,
							email_config
				`
				)
				.eq('fk_ac_customer', acId)
				.maybeSingle()
				.then(handleSupabaseError)
				.then(({ data }) => data),
		{
			enabled: !!acId,
			refetchOnWindowFocus: false,
			cacheTime: Infinity,
		}
	);
};

export default useSystemData;

const handleSupabaseError = ({ error, ...rest }: any) => {
	if (error) {
		throw error;
	}
	return rest;
};
