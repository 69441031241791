import { FC } from 'react';

interface Styles {
	upColor?: string;
	downColor?: string;
	width?: number;
	height?: number;
	onClick?: any;
}

const CustomFilterIcon: FC<Styles> = ({
	onClick,
	upColor = 'fill-slate-400',
	downColor = 'fill-slate-400',
	width,
	height,
}) => {
	return (
		<button type='button' onClick={onClick}>
			<svg xmlns='http://www.w3.org/2000/svg' height={height} width={width} viewBox='0 0 20 20'>
				<path
					className={upColor}
					d='M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12z'
				/>
				<path
					className={downColor}
					d='M15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z'
				/>
			</svg>
		</button>
	);
};

export default CustomFilterIcon;
