import { useContext } from 'react';
import { useQuery } from 'react-query';
import { UserContext } from '../App';
import { supabase } from '../utils/client';

const useDeliveryNoteByNo = (delivery_notes_no: any) => {
	const { acId } = useContext(UserContext);

	return useQuery(
		['deliveryNotes', delivery_notes_no, acId],
		async () =>
			await supabase
				.from('delivery_notes')
				.select(
					`   id,
							inserted_at,
							created_by_user,
							delivery_notes_no,
							company_short,
							producer,
							deliverer_no,
							ggn_no,
							delivery_notes_date,
							signature_employee,
							signature_carrier,
							producer,
							deliverer_no,
							ggn_no,
							signature_employee,
							signature_carrier,
							destination,
							delivery_notes_items,
							delivery_notes_pallets,
							delivery_notes_delivered_boxes,
							delivery_notes_returned_boxes,
							carrier
				`
				)
				.eq('fk_ac_customer', acId)
				.eq('delivery_notes_no', delivery_notes_no)
				.single()
				.then(handleSupabaseError)
				.then((data) => data),
		{ enabled: !!acId && !!delivery_notes_no, refetchOnWindowFocus: true }
	);
};

export default useDeliveryNoteByNo;

const handleSupabaseError = ({ error, ...rest }: any) => {
	if (error) {
		throw error;
	}
	return rest;
};
