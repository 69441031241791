import React from 'react';

const Checkbox = ({ register, id }: any) => {
  return (
    <div className="m-20">
      <input type="checkbox" id={id} name={id} className="absolute w-12 h-10 opacity-0" {...register(id)} />
      <div className="flex items-center justify-center flex-shrink-0 w-12 h-10 ">
        <div className="check"></div>
      </div>
    </div>
  );
};

export default Checkbox;
