import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import useAddPallet from '../../hooks/useAddPallet';
import useDeletePallet from '../../hooks/useDeletePallet';
import usePallets from '../../hooks/usePallets';
import Input from '../form/Input';
import Select from '../form/Select';
import useAddInventoryMinimumStock from '../../hooks/useAddInventoryMinimumStock';
import useDeleteInventoryMinimumStock from '../../hooks/useDeleteInventoryMinimumStock';

const Pallets = () => {
	const [pallet, setPallet] = useState<any>(undefined);
	const [palletStock, setPalletStock] = useState<any>(undefined);
	const [palletId, setPalletId] = useState<any>(undefined);
	const pallets = usePallets();
	const addPallet = useAddPallet(pallet);
	const addInventoryMinimumStock = useAddInventoryMinimumStock(palletStock);
	const deletePallet = useDeletePallet(palletId);
	const deleteInventoryMinimumStock = useDeleteInventoryMinimumStock(palletId);

	const {
		register,
		handleSubmit,
		reset,
		control,
		formState: { errors },
	} = useForm();
	const { fields, append } = useFieldArray({
		control,
		name: 'inventory_minimum_stock',
	});

	useEffect(() => {
		if (fields.length === 0) append([''], { shouldFocus: false });
	}, [fields]);

	const onSubmit = (data: any) => {
		setPallet(data);
	};

	useEffect(() => {
		if (pallet) {
			addPallet.mutate();
		}
	}, [pallet]);

	useEffect(() => {
		if (addPallet.isSuccess && pallet) {
			if (pallet.inventory_minimum_stock && pallet.inventory_minimum_stock.length > 0) {
				const newPallet = pallet;
				newPallet.inventory_minimum_stock[0].fk_inventory = addPallet.data.id;
				setPalletStock(newPallet);
			} else {
				reset({ label: null, description: null, default_unit: null, inventory_minimum_stock: undefined });
				setPallet(undefined);
				setPalletStock(undefined);
				pallets.refetch();
			}
		}
	}, [addPallet.isSuccess]);

	useEffect(() => {
		if (palletStock && palletStock.inventory_minimum_stock.length > 0) {
			addInventoryMinimumStock.mutate();
		}
	}, [palletStock]);

	useEffect(() => {
		if (addInventoryMinimumStock.isSuccess) {
			reset({ label: null, description: null, default_unit: null, inventory_minimum_stock: undefined });
			setPallet(undefined);
			pallets.refetch();
		}
	}, [addInventoryMinimumStock.isSuccess]);

	const deleteCurrentPallet = (id: number) => {
		setPalletId(id);
	};
	useEffect(() => {
		if (palletId) {
			if (window.confirm('Soll dieser Eintrag wirklich gelöscht werden?')) {
				deletePallet.mutate();
				deleteInventoryMinimumStock.mutate();
			} else {
				setPalletId(undefined);
			}
		}
	}, [palletId]);

	useEffect(() => {
		if (deletePallet.isSuccess && deleteInventoryMinimumStock.isSuccess) {
			pallets.refetch();
			setPalletId(undefined);
		}
		reset({ label: null, description: null, default_unit: null, inventory_minimum_stock: undefined });
	}, [deletePallet.isSuccess, deleteInventoryMinimumStock.isSuccess]);

	return (
		<>
			<h1 className='text-2xl mb-6'>Paletten</h1>
			<div className='flex justify-between flex-wrap lg:flex-nowrap gap-8'>
				<div className='w-full lg:w-2/5 p-4 border'>
					<form onSubmit={handleSubmit(onSubmit)}>
						<p className='mb-6'>Palette anlegen/bearbeiten</p>
						<Input
							id='label'
							style='flex-1'
							label='Bezeichnung*'
							register={register}
							errors={errors?.label}
							rules={{
								required: { value: true, message: 'Dies ist ein Pflichtfeld' },
							}}
						/>
						<Select
							id='default_unit'
							style='flex-1'
							label='Standardeinheit'
							placeholder='-'
							register={register}
							errors={errors?.default_unit}
							options={[
								{ label: 'Stk', key: 'pc' },
								{ label: 'kg', key: 'kg' },
								{ label: 't', key: 't' },
							]}
						/>
						<Input id='description' style='flex-1' label='Beschreibung' register={register} />
						{fields.map((field, index) => (
							<Input
								key={field.id}
								id={`inventory_minimum_stock.${index}.minimum_stock`}
								style='flex-1'
								label='Mindestbestand'
								register={register}
								// errors={errors?.label}
								// rules={{
								// 	required: { value: true, message: 'Dies ist ein Pflichtfeld' },
								// }}
							/>
						))}
						<div className='flex gap-8'>
							<button type='submit' className='btn btn-primary'>
								Speichern
							</button>
							<button
								type='button'
								className='btn btn-light'
								onClick={(_) => {
									setPallet(undefined);
									reset({ label: null, description: null, default_unit: null, inventory_minimum_stock: undefined });
								}}
							>
								Zurücksetzen
							</button>
						</div>
					</form>
				</div>
				<div className='w-full lg:w-3/5  overflow-x-auto'>
					<table className='ac-table'>
						<thead>
							<tr>
								<th>
									<span>Bezeichnung</span>
								</th>
								<th>
									<span>Beschreibung</span>
								</th>
								<th className='text-right'>
									<span>Mindestbestand</span>
								</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{pallets?.data &&
								pallets?.data?.map((pallet: any, index: number) => (
									<tr key={pallet.label + index.toString()}>
										<td>{pallet.label}</td>
										<td>{pallet.description}</td>
										<td className='text-right'>{pallet?.inventory_minimum_stock[0]?.minimum_stock}</td>
										<td className='w-24'>
											<div className='flex justify-between items-center px-2'>
												<button
													className='hover:scale-110'
													onClick={(_) => {
														reset(pallet);
													}}
												>
													<PencilAltIcon width={24} height={24} className='text-slate-700' />
												</button>
												<button className='hover:scale-110' onClick={(_) => deleteCurrentPallet(pallet.id)}>
													<TrashIcon width={24} height={24} className='text-red-500' />
												</button>
											</div>
										</td>
									</tr>
								))}
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
};

export default Pallets;
