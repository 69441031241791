import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import useAddProduct from '../../hooks/useAddProduct';
import useAddProductSizes from '../../hooks/useAddProductSizes';
import useDeleteProduct from '../../hooks/useDeleteProduct';
import useDeleteProductSize from '../../hooks/useDeleteProductSize';
import useProducts from '../../hooks/useProducts';
import Input from '../form/Input';
import Select from '../form/Select';

const Products = () => {
	const [product, setProduct] = useState<any>(undefined);
	const [productId, setProductId] = useState<any>(undefined);
	const [productSizes, setProductSizes] = useState<any>(undefined);
	const [productSizeId, setProductSizeId] = useState<number | undefined>(undefined);
	const [productSizeIds, setProductSizeIds] = useState<number[] | undefined>(undefined);
	const products = useProducts();
	const addProduct = useAddProduct(product);
	const addProductSizes = useAddProductSizes(productSizes);
	const deleteProduct = useDeleteProduct(productId);
	const deleteProductSize = useDeleteProductSize(productSizeId);
	const {
		register,
		handleSubmit,
		reset,
		control,
		formState: { errors },
	} = useForm();
	const { fields, append } = useFieldArray({
		control,
		name: 'products_sizes',
	});

	const onSubmit = (data: any) => {
		setProduct(data);
	};

	useEffect(() => {
		if (product) addProduct.mutate();
	}, [product]);

	useEffect(() => {
		if (productSizes) {
			addProductSizes.mutate();
		}
	}, [productSizes]);

	useEffect(() => {
		if (productSizeId) {
			deleteProductSize.mutate();
		}
	}, [productSizeId]);

	useEffect(() => {
		if (addProduct.isSuccess) {
			if (product?.products_sizes) {
				const sizesToDelete: number[] = [];
				const hasSizes = product?.products_sizes?.map((size: any) => {
					if (size.id && !size.label) sizesToDelete.push(size.id);
					if (size.label) return true;
					return false;
				});
				setProductSizeIds(sizesToDelete);
				if (hasSizes.includes(true)) {
					Object.assign(product, { id: addProduct.data.id });
					setProductSizes(product);
				} else {
					reset({ label: null, description: null, default_unit: null, products_sizes: undefined });
					setProduct(undefined);
					setProductSizes(undefined);
					products.refetch();
				}
			} else {
				reset({ label: null, description: null, default_unit: null, products_sizes: undefined });
				setProduct(undefined);
				setProductSizes(undefined);
				products.refetch();
			}
		}
	}, [addProduct.isSuccess]);

	useEffect(() => {
		if (productSizeIds && productSizeIds.length > 0) {
			setProductSizeId(productSizeIds[0]);
			const idArr = productSizeIds;
			idArr.shift();
			setProductSizeIds(idArr);
		}
	}, [productSizeIds]);

	useEffect(() => {
		if (deleteProductSize.isSuccess) {
			if (productSizeIds && productSizeIds.length > 0) {
				setProductSizeId(productSizeIds[0]);
				const idArr = productSizeIds;
				idArr.shift();
				setProductSizeIds(idArr);
			} else {
				products.refetch();
			}
		}
	}, [deleteProductSize.isSuccess]);

	useEffect(() => {
		if (addProductSizes.isSuccess) {
			reset({ label: null, description: null, default_unit: null, products_sizes: undefined });
			setProduct(undefined);
			setProductSizes(undefined);
			products.refetch();
		}
	}, [addProductSizes.isSuccess]);

	const deleteCurrentProduct = (id: number) => {
		setProductId(id);
	};
	useEffect(() => {
		if (productId) {
			if (window.confirm('Soll dieser Eintrag wirklich gelöscht werden?')) {
				deleteProduct.mutate();
			} else {
				setProductId(undefined);
			}
		}
	}, [productId]);

	useEffect(() => {
		if (deleteProduct.isSuccess) {
			products.refetch();
			setProductId(undefined);
		}
		reset({ label: null, description: null, default_unit: null, products_sizes: undefined });
	}, [deleteProduct.isSuccess]);

	return (
		<>
			<h1 className='text-2xl mb-6'>Produkte &#38; Größen</h1>
			<div className='flex justify-between flex-wrap lg:flex-nowrap gap-8'>
				<div className='w-full lg:w-2/5 p-4 border'>
					<form onSubmit={handleSubmit(onSubmit)}>
						<p className='mb-6'>Produkt anlegen/bearbeiten</p>
						<Input
							id='label'
							style='flex-1'
							label='Bezeichnung*'
							register={register}
							errors={errors?.label}
							rules={{
								required: { value: true, message: 'Dies ist ein Pflichtfeld' },
							}}
						/>
						<Select
							id='default_unit'
							style='flex-1'
							label='Standardeinheit'
							placeholder='-'
							register={register}
							errors={errors?.default_unit}
							options={[
								{ label: 'Stk', key: 'pc' },
								{ label: 'kg', key: 'kg' },
								{ label: 't', key: 't' },
							]}
						/>
						<Input id='description' style='flex-1' label='Beschreibung' register={register} />
						{fields.map((field, index) => (
							<Input
								key={field.id}
								id={`products_sizes.${index}.label`}
								style='flex-1'
								label='Größe'
								register={register}
							/>
						))}
						<button
							type='button'
							className='text-slate-700 flex items-center mb-8'
							onClick={() =>
								append([''], {
									shouldFocus: true,
									focusIndex: fields.length,
									focusName: `products_sizes.${fields.length}.label`,
								})
							}
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								className='h-5 w-5 mr-2'
								fill='none'
								viewBox='0 0 24 24'
								stroke='currentColor'
								strokeWidth={2}
							>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									d='M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z'
								/>
							</svg>
							Größe hinzufügen
						</button>
						<div className='flex gap-8'>
							<button type='submit' className='btn btn-primary'>
								Speichern
							</button>
							<button
								type='button'
								className='btn btn-light'
								onClick={(_) => {
									setProduct(undefined);
									setProductSizes(undefined);
									reset({ label: null, description: null, default_unit: null, products_sizes: undefined });
								}}
							>
								Zurücksetzen
							</button>
						</div>
					</form>
				</div>
				<div className='w-full lg:w-3/5  overflow-x-auto'>
					<table className='ac-table'>
						<thead>
							<tr>
								<th>
									<span>Bezeichnung</span>
								</th>
								<th>
									<span>Beschreibung</span>
								</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{products?.data &&
								products?.data.map((product: any, index: number) => (
									<tr key={product.label + index.toString()}>
										<td>{product.label}</td>
										<td>{product.description}</td>
										<td className='w-24'>
											<div className='flex justify-between items-center px-2'>
												<button
													className='hover:scale-110'
													onClick={(_) => {
														reset(product);
													}}
												>
													<PencilAltIcon width={24} height={24} className='text-slate-700' />
												</button>
												<button className='hover:scale-110' onClick={(_) => deleteCurrentProduct(product.id)}>
													<TrashIcon width={24} height={24} className='text-red-500' />
												</button>
											</div>
										</td>
									</tr>
								))}
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
};

export default Products;
