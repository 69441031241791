import React from 'react';
import { useEffect, useState } from 'react';
import moment from 'moment';

const TimeTrackingDay = () => {
	return (
		<div>
			<p className='mb-6 font-bold text-lg'>Donnerstag, 10.02.2022</p>
			<table className='w-full '>
				<thead>
					<tr className='bg-slate-100 text-left '>
						<th className='py-2 px-3'>Mitarbeiter-Nr.</th>
						<th className='py-2 px-3'>Name</th>
						<th className='py-2 px-3'>Vorname</th>
						<th className='py-2 px-3'>Buchungstyp</th>
						<th className='py-2 px-3'>Start</th>
						<th className='py-2 px-3'>Ende</th>
						<th className='py-2 px-3'>Total</th>
					</tr>
				</thead>
				<tbody>
					<tr className=' divide-x-2 border-b-2'>
						<td className='py-2 px-3'>175</td>
						<td className='py-2 px-3'>Kolbow</td>
						<td className='py-2 px-3'>Christian</td>
						<td className='py-2 px-3'>
							<p className='px-3 py-1 bg-emerald-200 rounded-full mb-2'>Arbeiten</p>
							<p className='px-3 py-1 bg-sky-200 rounded-full mb-2'>Pause</p>
							<p className='px-3 py-1 bg-emerald-200 rounded-full mb-2'>Arbeiten</p>
							<p className='px-3 py-1 bg-emerald-200 rounded-full'>Arbeiten</p>
						</td>
						<td className='py-2 px-3'>
							<p className='px-3 py-1 bg-emerald-200 rounded-full mb-2'>08:14</p>
							<p className='px-3 py-1 bg-sky-200 rounded-full mb-2'>12:45</p>
							<p className='px-3 py-1 bg-emerald-200 rounded-full mb-2'>13:12</p>
							<p className='px-3 py-1 bg-emerald-200 rounded-full'>18:53</p>
						</td>
						<td className='py-2 px-3'>
							<p className='px-3 py-1 bg-emerald-200 rounded-full mb-2'>12:45</p>
							<p className='px-3 py-1 bg-sky-200 rounded-full mb-2'>13:12</p>
							<p className='px-3 py-1 bg-emerald-200 rounded-full mb-2'>16:28</p>
							<p className='px-3 py-1 bg-emerald-200 rounded-full'>19:53</p>
						</td>
						<td className='py-2 px-3'>8,78 Std.</td>
					</tr>
					<tr className=' divide-x-2 border-b-2'>
						<td className='py-2 px-3'>111</td>
						<td className='py-2 px-3'>Milz</td>
						<td className='py-2 px-3'>Barbara</td>
						<td className='py-2 px-3'>
							<p className='px-3 py-1 bg-emerald-200 rounded-full mb-2'>Arbeiten</p>
							<p className='px-3 py-1 bg-sky-200 rounded-full mb-2'>Pause</p>
							<p className='px-3 py-1 bg-emerald-200 rounded-full'>Arbeiten</p>
						</td>
						<td className='py-2 px-3'>
							<p className='px-3 py-1 bg-emerald-200 rounded-full mb-2'>08:03</p>
							<p className='px-3 py-1 bg-sky-200 rounded-full mb-2'>12:31</p>
							<p className='px-3 py-1 bg-emerald-200 rounded-full'>12:59</p>
						</td>
						<td className='py-2 px-3'>
							<p className='px-3 py-1 bg-emerald-200 rounded-full mb-2'>12:31</p>
							<p className='px-3 py-1 bg-sky-200 rounded-full mb-2'>12:59</p>
							<p className='px-3 py-1 bg-emerald-200 rounded-full'>16:11</p>
						</td>
						<td className='py-2 px-3'>7,67 Std.</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default TimeTrackingDay;
