import { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { supabase } from '../utils/client';
import { ExperimentalContext, UserContext } from '../App';
import { useAuth } from '../provider/AuthProvider';
import { QuestionMarkCircleIcon } from '@heroicons/react/solid';

const AdminNav = () => {
	const { experimental } = useContext(ExperimentalContext);
	const { userRole, firstname, lastname, setAcId, setFirstname, setLastname, setUserRole } = useContext(UserContext);
	const { user } = useAuth();

	const logout = async () => {
		await supabase.auth.signOut();
		setAcId(undefined);
		setFirstname('');
		setLastname('');
		setUserRole(undefined);
	};

	return (
		<div className='bg-slate-700 text-white fixed w-full z-50'>
			<nav className='container px-4 flex items-center justify-between'>
				<ul className='flex items-center gap-8'>
					{/* <li>
						<Link to={'/employees'}>
							<div className='py-4'>Mitarbeiter</div>
						</Link>
					</li> */}
					{/* <li>
						<Link to={'/timetracking'}>
							<div className='py-4'>Zeiterfassung</div>
						</Link>
					</li> */}
					{/* <li>
						<Link to={'/bonus'}>
							<div className='py-4'>Bonus</div>
						</Link>
					</li> */}

					<li>
						<NavLink
							to={'/merchandise-management'}
							className={({ isActive }) =>
								isActive
									? 'underline underline-offset-4 decoration-solid font-semibold text-lg'
									: 'hover:text-slate-300 font-semibold text-lg'
							}
						>
							<div className='py-5'>Warenwirtschaft</div>
						</NavLink>
					</li>
					{/* <li>
						<Link to={'/resource-planning'}>
							<div className='py-4'>Ressourcenplanung</div>
						</Link>
					</li> */}
					{/* <li>
						<Link to={'/field-index'}>
							<div className='py-4'>Feldkalender</div>
						</Link>
					</li> */}

					{experimental && (
						<li>
							<NavLink
								to={'/employees'}
								className={({ isActive }) =>
									isActive
										? 'underline underline-offset-4 decoration-solid font-semibold text-lg'
										: 'hover:text-slate-300 font-semibold text-lg'
								}
							>
								<div className='py-5'>Mitarbeiter</div>
							</NavLink>
						</li>
					)}
					{experimental && (
						<li>
							<NavLink
								to={'/timetracking'}
								className={({ isActive }) =>
									isActive
										? 'underline underline-offset-4 decoration-solid font-semibold text-lg'
										: 'hover:text-slate-300 font-semibold text-lg'
								}
							>
								<div className='py-5'>Zeiterfassung</div>
							</NavLink>
						</li>
					)}
					{experimental && (
						<li>
							<NavLink
								to={'/bonus'}
								className={({ isActive }) =>
									isActive
										? 'underline underline-offset-4 decoration-solid font-semibold text-lg'
										: 'hover:text-slate-300 font-semibold text-lg'
								}
							>
								<div className='py-5'>Bonus</div>
							</NavLink>
						</li>
					)}
					{experimental && (
						<li>
							<NavLink
								to={'/forms'}
								className={({ isActive }) =>
									isActive
										? 'underline underline-offset-4 decoration-solid font-semibold text-lg'
										: 'hover:text-slate-300 font-semibold text-lg'
								}
							>
								<div className='py-5'>Formulare</div>
							</NavLink>
						</li>
					)}
					{experimental && (
						<li>
							<NavLink
								to={'/documents'}
								className={({ isActive }) =>
									isActive
										? 'underline underline-offset-4 decoration-solid font-semibold text-lg'
										: 'hover:text-slate-300 font-semibold text-lg'
								}
							>
								<div className='py-5'>Dokumente</div>
							</NavLink>
						</li>
					)}
					{userRole === 'admin' && (
						<li>
							<NavLink
								to={'/settings'}
								className={({ isActive }) =>
									isActive
										? 'underline underline-offset-4 decoration-solid font-semibold text-lg'
										: 'hover:text-slate-300 font-semibold text-lg'
								}
							>
								<div className='py-5'>Einstellungen</div>
							</NavLink>
						</li>
					)}
					{userRole === 'admin' && (
						<li>
							<a href='/docs' target='_blank' className='py-5 hover:text-slate-300 font-semibold text-lg'>
								<QuestionMarkCircleIcon width={22} />
							</a>
						</li>
					)}
				</ul>
				<button onClick={logout} className='flex items-center gap-4' title='Ausloggen!'>
					<span>{firstname || lastname ? `${firstname} ${lastname}` : user?.email}</span>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						className='h-6 w-6'
						fill='none'
						viewBox='0 0 24 24'
						stroke='currentColor'
						strokeWidth={2}
					>
						<path
							strokeLinecap='round'
							strokeLinejoin='round'
							d='M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1'
						/>
					</svg>
				</button>
			</nav>
		</div>
	);
};

export default AdminNav;
