import { useMutation } from 'react-query';
import { supabase } from '../utils/client';

const useMoveFile = () => {
	return useMutation(['move-file'], File);
};

export default useMoveFile;

const File = async (paths) => {
	return await supabase.storage
		.from('user-files')
		.move(paths.from, paths.to)
		.then(handleSupabaseError)
		.then(({ data }) => data);
};

const handleSupabaseError = ({ error, ...rest }) => {
	if (error) {
		throw error;
	}
	return rest;
};
