import { FC, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "./Pagination";
import moment from "moment";
import {
  DocumentDownloadIcon,
  PencilAltIcon,
  PrinterIcon,
  TrashIcon,
  RefreshIcon,
  InformationCircleIcon,
  ReceiptRefundIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import useDeleteDeliveryNote from "../hooks/useDeleteDeliveryNote";
import useUpdateDeliveryNote from "../hooks/useUpdateDeliveryNote";
import { MerchandiseContext, UserContext } from "../App";
import StyledInput from "./form/StyledInput";
import CustomFilterIcon from "./CustomFilterIcon";
import { Tooltip } from "./Tooltip";
import useDeliveryNotes from "../hooks/useDeliveryNotes";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import { useAuth } from "../provider/AuthProvider";
import useDeleteStockByDeliveryNoteId from "../hooks/useDeleteStockByDeliveryNoteId";

interface Note {
  id: number;
  inserted_at: string;
  company_short: string;
  deliverer_no: number;
  delivery_notes_date: string;
  delivery_notes_no: number;
  ggn_no: string;
  producer: string;
  signature_carrier: string;
  signature_employee: string;
  delivery_notes_items: any[];
  delivery_notes_pallets: any;
  delivery_notes_delivered_boxes: any[];
  delivery_notes_returned_boxes: any[];
  destination: string;
  consignment_number: string;
  carrier: string;
  is_canceled: boolean;
  is_deleted: boolean;
}

const Receipts: FC<any> = ({ setTab }) => {
  const {
    receiptDateFrom,
    receiptDateTo,
    receiptSearch,
    setReceiptOrder,
    setReceiptDateFrom,
    setReceiptDateTo,
    setReceiptSearch,
    receiptSearchInput,
    setReceiptSearchInput,
    receiptsPage,
    setReceiptsPage,
    receiptOrder,
    receiptAscending,
    setReceiptAscending,
  } = useContext(MerchandiseContext);
  const [receiptsPerPage, setReceiptsPerPage] = useState<number>(10);

  const { acId } = useContext(UserContext);
  const { user } = useAuth();

  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const deliveryNotes = useDeliveryNotes(
    receiptsPage,
    receiptsPerPage,
    receiptSearch,
    receiptOrder,
    receiptAscending,
    receiptDateFrom,
    receiptDateTo
  );
  const [totalElements, setTotalElements] = useState<number>(0);
  const [deliveryNoteToUpdate, setDeliveryNoteToUpdate] = useState<any>({
    id: undefined,
    user: undefined,
    is_canceled: false,
    is_deleted: false,
  });
  const updateDeliveryNote = useUpdateDeliveryNote(deliveryNoteToUpdate);
  const deleteStocksByDeliveryNoteId = useDeleteStockByDeliveryNoteId(
    deliveryNoteToUpdate.id
  );

  const deleteCurrentDeliveryNote = (id: number, noteNo: number) => {
    if (
      window.confirm(
        'Soll der Lieferschein mit der Nummer "' +
          noteNo.toString() +
          '" wirklich gelöscht werden?'
      )
    ) {
      setDeliveryNoteToUpdate({
        id: id,
        user: user,
        is_deleted: true,
        is_canceled: false,
      });
    }
  };
  const cancelCurrentDeliveryNote = (id: number, noteNo: number) => {
    if (
      window.confirm(
        'Soll der Lieferschein mit der Nummer "' +
          noteNo.toString() +
          '" wirklich storniert werden?'
      )
    ) {
      setDeliveryNoteToUpdate({
        id: id,
        user: user,
        is_deleted: false,
        is_canceled: true,
      });
    }
  };

  useEffect(() => {
    if (deliveryNotes.data) {
      setTotalElements(deliveryNotes?.data?.count || 0);
    }
  }, [deliveryNotes.data]);

  useEffect(() => {
    if (!receiptSearchInput) return setReceiptSearch(null);
    const handler = setTimeout(() => {
      if (receiptSearchInput.length >= 1) {
        setReceiptSearch(receiptSearchInput);
        setReceiptsPage(0);
      } else {
        setReceiptSearch(null);
        setReceiptsPage(0);
      }
    }, 750);
    return () => {
      clearTimeout(handler);
    };
  }, [receiptSearchInput]);

  useEffect(() => {
    if (deliveryNoteToUpdate.id && deliveryNoteToUpdate.user) {
      updateDeliveryNote.mutate();
      deleteStocksByDeliveryNoteId.mutate();
    }
  }, [deliveryNoteToUpdate]);

  useEffect(() => {
    if (updateDeliveryNote.isSuccess) {
      setDeliveryNoteToUpdate({
        id: undefined,
        user: undefined,
        is_canceled: false,
        is_deleted: false,
      });
      deliveryNotes.refetch();
    }
  }, [updateDeliveryNote.isSuccess]);

  const handleFilter = (label: string) => {
    setReceiptOrder(label);
    if (label === receiptOrder) {
      setReceiptAscending(!receiptAscending);
      setReceiptsPage(0);
    } else {
      setReceiptAscending(false);
      setReceiptsPage(0);
    }
  };

  const handleSetDate = () => {
    if (startDate) {
      setReceiptDateFrom(startDate);
      setReceiptsPage(0);
    }
    if (endDate) {
      setReceiptDateTo(endDate);
      setReceiptsPage(0);
    }
  };

  const resetAllFilters = () => {
    setReceiptSearch(undefined);
    setReceiptSearchInput(null);
    setReceiptDateFrom("2022-01-01");
    setStartDate("2022-01-01");
    setReceiptDateTo(moment().format("YYYY") + "-12-31");
    setEndDate(moment().format("YYYY") + "-12-31");
    setReceiptsPage(0);
  };

  return (
    <div className="py-12">
      <div className="flex flex-wrap items-center gap-x-8 ">
        <Link to={"/delivery-note"} className=" mb-8">
          <button className="btn btn-primary flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 mr-3"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                clipRule="evenodd"
              />
            </svg>
            Lieferschein
          </button>
        </Link>
        <StyledInput
          value={receiptSearchInput || ""}
          label="Filter"
          id="filter"
          placeholder="Filter"
          isFilled={receiptSearchInput ? true : false}
          onChange={(e: any) => setReceiptSearchInput(e.target.value)}
        />
        <StyledInput
          id="date-from"
          label="Von:"
          type="date"
          value={startDate || "2022-01-01"}
          isFilled={startDate ? true : false}
          onChange={(e: any) => setStartDate(e.target.value)}
        />
        <StyledInput
          id="date-to"
          label="Bis:"
          type="date"
          value={endDate || moment().format("YYYY") + "-12-31"}
          isFilled={endDate ? true : false}
          onChange={(e: any) => setEndDate(e.target.value)}
        />
        <button className="btn btn-primary mb-8" onClick={handleSetDate}>
          <RefreshIcon width={20} />
        </button>
        <button className="btn btn-light mb-8" onClick={resetAllFilters}>
          Filter zurücksetzen
        </button>
        {/* <button className='btn btn-light mb-8 flex items-center gap-2' onClick={(_) => setTab(3)}>
					<TrashIcon width={20} /> Papierkorb
				</button> */}
      </div>
      <div className="overflow-visible">
        <table className="ac-table">
          <thead>
            <tr>
              <th
                className="cursor-pointer"
                onClick={() => handleFilter("delivery_notes_no")}
              >
                <div className="flex justify-between items-center">
                  <span>Beleg-Nr.</span>
                  <CustomFilterIcon
                    width={20}
                    upColor={
                      receiptOrder === "delivery_notes_no" && receiptAscending
                        ? "fill-white"
                        : undefined
                    }
                    downColor={
                      receiptOrder === "delivery_notes_no" && !receiptAscending
                        ? "fill-white"
                        : undefined
                    }
                  />
                </div>
              </th>
              <th
                className="cursor-pointer"
                onClick={() => handleFilter("consignment_number")}
              >
                <div className="flex justify-between items-center">
                  <span>Bestell-Nr.</span>
                  <CustomFilterIcon
                    width={20}
                    upColor={
                      receiptOrder === "consignment_number" && receiptAscending
                        ? "fill-white"
                        : undefined
                    }
                    downColor={
                      receiptOrder === "consignment_number" && !receiptAscending
                        ? "fill-white"
                        : undefined
                    }
                  />
                </div>
              </th>
              <th
                className="cursor-pointer"
                onClick={() => handleFilter("delivery_notes_date")}
              >
                <div className="flex justify-between items-center">
                  <span>Lieferdatum</span>
                  <CustomFilterIcon
                    width={20}
                    upColor={
                      receiptOrder === "delivery_notes_date" && receiptAscending
                        ? "fill-white"
                        : undefined
                    }
                    downColor={
                      receiptOrder === "delivery_notes_date" &&
                      !receiptAscending
                        ? "fill-white"
                        : undefined
                    }
                  />
                </div>
              </th>
              <th>
                <span>Produkte</span>
              </th>
              <th
                className="cursor-pointer"
                onClick={() => handleFilter("destination")}
              >
                <div className="flex justify-between items-center">
                  <span>Bestimmungsort</span>
                  <CustomFilterIcon
                    width={20}
                    upColor={
                      receiptOrder === "destination" && receiptAscending
                        ? "fill-white"
                        : undefined
                    }
                    downColor={
                      receiptOrder === "destination" && !receiptAscending
                        ? "fill-white"
                        : undefined
                    }
                  />
                </div>
              </th>
              <th
                className="cursor-pointer"
                onClick={() => handleFilter("carrier")}
              >
                <div className="flex justify-between items-center">
                  <span>Spedition</span>
                  <CustomFilterIcon
                    width={20}
                    upColor={
                      receiptOrder === "carrier" && receiptAscending
                        ? "fill-white"
                        : undefined
                    }
                    downColor={
                      receiptOrder === "carrier" && !receiptAscending
                        ? "fill-white"
                        : undefined
                    }
                  />
                </div>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {deliveryNotes.data &&
              deliveryNotes.data?.data?.map((note: Note, index: number) => {
                if (!note.is_deleted)
                  return (
                    <tr
                      key={index}
                      className={note.is_canceled ? "!text-slate-400" : ""}
                    >
                      <td>{note.delivery_notes_no}</td>
                      <td>{note.consignment_number}</td>
                      <td>
                        {moment(note.delivery_notes_date).format("DD.MM.YYYY")}
                      </td>
                      <td>
                        {note?.delivery_notes_items?.map(
                          (item: any, index: number) => {
                            const obj =
                              typeof item === "string"
                                ? JSON.parse(item)
                                : item;
                            return (
                              obj.product_label +
                              (note?.delivery_notes_items.length > index + 1
                                ? ", "
                                : "")
                            );
                          }
                        )}
                      </td>
                      <td>{note?.destination}</td>
                      <td>{note?.carrier}</td>
                      <td className="w-32">
                        <div className="px-2 flex items-center gap-4 justify-end">
                          {!note.is_canceled && (
                            <>
                              <a
                                href={`/api/createDeliveryNotePdf?note_no=${note.id}&acid=${acId}`}
                                target="_blank"
                                className="hover:scale-110"
                              >
                                <PrinterIcon
                                  width={24}
                                  height={24}
                                  className="text-slate-700"
                                />
                              </a>
                              <Link
                                to="/delivery-note"
                                state={note}
                                className="hover:scale-110"
                              >
                                <PencilAltIcon
                                  width={24}
                                  height={24}
                                  className="text-slate-700"
                                />
                              </Link>
                            </>
                          )}
                          {note.is_canceled && (
                            <span className="italic text-amber-500">
                              storniert
                            </span>
                          )}
                          <div className="relative group flex items-center">
                            <button className="hover:scale-110">
                              <DotsVerticalIcon
                                width={24}
                                height={24}
                                className="text-slate-700"
                              />
                            </button>
                            <div className="absolute z-40 w-max top-6 right-0 shadow-lg shadow-slate-700/30 bg-white hidden group-hover:block border-slate-200 border-2">
                              <ul>
                                <li className=" hover:bg-slate-100">
                                  <Link
                                    to="/delivery-note"
                                    state={note}
                                    className=" flex items-center gap-2 cursor-pointer py-2 px-4 "
                                  >
                                    <PencilAltIcon
                                      width={24}
                                      height={24}
                                      className="text-slate-700"
                                    />
                                    <span>Öffnen</span>
                                  </Link>
                                </li>
                                <li className=" hover:bg-slate-100">
                                  <a
                                    href={`/api/createDeliveryNotePdf?note_no=${note.id}&acid=${acId}`}
                                    target="_blank"
                                    className=" flex items-center gap-2 cursor-pointer py-2 px-4 "
                                  >
                                    <PrinterIcon
                                      width={24}
                                      height={24}
                                      className="text-slate-700"
                                    />
                                    <span>Drucken</span>
                                  </a>
                                </li>
                                <li className=" hover:bg-slate-100">
                                  <a
                                    href={`/api/createDeliveryNotePdf?note_no=${
                                      note.id
                                    }&acid=${acId}&dl=${true}`}
                                    target="_blank"
                                    className=" flex items-center gap-2 cursor-pointer py-2 px-4 "
                                  >
                                    <DocumentDownloadIcon
                                      width={24}
                                      height={24}
                                      className="text-slate-700"
                                    />
                                    <span>Download</span>
                                  </a>
                                </li>
                                <li className="hover:bg-slate-100">
                                  <a
                                    className=" flex items-center gap-2 cursor-pointer py-2 px-4 "
                                    onClick={(_) =>
                                      cancelCurrentDeliveryNote(
                                        note.id,
                                        note.delivery_notes_no
                                      )
                                    }
                                  >
                                    <XCircleIcon
                                      width={24}
                                      height={24}
                                      className="text-red-500"
                                    />
                                    <span>Storno</span>
                                  </a>
                                </li>
                                <li className=" hover:bg-slate-100">
                                  <a
                                    className=" flex items-center gap-2 cursor-pointer py-2 px-4 "
                                    onClick={(_) =>
                                      deleteCurrentDeliveryNote(
                                        note.id,
                                        note.delivery_notes_no
                                      )
                                    }
                                  >
                                    <TrashIcon
                                      width={24}
                                      height={24}
                                      className="text-red-500"
                                    />
                                    <span>Löschen</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
              })}
            {!deliveryNotes.data &&
              Array.from({ length: receiptsPerPage }).map((_, index) => (
                <tr key={index}>
                  <td className="px-2">
                    <div className="animate-pulse">
                      <div className="h-4 bg-gray-200 rounded"></div>
                    </div>
                  </td>
                  <td className="px-2">
                    <div className="animate-pulse">
                      <div className="h-4 bg-gray-200 rounded"></div>
                    </div>
                  </td>
                  <td className="px-2">
                    <div className="animate-pulse">
                      <div className="h-4 bg-gray-200 rounded"></div>
                    </div>
                  </td>
                  <td className="px-2">
                    <div className="animate-pulse">
                      <div className="h-4 bg-gray-200 rounded"></div>
                    </div>
                  </td>
                  <td className="px-2">
                    <div className="animate-pulse">
                      <div className="h-4 bg-gray-200 rounded"></div>
                    </div>
                  </td>
                  <td className="px-2">
                    <div className="animate-pulse">
                      <div className="h-4 bg-gray-200 rounded"></div>
                    </div>
                  </td>
                  <td className="w-32">
                    <div className="px-2 flex items-center gap-4 justify-end">
                      <PrinterIcon
                        width={24}
                        height={24}
                        className="text-gray-400"
                      />
                      {/* <DocumentDownloadIcon width={24} height={24} className='text-gray-400' /> */}
                      <PencilAltIcon
                        width={24}
                        height={24}
                        className="text-gray-400"
                      />
                      {/* <TrashIcon width={24} height={24} className='text-gray-400' /> */}
                      <DotsVerticalIcon
                        width={24}
                        height={24}
                        className="text-gray-400"
                      />
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
          <tfoot className="border">
            <tr>
              <td colSpan={7}>
                <Pagination
                  count={
                    deliveryNotes.data ? deliveryNotes.data?.data?.length : 0
                  }
                  totalElements={totalElements}
                  setPage={setReceiptsPage}
                  page={receiptsPage}
                  rowsPerPage={receiptsPerPage}
                  setRowsPerPage={setReceiptsPerPage}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default Receipts;
