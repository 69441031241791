import { FC } from "react";

interface Input {
  label: string;
  placeholder?: string;
  id: string;
  type?: string;
  style?: string;
  rules?: any;
  dataList?: any[];
  listname?: string;
  register: any;
  autofocus?: boolean;
  errors?: any;
  readonly?: boolean;
  disabled?: boolean;
  defaultValue?: string;
  onFocus?: any;
  onChange?: any;
}

const Input: FC<Input> = ({
  errors,
  label,
  placeholder,
  id,
  type,
  style,
  dataList,
  listname,
  register,
  autofocus,
  rules,
  readonly,
  disabled,
  defaultValue,
  onFocus,
  onChange,
}) => {
  return (
    <fieldset className={`relative standard-input ${style || ""}`}>
      <span className={dataList && "datalist-arrow"}>
        {onChange && (
          <input
            {...register(id, rules)}
            readOnly={readonly}
            disabled={disabled}
            list={listname || ""}
            defaultValue={defaultValue}
            className={`${
              errors?.message && "border-red-600 focus-within:!border-red-400"
            } peer ${dataList && "hide-indicator"}`}
            type={type || "text"}
            name={id}
            id={id}
            placeholder={placeholder || label}
            autoComplete="off"
            autoFocus={autofocus || false}
            onFocus={onFocus || null}
            onChange={onChange}
          />
        )}
        {!onChange && (
          <input
            {...register(id, rules)}
            readOnly={readonly}
            disabled={disabled}
            list={listname || ""}
            defaultValue={defaultValue}
            className={`${
              errors?.message && "border-red-600 focus-within:!border-red-400"
            } peer ${dataList && "hide-indicator"}`}
            type={type || "text"}
            name={id}
            id={id}
            placeholder={placeholder || label}
            autoComplete="off"
            autoFocus={autofocus || false}
            onFocus={onFocus || null}
          />
        )}
        <label
          className={`${
            errors?.message && "!text-red-600"
          } standard-input-label`}
          htmlFor={id}
        >
          {label}
        </label>
      </span>
      {dataList && (
        <datalist id={listname}>
          {dataList.map((item, index) => (
            <option key={item.key + "-" + index} value={item.label}>
              {item.label}
            </option>
          ))}
        </datalist>
      )}
      {errors?.message && (
        <span className="absolute text-red-500 bottom-3.5 left-2.5 z-50 text-sm">
          {errors?.message}
        </span>
      )}
    </fieldset>
  );
};

export default Input;
