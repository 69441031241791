import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Input from "../form/Input";
import useSystemData from "../../hooks/useSystemData";
import useUpdateSystemData from "../../hooks/useUpdateSystemData";
import { toast } from "react-toastify";
import { ExperimentalContext } from "../../App";

const System = () => {
  const { experimental, setExperimental } = useContext(ExperimentalContext);
  const [formData, setFormData] = useState<any>(undefined);
  const systemData = useSystemData();
  const updateSystemData = useUpdateSystemData(formData);
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (systemData.isSuccess) {
      reset(systemData?.data);
    }
  }, [systemData.isSuccess]);

  const onSubmit = (data: any) => {
    setFormData(data);
  };

  useEffect(() => {
    if (formData) updateSystemData.mutate();
  }, [formData]);

  useEffect(() => {
    if (updateSystemData.isSuccess) {
      systemData.refetch();
      toast.success("Die Daten wurden gespeichert", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [updateSystemData.isSuccess]);

  useEffect(() => {
    if (updateSystemData.isError) {
      toast.error("Da ist etwas schiefgelaufen!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [updateSystemData.isError]);

  return (
    <>
      <h1 className="mb-6 text-2xl">System</h1>
      <div className="">
        <div className="w-full max-w-md p-4 border">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Input
              id="producer"
              style="flex-1"
              label="Produzent"
              register={register}
            />
            <Input
              id="deliverer_no"
              style="flex-1"
              label="Anlieferer Nr."
              register={register}
            />
            <Input
              id="ggn_no"
              style="flex-1"
              label="GGN Nr."
              register={register}
            />
            <Input
              id="company_short"
              style="flex-1"
              label="Betriebsname/Kürzel"
              register={register}
            />
            <Input
              id="delivery_notes_no_start"
              style="flex-1"
              label="Lieferscheinnummer Startnummer"
              register={register}
            />
            {/* <div className='mb-8'>
							<fieldset className='mb-2'>
								<label className='inline-block form-check-label text-slate-700' htmlFor='format1'>
									<input
										{...register('delivery_notes_no_format', { required: true })}
										value={0}
										className='float-left w-4 h-4 mt-1 mr-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border border-gray-300 rounded-full appearance-none cursor-pointer form-check-input checked:bg-slate-700 checked:border-slate-700 focus:outline-none'
										type='radio'
										name='delivery_notes_no_format'
										id='format1'
									/>
									Nummer <span className='text-slate-400'>(10000, 10001, 10002,...)</span>
								</label>
							</fieldset>
							<fieldset className='mb-2'>
								<label className='inline-block form-check-label text-slate-700' htmlFor='format2'>
									<input
										{...register('delivery_notes_no_format', { required: true })}
										value={1}
										className='float-left w-4 h-4 mt-1 mr-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border border-gray-300 rounded-full appearance-none cursor-pointer form-check-input checked:bg-slate-700 checked:border-slate-700 focus:outline-none'
										type='radio'
										name='delivery_notes_no_format'
										id='format2'
									/>
									Jahr/Nummer <span className='text-slate-400'>(22/10000)</span>
								</label>
							</fieldset>
							<fieldset>
								<label className='inline-block form-check-label text-slate-700' htmlFor='format3'>
									<input
										{...register('delivery_notes_no_format', { required: true })}
										value={2}
										className='float-left w-4 h-4 mt-1 mr-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border border-gray-300 rounded-full appearance-none cursor-pointer form-check-input checked:bg-slate-700 checked:border-slate-700 focus:outline-none'
										type='radio'
										name='delivery_notes_no_format'
										id='format3'
									/>
									Jahr/Monat/Nummer <span className='text-slate-400'>(22/03/10000)</span>
								</label>
							</fieldset>
						</div> */}
            <button type="submit" className="btn btn-primary">
              Speichern
            </button>
          </form>
        </div>
      </div>
      <div className="mt-8">
        <p className="text-lg font-semibold">Nur für Profis / Barbara</p>
        <label htmlFor="experimental">
          Experimentelle Ansicht aktivieren?{" "}
        </label>
        <input
          type="checkbox"
          name="experimental"
          id="experimental"
          checked={experimental}
          onChange={(_) => setExperimental(!experimental)}
        />
      </div>
    </>
  );
};

export default System;
