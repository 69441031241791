import { useContext } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import Input from '../form/Input';
import { UserContext } from '../../App';
import { toast } from 'react-toastify';
import useUsers from '../../hooks/useUsers';
import { TrashIcon } from '@heroicons/react/outline';

const Users = () => {
	const { acId } = useContext(UserContext);
	const users = useUsers();

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm();

	const deleteUser = (id: string) => {
		if (window.confirm('Soll dieser Eintrag wirklich gelöscht werden?')) {
			axios
				.post('/api/deleteUser', {
					userId: id,
				})
				.then((data) => {
					users.refetch();
					toast.success('Benutzer gelöscht.', {
						position: toast.POSITION.BOTTOM_RIGHT,
					});
				})
				.catch((error) => {
					toast.error('Etwas ist schiefgelaufen!', {
						position: toast.POSITION.BOTTOM_RIGHT,
					});
				});
		}
	};

	const newMember = async (data: any) => {
		if (data.email && data.password && acId) {
			axios
				.post('/api/createUser', {
					email: data.email,
					password: data.password,
					user_metadata: {
						ackercloud_id: acId,
						user_role: 'employee',
						firstname: data.firstname,
						lastname: data.lastname,
					},
				})
				.then((data) => {
					users.refetch();
					toast.success('Benutzer angelegt.', {
						position: toast.POSITION.BOTTOM_RIGHT,
					});
				})
				.catch((error) => {
					toast.error('Etwas ist schiefgelaufen!', {
						position: toast.POSITION.BOTTOM_RIGHT,
					});
				});
		} else {
			toast.error('Etwas ist schiefgelaufen!', {
				position: toast.POSITION.BOTTOM_RIGHT,
			});
		}
	};

	return (
		<>
			<h1 className='text-2xl mb-6'>Benutzerverwaltung</h1>
			<div className='flex justify-between flex-wrap lg:flex-nowrap gap-8'>
				<div className='w-full lg:w-2/5 p-4 border'>
					<form onSubmit={handleSubmit(newMember)}>
						<p className='mb-6'>Benutzer anlegen/bearbeiten</p>
						<Input id='firstname' style='flex-1' label='Vorname' register={register} />
						<Input id='lastname' style='flex-1' label='Nachname' register={register} />
						<Input
							id='email'
							type='email'
							style='flex-1'
							label='Email*'
							register={register}
							errors={errors?.email}
							rules={{
								required: { value: true, message: 'Dies ist ein Pflichtfeld' },
								pattern: {
									value:
										/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
									message: 'Keine gültige Email Adresse',
								},
							}}
						/>
						<Input
							id='password'
							type='password'
							style='flex-1'
							label='Passwort*'
							register={register}
							errors={errors?.password}
							rules={{
								required: { value: true, message: 'Dies ist ein Pflichtfeld' },
								pattern: {
									value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
									message: 'Mindestens 8 Zeichen. Groß-& Kleinbuchstaben und Zahlen.',
								},
							}}
						/>
						<button type='submit' className='btn btn-primary'>
							Benutzer anlegen
						</button>
					</form>
				</div>
				<div className='w-full lg:w-3/5  overflow-x-auto'>
					<table className='ac-table'>
						<thead>
							<tr>
								<th>
									<span>Vorname</span>
								</th>
								<th>
									<span>Nachname</span>
								</th>
								<th>
									<span>Email</span>
								</th>
								<th>
									<span>Rolle</span>
								</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{users &&
								users?.data?.users.map((user: any, index: number) => {
									if (user.email !== users?.data?.email)
										return (
											<tr key={user.email}>
												<td>{user.firstname}</td>
												<td>{user.lastname}</td>
												<td>{user.email}</td>
												<td>{user.user_role === 'employee' ? 'Mitarbeiter' : user.user_role}</td>
												<td className='w-16'>
													<div>
														<TrashIcon
															onClick={(_) => deleteUser(user.id)}
															width={24}
															height={24}
															className='text-red-500 hover:scale-110 mx-auto cursor-pointer'
														/>
													</div>
												</td>
											</tr>
										);
								})}
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
};

export default Users;
