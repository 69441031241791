import React, { useContext, useEffect, useState } from 'react';
import AdminLayout from '../layouts/AdminLayout';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import Input from '../components/form/Input';
import Select from '../components/form/Select';
import useAttachedFiles from '../hooks/useAttachedFiles';
import { UserContext } from '../App';
import useEmployees from '../hooks/useEmployees';

const Forms = () => {
	const { acId } = useContext(UserContext);
	const {
		register,
		handleSubmit,
		reset,
		control,
		setValue,
		watch,
		getValues,
		formState: { errors },
	} = useForm();
	const [availableDocs, setAvailableDocs] = useState<string[]>([]);
	const formFiles = useAttachedFiles('Formulare');
	const employees = useEmployees();

	useEffect(() => {
		const items: any = [];
		if (formFiles?.data?.data) {
			formFiles.data.data.map((file: any) => {
				if (file.name !== '.emptyFolderPlaceholder') {
					const item = {
						key: file.name,
						label: file.name,
					};
					items.push(item);
				}
			});
		}
		setAvailableDocs(items);
	}, [formFiles.isSuccess]);

	const onSubmit = async (formData: any) => {
		formData.acId = acId;
		const test = axios.post('/api/docReplacer', formData);
	};

	return (
		<AdminLayout>
			<form onSubmit={handleSubmit(onSubmit)} className='pt-3'>
				<div className='flex gap-8 items-center'>
					<Select
						id='fileName'
						label='Formular wählen'
						placeholder=' - bitte wählen - '
						register={register}
						options={availableDocs}
						style='w-72'
					/>
					<button className='btn btn-primary mb-8' type='submit'>
						Erstellen
					</button>
				</div>

				<table className='ac-table'>
					<thead>
						<tr>
							<th>Auswählen</th>
							<th>Mitarbeiter-Nr.</th>
							<th>Nachname</th>
							<th>Vorname</th>
						</tr>
					</thead>
					<tbody>
						{employees?.data?.map((employee: any, index: number) => (
							<tr key={employee.employee_id}>
								<td className='relative '>
									<input
										className='cursor-pointer relative z-10'
										{...register('ids')}
										id={index.toString()}
										type='checkbox'
										value={employee.id}
									/>
									<label htmlFor={index.toString()} className='cursor-pointer inset-0 absolute  z-0'></label>
								</td>
								<td>{employee.employee_id}</td>
								<td>{employee.lastname}</td>
								<td>{employee.firstname}</td>
							</tr>
						))}
					</tbody>
				</table>
			</form>
		</AdminLayout>
	);
};

export default Forms;
